import React from 'react';
import { Icon } from '@fluentui/react';
import './NavBarButtonCallout.scss';

export interface NavBarButtonProps {
  active: boolean;
}

const NavBarCallout: React.FunctionComponent<NavBarButtonProps> = ({ active }) => {
  return (
    <Icon
      iconName="ChromeBackMirrored"
      className="navbarCallout"
      styles={{
        root: {
          display: active ? 'block' : 'none',
          fontSize: 48,
          color: 'red',
          position: 'absolute',
          left: -36,
          top: -24,
        },
      }}
    />
  );
};

export default NavBarCallout;
