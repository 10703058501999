import _mapKeys from 'lodash/mapKeys';
import type { EntryDefinition } from '../types';
import GA01 from './GA01';
import IDSA01 from './IDSA01';
import IDSA03 from './IDSA03';
import SECSA02 from './SECSA02';
import SECSA03 from './SECSA03';

/**
 * Collect all game entry data into a single object for easier export.
 */
const Game01: EntryDefinition = {
  // Prefix exported keys with `Game01.[Activity].`
  ..._mapKeys(GA01, (value, key) => `Game01.GA01.${key}`),
  ..._mapKeys(IDSA01, (value, key) => `Game01.IDSA01.${key}`),
  ..._mapKeys(IDSA03, (value, key) => `Game01.IDSA03.${key}`),
  ..._mapKeys(SECSA02, (value, key) => `Game01.SECSA02.${key}`),
  ..._mapKeys(SECSA03, (value, key) => `Game01.SECSA03.${key}`),
};
export default Game01;
