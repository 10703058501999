import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useTaskMachine } from '../../../lib/hooks/useTaskMachine';
import Title from './components/Title';
import { createRegistration } from './machine';
import Register from './components/Register';

const baseNS = 'Game01.Registration';
const taskKey = 'Game01.Registration';

const Registration: React.FunctionComponent = () => {
  const { t } = useTranslation([baseNS, 'roles']);

  const history = useHistory();

  // Setup task machine
  const [state, send] = useTaskMachine(createRegistration, {
    taskKey,
    shouldPersist: false,
  });

  const onClick = () => {
    send('NEXT');
  };

  useEffect(() => {
    const isDone = state.matches('done');
    if (isDone) {
      history.push('/tasks/Game01/Intro');
    }
  }, [state, history]);

  return (
    <>
      {/**
       * title screen
       */}
      {state.matches('title') && <Title t={t} onClick={onClick} />}

      {/**
       * registration screen
       */}
      {state.matches('register') && <Register t={t} onSubmit={onClick} />}
    </>
  );
};

export default Registration;
