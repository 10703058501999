/* eslint-disable no-param-reassign, @typescript-eslint/no-use-before-define */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import { getDefaultUserStatusEventParameters, ClientEventUserStatus } from '../lib/socket/schemas';

import type { ThunkApiConfig } from '.';

export interface MultiplayerStateInterface {
  status: ClientEventUserStatus;
}

// Initial slice state
const initialState: MultiplayerStateInterface = {
  status: getDefaultUserStatusEventParameters(),
};

export const emitChatMessage = createAsyncThunk<void, string, ThunkApiConfig>(
  'multiplayer/chat:send',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (message, { extra }) => {},
);

export const emitRoomStatus = createAsyncThunk<void, string, ThunkApiConfig>(
  'multiplayer/status:room',
  (room, { extra }) => extra.emitPromise('status:room', { room }, () => {}),
);

export const emitUserStatus = createAsyncThunk<void, undefined, ThunkApiConfig>(
  'multiplayer/status:user',
  (message, thunkApi) =>
    thunkApi.extra.emitPromise('status:user', (result) => {
      const args = ClientEventUserStatus.safeParse(result?.data);
      if (!args.success) {
        throw new Error(`ClientEventUserStatus validation error: ${args.error.message}`);
      }
      const newAction = actions.updateUserStatus(args.data);
      thunkApi.dispatch(newAction);
    }),
);

export const multiplayerSlice = createSlice({
  name: 'multiplayer',
  initialState,
  reducers: {
    updateUserStatus: (state, { payload }: PayloadAction<Partial<ClientEventUserStatus>>) => {
      Object.assign(state.status, payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(emitChatMessage.fulfilled, () => {});
  },
});

/**
 * Grouped export for multiplayerSlice actions (include thunk actions).
 */
export const actions = {
  ...multiplayerSlice.actions,
  emitChatMessage,
  emitRoomStatus,
  emitUserStatus,
};

/**
 * Individual exports for multiplayerSlice actions.
 */
export const { updateUserStatus } = multiplayerSlice.actions;

export default multiplayerSlice.reducer;
