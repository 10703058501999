/* eslint-disable no-console */
import React, { useState } from 'react';
import { Text, FontWeights, Stack, getTheme, DefaultButton, DefaultEffects, PrimaryButton } from '@fluentui/react';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import _isEqual from 'lodash/isEqual';
import { useSelector, useStore } from 'react-redux';
import { RootState } from '../../../../store';
import { hasJournalEntry } from '../../../../store/journal-slice';
import { IR01BonusFlags, IR01TaskProps, IRForm } from '../interface';
import { IR01Alert2EventOptions, getIRResults, generateIRParts } from '../data/event-options';
import IRPageHead from './ir_components/IRPageHead';
import IRSummary from './ir_components/IRSummary';
import IREventsSubheader from './ir_components/IREventsSubheader';
import IREvent from './ir_components/IREvent';
import { IR01DataLossOptions, IR01ImpactOptions } from '../data/options';
import ResultModal from '../../../../components/ResultModal/ResultModal';
import { addScore } from '../../../../store/game-slice';
import './ir_components/IRForm.scss';

interface IR01IncidentReportProps extends IR01TaskProps {
  state: any;
}

// this should be the date of the first entry
const today = format(Date.now(), 'MM/dd/yy');
const theme = getTheme();

const IncidentReport: React.FunctionComponent<IR01IncidentReportProps> = ({ t, state, send = () => {} }) => {
  const store = useStore();
  const [reportValues, setReportValues] = useState<Record<string, string>>({});
  const { handleSubmit, control, formState } = useForm<IRForm>({
    defaultValues: {},
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const showConfirmModal = state?.incidentReport === 'confirmComplete';
  const { isDirty, isValid } = formState;

  // these need to be set according to entries gained
  const bonuses: IR01BonusFlags = useSelector(
    ({ journal }: RootState) => ({
      // Confirmed user Amari Rivera compromised in IDSA01.lab01
      id1: hasJournalEntry(journal, { id: 'Game01.IDSA01.AADIPConfirmCompromised' }),
      // Got "Reporting User: Debra Berger" but NOT "Help Desk Agent: Grady Archie" in SECSA02.delete01
      sec1a: hasJournalEntry(journal, { id: 'Game01.SECSA02.supportTicket', every: ['CH01'], none: ['CH02'] }),
      // Got "Help Desk Agent: Grady Archie" but NOT "Reporting User: Debra Berger" in SECSA02.delete01
      sec1b: hasJournalEntry(journal, { id: 'Game01.SECSA02.supportTicket', every: ['CH02'], none: ['CH01'] }),
      // Got "Reporting User: Debra Berger"AND "Help Desk Agent: Grady Archie" in SECSA02.delete01
      sec1c: hasJournalEntry(journal, { id: 'Game01.SECSA02.supportTicket', every: ['CH01', 'CH02'] }),
    }),
    _isEqual,
  );

  const { IR01EventsPart1, IR01EventsPart2 } = generateIRParts(bonuses);

  const onSave = () => {
    handleSubmit(
      (data) => {
        console.log('Success', data);
        setReportValues(data);
        send('CLICK.SUBMIT');
      },
      (err) => {
        console.log('Error:', err);
      },
    )();
  };

  const onComplete = () => {
    const results = getIRResults(reportValues);
    console.log('IR Results:', results);
    store.dispatch(addScore({ type: 'IR', amount: results.total, id: 'Game01.IR01.Submit' }));
    send('CLICK.CONFIRM');
  };

  return (
    <Stack verticalFill styles={{ root: { backgroundColor: theme.palette.neutralLight } }}>
      <Stack.Item styles={{ root: { backgroundColor: theme.palette.neutralLight, padding: 24 } }}>
        <form onSubmit={handleSubmit(onSave)} className="IR01Form">
          <Stack tokens={{ childrenGap: 16 }}>
            <Stack.Item>
              <Text variant="xxLarge" styles={{ root: { fontWeight: FontWeights.bold } }}>
                {t('ir.header')}
              </Text>
            </Stack.Item>
            <Stack.Item>
              <IRPageHead
                control={control}
                theme={theme}
                incidentImpactOptions={IR01ImpactOptions}
                dataLossOptions={IR01DataLossOptions}
                t={t}
                date={today}
              />
            </Stack.Item>
            <Stack.Item>
              <IRSummary t={t} theme={theme} date={today} />
            </Stack.Item>
            <Stack.Item>
              <Stack
                styles={{
                  root: {
                    backgroundColor: theme.palette.neutralLighterAlt,
                    padding: 24,
                    boxShadow: DefaultEffects.elevation16,
                  },
                }}
                tokens={{ childrenGap: 16 }}
              >
                <Stack.Item>
                  <Text variant="xLarge" styles={{ root: { fontWeight: FontWeights.bold } }}>
                    {t('ir.reported.header')}
                  </Text>
                </Stack.Item>
                <Stack.Item>
                  <IREventsSubheader t={t} i18nKey="ir.reported.alert1" />
                </Stack.Item>
                <Stack.Item>
                  {IR01EventsPart1.map(({ i18nKey, options }) => (
                    <IREvent t={t} i18nKey={i18nKey} control={control} options={options} key={i18nKey} />
                  ))}
                </Stack.Item>
                <Stack.Item>
                  <IREventsSubheader
                    t={t}
                    i18nKey="ir.reported.alert2.text"
                    options={IR01Alert2EventOptions}
                    control={control}
                  />
                </Stack.Item>
                <Stack.Item>
                  {IR01EventsPart2.map(({ i18nKey, options }) => (
                    <IREvent t={t} i18nKey={i18nKey} control={control} options={options} key={i18nKey} />
                  ))}
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Text variant="medium">{t('ir.whenDone')}</Text>
            </Stack.Item>
            <Stack.Item>
              <PrimaryButton disabled={!isDirty || !isValid} type="submit" text={t('ir.submitButton')} />
            </Stack.Item>
          </Stack>
        </form>
      </Stack.Item>
      <Stack.Item>
        <ResultModal
          isOpen={showConfirmModal}
          title={t('ir.confirmationDialog.title')}
          body={<Text>{t('ir.confirmationDialog.body')}</Text>}
          footer={
            <>
              <DefaultButton onClick={() => send('CLICK.DENY')}>
                {t('ir.confirmationDialog.cancelButton')}
              </DefaultButton>
              <PrimaryButton onClick={onComplete}>{t('ir.confirmationDialog.confimButton')}</PrimaryButton>
            </>
          }
        />
      </Stack.Item>
    </Stack>
  );
};

export default IncidentReport;
