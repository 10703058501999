import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Checkbox, DefaultButton, Text } from '@fluentui/react';

import { createIDSA01 } from './machine';
import LabFrame, { LabFrameOnMessage } from '../../../components/LabFrame/LabFrame';
import ResultModal from '../../../components/ResultModal/ResultModal';
import ScreenModal, { ScreenModalSidebarProps } from '../../../components/ScreenModal/ScreenModal';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ExtractTEvent } from '../../task-types';
import RTrans from '../../../components/RTrans/RTrans';
import { isDev } from '../../../lib/util';
import { useTaskMachine } from '../../../lib/hooks/useTaskMachine';
import LabFrameHints from '../../../components/LabFrameHints/LabFrameHints';

const baseNS = 'Game01.IDSA01';
const taskKey = 'Game01.IDSA01';

const labURI = 'https://octe.azurewebsites.net/Microsoft/viewer/760/index.html';

const IDSA01: React.FC = () => {
  const { t } = useTranslation(baseNS);
  const history = useHistory();

  // Setup task machine
  const [state, send] = useTaskMachine(createIDSA01, {
    taskKey,
  });

  // Move along to the next task if state is done.
  useEffect(() => {
    if (state.done) {
      history.push('/tasks/Game01/IDSA03');
    }
  }, [state, history]);

  // Memoize IR manager persona
  const IRManager: ScreenModalSidebarProps = useMemo(
    () => ({
      title: t('IRManager.name'),
      persona: {
        imageInitials: t('IRManager.initials'),
      },
    }),
    [t],
  );

  const cbSendClickB01 = useCallback(() => send('CLICK.B01'), [send]);
  const cbSendClickB02 = useCallback(() => send('CLICK.B02'), [send]);
  const cbSendClickB03 = useCallback(() => send('CLICK.B03'), [send]);
  const cbSendClickB04 = useCallback(() => send('CLICK.B04'), [send]);
  const cbSendClickB05 = useCallback(() => send('CLICK.B05'), [send]);
  const cbSendClickD01 = useCallback(() => send('CLICK.D01'), [send]);

  const labMessageHandler = useCallback<LabFrameOnMessage>(
    (message) => {
      const { data } = message;
      if (isDev) {
        // eslint-disable-next-line no-console
        console.log('labMessageHandler', data);
      }
      const xEvent: ExtractTEvent<typeof send> = {
        type: String(data.action).toUpperCase(),
        ...data,
      };
      send(xEvent);
    },
    [send],
  );

  return (
    <>
      {/**
       * intro01
       */}
      {state.matches('intro01') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('intro01.title')}
          body={<Text>{t('intro01.body')}</Text>}
          footer={<DefaultButton onClick={cbSendClickB01}>{t('intro01.B01')}</DefaultButton>}
        />
      )}

      {/**
       * intro02 / intro02.intro02a
       */}
      {state.matches('intro02') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('intro02a.title')}
          body={<Text>{t('intro02a.body')}</Text>}
          footer={
            <>
              <DefaultButton onClick={cbSendClickB01}>{t('intro02a.B01')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB02}>{t('intro02a.B02')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB03}>{t('intro02a.B03')}</DefaultButton>
            </>
          }
        />
      )}

      {/**
       * intro02.intro02b
       */}
      <ResultModal
        isOpen={state.matches('intro02.intro02b')}
        title={t('intro02b.title')}
        body={<Text>{t('intro02b.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB04}>{t('intro02b.B04')}</DefaultButton>}
        onDismiss={cbSendClickB04}
      />

      {/**
       * intro02.intro02c
       */}
      <ResultModal
        isOpen={state.matches('intro02.intro02c')}
        title={t('intro02c.title')}
        body={<Text>{t('intro02c.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB05}>{t('intro02c.B05')}</DefaultButton>}
        onDismiss={cbSendClickB05}
      />

      {/**
       * lab01 / modal01
       */}
      {(state.matches('lab01') || state.matches('modal01')) && (
        <>
          <LabFrame
            src={labURI}
            title={t('lab01.title')}
            sectionIndex={state.context.lab01.sectionIndex}
            screenIndex={state.context.lab01.screenIndex}
            onMessage={labMessageHandler}
          />
          <LabFrameHints screenId={state.context.lab01.screenId || 'start1'} namespace="labHints" t={t} />
        </>
      )}

      {/**
       * modal01
       */}
      <ResultModal
        isOpen={state.matches('modal01')}
        body={<Text>{t('modal01.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickD01}>{t('modal01.D01')}</DefaultButton>}
        onDismiss={cbSendClickD01}
      />

      {/**
       * mid01 / mid01.mid01a
       */}
      {state.matches('mid01') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('mid01a.title')}
          body={<Text>{t('mid01a.body')}</Text>}
          footer={
            <>
              <DefaultButton onClick={cbSendClickB01}>{t('mid01a.B01')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB02}>{t('mid01a.B02')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB03}>{t('mid01a.B03')}</DefaultButton>
            </>
          }
        />
      )}

      {/**
       * mid01.mid01b
       */}
      <ResultModal
        isOpen={state.matches('mid01.mid01b')}
        title={t('mid01b.title')}
        body={<Text>{t('mid01b.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB04}>{t('mid01b.B04')}</DefaultButton>}
        onDismiss={cbSendClickB04}
      />

      {/**
       * mid01.mid01c
       */}
      <ResultModal
        isOpen={state.matches('mid01.mid01c')}
        title={t('mid01c.title')}
        body={<Text>{t('mid01c.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB05}>{t('mid01c.B05')}</DefaultButton>}
        onDismiss={cbSendClickB05}
      />

      {/**
       * mid02 / mid02.mid02a
       */}
      {state.matches('mid02') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('mid02a.title')}
          body={<Text>{t('mid02a.body')}</Text>}
          footer={
            <>
              <DefaultButton onClick={cbSendClickB01}>{t('mid02a.B01')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB02}>{t('mid02a.B02')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB03}>{t('mid02a.B03')}</DefaultButton>
            </>
          }
        />
      )}

      {/**
       * mid02.mid02b
       */}
      <ResultModal
        isOpen={state.matches('mid02.mid02b')}
        title={t('mid02b.title')}
        body={<Text>{t('mid02b.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB04}>{t('mid02b.B04')}</DefaultButton>}
        onDismiss={cbSendClickB04}
      />

      {/**
       * mid02.mid02c
       */}
      <ResultModal
        isOpen={state.matches('mid02.mid02c')}
        title={t('mid02c.title')}
        body={<Text>{t('mid02c.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB05}>{t('mid02c.B05')}</DefaultButton>}
        onDismiss={cbSendClickB05}
      />

      {/**
       * mid03
       */}
      {state.matches('mid03') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('mid03.title')}
          body={
            <>
              <Text>{t('mid03.body')}</Text>
              <Checkbox label={t('mid03.list.0')} checked />
              <Checkbox label={t('mid03.list.1')} checked />
              <Checkbox label={t('mid03.list.2')} checked />
            </>
          }
          footer={
            <>
              <DefaultButton onClick={cbSendClickB01}>{t('mid03.B01')}</DefaultButton>
            </>
          }
        />
      )}

      {/**
       * lab02
       */}
      {(state.matches('lab02') || state.matches('modal02')) && (
        <>
          <LabFrame
            src={labURI}
            title="Lab02"
            sectionIndex={state.context.lab02.sectionIndex}
            screenIndex={state.context.lab02.screenIndex}
            onMessage={labMessageHandler}
          />
          <LabFrameHints screenId={state.context.lab02.screenId || 'start2'} namespace="labHints" t={t} />
        </>
      )}

      {/**
       * modal02
       */}
      <ResultModal
        isOpen={state.matches('modal02')}
        title={t('modal02.title')}
        body={<Text>{t('modal02.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickD01}>{t('modal02.D01')}</DefaultButton>}
        onDismiss={cbSendClickD01}
      />

      {/**
       * mid04 / mid04.mid04a
       */}
      {state.matches('mid04') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('mid04a.title')}
          body={<Text>{t('mid04a.body')}</Text>}
          footer={
            <>
              <DefaultButton onClick={cbSendClickB01}>{t('mid04a.B01')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB02}>{t('mid04a.B02')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB03}>{t('mid04a.B03')}</DefaultButton>
            </>
          }
        />
      )}

      {/**
       * mid04.mid04b
       */}
      <ResultModal
        isOpen={state.matches('mid04.mid04b')}
        title={t('mid04b.title')}
        body={<Text>{t('mid04b.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB04}>{t('mid04b.B04')}</DefaultButton>}
        onDismiss={cbSendClickB04}
      />

      {/**
       * mid04.mid04c
       */}
      <ResultModal
        isOpen={state.matches('mid04.mid04c')}
        title={t('mid04c.title')}
        body={<Text>{t('mid04c.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB05}>{t('mid04c.B05')}</DefaultButton>}
        onDismiss={cbSendClickB05}
      />

      {/**
       * modal03
       */}
      <ResultModal
        isOpen={state.matches('modal03')}
        body={
          <Text>
            <RTrans t={t} i18nKey="modal03.body" />
          </Text>
        }
        footer={<DefaultButton onClick={cbSendClickD01}>{t('modal03.D01')}</DefaultButton>}
        onDismiss={cbSendClickD01}
      />

      {/**
       * modal03 / lab03 / modal04
       */}
      {(state.matches('modal03') || state.matches('lab03') || state.matches('modal04')) && (
        <>
          <LabFrame
            src={labURI}
            title={t('lab03.title')}
            sectionIndex={state.context.lab03.sectionIndex}
            screenIndex={state.context.lab03.screenIndex}
            onMessage={labMessageHandler}
          />
          <LabFrameHints screenId={state.context.lab03.screenId || 'start3'} namespace="labHints" t={t} />
        </>
      )}

      {/**
       * modal04
       */}
      <ResultModal
        isOpen={state.matches('modal04')}
        title={t('modal04.title')}
        body={<Text>{t('modal04.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickD01}>{t('modal04.D01')}</DefaultButton>}
        onDismiss={cbSendClickD01}
      />
    </>
  );
};

export default IDSA01;
