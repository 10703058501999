import _Registration from './Registration';
import _Intro from './Intro';
import _GA01 from './GA01';
import _IDSA01 from './IDSA01';
import _IDSA03 from './IDSA03';
import _SECSA02 from './SECSA02';
import _SECSA03 from './SECSA03';
import _IR01 from './IR01';
import _Ending from './Ending';

/**
 * Collect all game components into a single namespace for easier export.
 *
 * TODO: Maybe this actually becomes a/the top-level component for the game?
 */
namespace Game01 {
  export const Registration = _Registration;
  export const Intro = _Intro;
  export const GA01 = _GA01;
  export const IDSA01 = _IDSA01;
  export const IDSA03 = _IDSA03;
  export const SECSA02 = _SECSA02;
  export const SECSA03 = _SECSA03;
  export const IR01 = _IR01;
  export const Ending = _Ending;
}

export default Game01;
