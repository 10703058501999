/* eslint-disable max-len */
import React from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { PrimaryButton, DefaultButton } from '@fluentui/react';
import { TFunction } from 'react-i18next';
import ResultModal from '../ResultModal/ResultModal';
import RTrans from '../RTrans/RTrans';

interface LabFrameHintsInterface {
  screenId: string;
  namespace: string;
  t: TFunction<string>;
}

const LabFrameHints: React.FunctionComponent<LabFrameHintsInterface> = ({ screenId, namespace, t }) => {
  const [showHint, { toggle: toggleHideDialog }] = useBoolean(false);
  const key = `${namespace}.${screenId}`;
  const disabled = key === t(key);

  return (
    <>
      {!disabled && (
        <ResultModal
          hidePersona
          isOpen={showHint}
          onDismiss={toggleHideDialog}
          body={<RTrans t={t} i18nKey={key} />}
          footer={<DefaultButton onClick={() => toggleHideDialog()}>Got It</DefaultButton>}
        />
      )}
      <PrimaryButton
        disabled={disabled}
        styles={{
          root: {
            position: 'absolute',
            left: 16,
            bottom: 16,
          },
          rootDisabled: {
            position: 'absolute',
          },
        }}
        text={t(`common.btnHint${disabled ? 'Unavailable' : 'Available'}`)}
        onClick={() => toggleHideDialog()}
      />
    </>
  );
};
export default LabFrameHints;
