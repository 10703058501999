import React from 'react';
import { Text, Stack, FontWeights, Icon } from '@fluentui/react';
import { Control } from 'react-hook-form';
import { IR01EventOptions, IR01TaskProps } from '../../interface';
import { getEntryColor, getEntryIcons } from '../../../../../lib/entries';
import RTrans from '../../../../../components/RTrans/RTrans';
import IREvent from './IREvent';

interface IREventsSubheaderProps extends IR01TaskProps {
  i18nKey: string;
  options?: IR01EventOptions[];
  control?: Control<any, object>;
}

const eventStyles = {
  root: {
    fontWeight: FontWeights.bold,
    display: 'inline',
    marginLeft: 8,
    selectors: {
      p: {
        display: 'inline',
      },
    },
  },
};

const IREventsSubheader: React.FunctionComponent<IREventsSubheaderProps> = ({ t, i18nKey, options = [], control }) => {
  const hasOptions = options.length > 0;
  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 24 }}>
      <Stack.Item>
        <Icon
          iconName={getEntryIcons('alert')}
          styles={{
            root: {
              color: getEntryColor('alert').color,
              fontSize: 64,
            },
          }}
        />
      </Stack.Item>
      <Stack.Item>
        {!hasOptions && (
          <Text variant="large" styles={{ root: { fontWeight: FontWeights.bold } }}>
            <RTrans t={t} i18nKey={i18nKey} />
          </Text>
        )}
        {hasOptions && control && (
          <IREvent t={t} i18nKey={i18nKey} options={options} control={control} variant="large" styles={eventStyles} />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default IREventsSubheader;
