import React, { useEffect, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Stack, IStackStyles, LayerHost } from '@fluentui/react';

import Journal from '../Journal/Journal';
import Help from '../Help/Help';
import Policies from '../Policies/Policies';
import Settings from '../Settings/Settings';
import HomeScreen from '../HomeScreen/HomeScreen';
import Game01 from '../../tasks/Game01';
import NavBar from '../NavBar/NavBar';
import DebugScreen from '../DebugScreen/DebugScreen';
import './App.scss';
import Chat from '../Chat/Chat';
import { isDev } from '../../lib/util';
import { selectUserIsRegistered } from '../../store/user-slice';

const outerStackStyles: IStackStyles = {
  root: { height: '100vh', width: '100vw', overflow: 'hidden' },
};

const layerHostStyles: React.CSSProperties = {
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: 1,
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  pointerEvents: 'none',
};

function swallowBackButtonAndReload() {
  // prevent doing this in dev
  if (isDev) {
    return function cleanup() {};
  }
  function preventUnload(e: BeforeUnloadEvent) {
    e.preventDefault();
    e.returnValue = '';
  }
  function preventPopState() {
    window.history.pushState(null, document.title, window.location.href);
  }
  window.addEventListener('popstate', preventPopState);
  window.addEventListener('beforeunload', preventUnload);
  return function cleanup() {
    window.removeEventListener('popstate', preventPopState);
    window.removeEventListener('beforeunload', preventUnload);
  };
}

const App: React.FunctionComponent = () => {
  useEffect(swallowBackButtonAndReload);
  const isRegistered = useSelector(selectUserIsRegistered);

  /**
   * Set the root / route path based on isDev and registration status.
   */
  const rootPath = useMemo(() => {
    if (isDev) {
      return <HomeScreen />;
    }
    if (isRegistered) {
      return <Redirect to="/tasks/Game01/Intro" />;
    }
    return <Redirect to="/tasks/Game01/Registration" />;
  }, [isRegistered]);

  return (
    <Stack horizontal horizontalAlign="space-between" styles={outerStackStyles}>
      <Stack.Item>
        <LayerHost id="journal-content" style={layerHostStyles} />
        <LayerHost id="policies-content" style={layerHostStyles} />
        <LayerHost id="panel-content" style={layerHostStyles} />
      </Stack.Item>
      <Stack.Item grow={1} styles={{ root: { overflow: 'auto' } }}>
        <Switch>
          <Route exact path="/debug" component={DebugScreen} />
          <Route exact path="/home" component={HomeScreen} />
          <Route exact path="/tasks/Game01/Registration" component={Game01.Registration} />
          <Route exact path="/tasks/Game01/Intro" component={Game01.Intro} />
          <Route exact path="/tasks/Game01/GA01" component={Game01.GA01} />
          <Route exact path="/tasks/Game01/IDSA01" component={Game01.IDSA01} />
          <Route exact path="/tasks/Game01/IDSA03" component={Game01.IDSA03} />
          <Route exact path="/tasks/Game01/SECSA02" component={Game01.SECSA02} />
          <Route exact path="/tasks/Game01/SECSA03" component={Game01.SECSA03} />
          <Route exact path="/tasks/Game01/IR01" component={Game01.IR01} />
          <Route exact path="/tasks/Game01/Ending" component={Game01.Ending} />
          <Route path="/group-activity" component={Game01.GA01} />
          <Route path="/">{rootPath}</Route>
        </Switch>
      </Stack.Item>
      <Stack.Item disableShrink>
        <NavBar />
        <Journal />
        <Help />
        <Policies />
        <Chat />
        <Settings />
      </Stack.Item>
    </Stack>
  );
};

export default App;
