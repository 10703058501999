import { assign, createMachine } from 'xstate';
import { PointValue } from '../../task-common';

import { addEntry, removeEntry, getDefaultOptions, matchesTS, addScore } from '../../task-options';
import type {
  TaskConfig,
  TaskContext,
  TaskOptions,
  TaskMachineProps,
  TaskEvent,
  TSXEvent,
  LabContext,
} from '../../task-types';

export interface IDSA01Context extends TaskContext {
  lab01: LabContext;
  lab02: LabContext;
  lab03: LabContext;
}
type TContext = IDSA01Context;

export type IDSA01Event = TaskEvent | TSXEvent;
type TEvent = IDSA01Event;

export type IDA01MachineProps = TaskMachineProps<TContext>;

// Entry ID prefix
const entPfx = 'Game01.IDSA01';

/**
 * Partially applied `addScore` action creator with
 * `{ type: 'identity', id?: ``Game01.IDSA01.${id}`` }`
 */
const addScoreP = (amount: PointValue, id?: string) => {
  const computedId = id == null ? undefined : `${entPfx}.${id}`;
  return addScore({ type: 'identity', amount, id: computedId });
};

/**
 * Game01.IDSA01 machine config
 */
export const IDSA01Config: TaskConfig<TContext, TEvent> = {
  id: 'IDSA01',
  initial: 'intro01',
  context: {
    lab01: {
      sectionIndex: 0,
      screenIndex: 0,
    },
    lab02: {
      sectionIndex: 0,
      screenIndex: 14,
    },
    lab03: {
      sectionIndex: 0,
      screenIndex: 24,
    },
  },
  states: {
    intro01: {
      entry: [addEntry(`${entPfx}.userRiskRemediationPolicy`), addEntry(`${entPfx}.signInRiskPolicy`)],
      on: {
        'CLICK.B01': {
          target: 'intro02',
        },
      },
    },
    intro02: {
      initial: 'intro02a',
      states: {
        intro02a: {
          on: {
            'CLICK.B01': {
              actions: addScoreP(PointValue.Incorrect, 'intro02b.B01'),
              target: 'intro02b',
            },
            'CLICK.B02': {
              actions: addScoreP(PointValue.Critical, 'intro02b.B02'),
              target: '#IDSA01.lab01',
            },
            'CLICK.B03': {
              actions: addScoreP(PointValue.Incorrect, 'intro02b.B03'),
              target: 'intro02c',
            },
          },
        },
        intro02b: {
          on: {
            'CLICK.B04': { target: 'intro02a' },
          },
        },
        intro02c: {
          on: {
            'CLICK.B05': { target: 'intro02a' },
          },
        },
      },
    },
    lab01: {
      on: {
        'ESCAPE': {
          target: 'modal01',
        },
        'TSGS.NAVIGATED': {
          actions: [
            assign(
              (context, event): Partial<typeof context> => ({
                lab01: {
                  ...context.lab01,
                  screenId: event?.screenId ?? context.lab01.screenId,
                },
              }),
            ),
          ],
        },
        'TSGS.METADATA': {
          actions: [
            assign(
              (context, event): Partial<typeof context> => ({
                lab01: {
                  ...context.lab01,
                  sectionIndex: event?.currentSection ?? context.lab01.sectionIndex,
                  screenIndex: event?.currentScreen ?? context.lab01.screenIndex,
                },
              }),
            ),
          ],
        },
        'TSGS.SHAPECLICK': [
          // AADIPRiskyUserDetails01 (critical points)
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskyUserDetails01`, ['A']),
              addScoreP(PointValue.Critical, 'AADIPRiskyUserDetails01'),
            ],
            cond: matchesTS({ shapeId: '82fec10c-8269-45bc-a098-73414e71b271' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskyUserDetails01`, ['B']),
              addScoreP(PointValue.Critical, 'AADIPRiskyUserDetails01'),
            ],
            cond: matchesTS({ shapeId: '2fd7cef9-74ac-475f-b3f8-ec6522e1c10b' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskyUserDetails01`, ['C']),
              addScoreP(PointValue.Critical, 'AADIPRiskyUserDetails01'),
            ],
            cond: matchesTS({ shapeId: '7746098d-a58d-4417-9be2-09a053e9a5ac' }),
          },
          // AADIPRiskyUserDetails02 (bonus points)
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskyUserDetails02`, ['A']),
              addScoreP(PointValue.Bonus, 'AADIPRiskyUserDetails02'),
            ],
            cond: matchesTS({ shapeId: '93240855-4720-43f8-94d4-c28eeae22c41' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskyUserDetails02`, ['B']),
              addScoreP(PointValue.Bonus, 'AADIPRiskyUserDetails02'),
            ],
            cond: matchesTS({ shapeId: '6d21946c-22be-4997-9773-0a5e68748582' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskyUserDetails02`, ['C']),
              addScoreP(PointValue.Bonus, 'AADIPRiskyUserDetails02'),
            ],
            cond: matchesTS({ shapeId: 'ea57535f-357b-4e05-a153-0595f706c2fd' }),
          },
          // AADIPRiskyUserDetails03 (bonus points)
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskyUserDetails03`, ['A']),
              addScoreP(PointValue.Bonus, 'AADIPRiskyUserDetails03'),
            ],
            cond: matchesTS({ shapeId: 'd72120fe-b573-49fe-a4e8-ac1cd9fb56a4' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskyUserDetails03`, ['B']),
              addScoreP(PointValue.Bonus, 'AADIPRiskyUserDetails03'),
            ],
            cond: matchesTS({ shapeId: '3eba4695-3ac4-4b83-b75c-91a06501dc7a' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskyUserDetails03`, ['C']),
              addScoreP(PointValue.Bonus, 'AADIPRiskyUserDetails03'),
            ],
            cond: matchesTS({ shapeId: '98b4bc23-952c-4de7-bb13-dcdee48daf57' }),
          },
          // AADIPRiskySignInDetails (critical points)
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskySignInDetails`, ['A']),
              addScoreP(PointValue.Critical, 'AADIPRiskySignInDetails'),
            ],
            cond: matchesTS({ shapeId: 'fdd06d95-5403-4d7f-96aa-f48951489fe0' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskySignInDetails`, ['B']),
              addScoreP(PointValue.Critical, 'AADIPRiskySignInDetails'),
            ],
            cond: matchesTS({ shapeId: '504fd86d-1210-48a8-a62c-40dec3788e7f' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskySignInDetails`, ['C']),
              addScoreP(PointValue.Critical, 'AADIPRiskySignInDetails'),
            ],
            cond: matchesTS({ shapeId: '49297889-1d5b-4383-9671-8afaddc1457f' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskySignInDetails`, ['D']),
              addScoreP(PointValue.Critical, 'AADIPRiskySignInDetails'),
            ],
            cond: matchesTS({ shapeId: '973a9e96-53a3-4cb4-825c-03f6897b5eb9' }),
          },
          // AADIPAnonymousSignIn (critical points)
          {
            actions: [
              addEntry(`${entPfx}.AADIPAnonymousSignIn`, ['A']),
              addScoreP(PointValue.Critical, 'AADIPAnonymousSignIn'),
            ],
            cond: matchesTS({ shapeId: 'ad6ca2f9-c7b8-41f6-9cce-428472b36bb2' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPAnonymousSignIn`, ['B']),
              addScoreP(PointValue.Critical, 'AADIPAnonymousSignIn'),
            ],
            cond: matchesTS({ shapeId: '56d7634b-5d89-4413-9f2d-9801c0ec0376' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPAnonymousSignIn`, ['C']),
              addScoreP(PointValue.Critical, 'AADIPAnonymousSignIn'),
            ],
            cond: matchesTS({ shapeId: '6118abf4-98b8-49ae-823c-825d7cbcc185' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPAnonymousSignIn`, ['D']),
              addScoreP(PointValue.Critical, 'AADIPAnonymousSignIn'),
            ],
            cond: matchesTS({ shapeId: '189d38f4-2aa0-4f63-a523-d145afc2abee' }),
          },
          // AADIPRiskDetections (critical points)
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskDetections`, ['A']),
              addScoreP(PointValue.Critical, 'AADIPRiskDetections'),
            ],
            cond: matchesTS({ shapeId: 'd4542c9c-3f03-4859-ad09-85ec8fadaf65' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskDetections`, ['B']),
              addScoreP(PointValue.Critical, 'AADIPRiskDetections'),
            ],
            cond: matchesTS({ shapeId: 'bfbdcd4a-ad80-4dc2-8ae5-734485399467' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPRiskDetections`, ['C']),
              addScoreP(PointValue.Critical, 'AADIPRiskDetections'),
            ],
            cond: matchesTS({ shapeId: '5b2f98cd-45af-4632-a522-991cd6d6bd10' }),
          },
          // AADIPConfirmCompromised (bonus points)
          {
            actions: [
              addEntry(`${entPfx}.AADIPConfirmCompromised`),
              addScoreP(PointValue.Bonus, 'AADIPConfirmCompromised'),
            ],
            cond: matchesTS({ shapeId: '96fdb385-4465-474b-a390-fcdb0e2c8829' }),
          },
          // Click X on #/2/0 (Risk detection details for Amari Rivera)
          {
            target: 'modal01',
            cond: matchesTS({ shapeId: '7604fefd-4614-45d9-8873-19a0c06155a8' }),
          },
        ],
      },
    },
    modal01: {
      entry: addEntry(`${entPfx}.alertVMDeleted`),
      on: {
        'CLICK.D01': {
          target: 'mid01',
        },
      },
    },
    mid01: {
      initial: 'mid01a',
      states: {
        mid01a: {
          on: {
            'CLICK.B01': { actions: addScoreP(PointValue.Critical, 'mid01a.B01'), target: '#IDSA01.mid02' },
            'CLICK.B02': { actions: addScoreP(PointValue.Incorrect, 'mid01a.B02'), target: 'mid01b' },
            'CLICK.B03': { actions: addScoreP(PointValue.Incorrect, 'mid01a.B03'), target: 'mid01c' },
          },
        },
        mid01b: {
          on: {
            'CLICK.B04': { target: 'mid01a' },
          },
        },
        mid01c: {
          on: {
            'CLICK.B05': { target: 'mid01a' },
          },
        },
      },
    },
    mid02: {
      initial: 'mid02a',
      states: {
        mid02a: {
          on: {
            'CLICK.B01': { actions: addScoreP(PointValue.Critical, 'mid02a.B01'), target: '#IDSA01.mid03' },
            'CLICK.B02': { actions: addScoreP(PointValue.Incorrect, 'mid02a.B02'), target: 'mid02b' },
            'CLICK.B03': { actions: addScoreP(PointValue.Incorrect, 'mid02a.B03'), target: 'mid02c' },
          },
        },
        mid02b: {
          on: {
            'CLICK.B04': { target: 'mid02a' },
          },
        },
        mid02c: {
          on: {
            'CLICK.B05': { target: 'mid02a' },
          },
        },
      },
    },
    mid03: {
      on: {
        'CLICK.B01': { target: 'lab02' },
      },
    },
    lab02: {
      on: {
        'ESCAPE': {
          target: 'modal02',
        },
        'TSGS.METADATA': {
          actions: [
            assign(
              (context, event): Partial<typeof context> => ({
                lab02: {
                  ...context.lab02,
                  sectionIndex: event?.currentSection ?? context.lab02.sectionIndex,
                  screenIndex: event?.currentScreen ?? context.lab02.screenIndex,
                },
              }),
            ),
          ],
        },
        'TSGS.NAVIGATED': [
          {
            actions: [
              assign(
                (context, event): Partial<typeof context> => ({
                  lab02: {
                    ...context.lab02,
                    screenId: event?.screenId ?? context.lab02.screenId,
                  },
                }),
              ),
            ],
          },
          {
            target: 'modal02',
            cond: matchesTS({ sectionIndex: 0, screenIndex: 23 }),
          },
        ],
      },
    },
    modal02: {
      on: {
        'CLICK.D01': { target: 'mid04' },
      },
    },
    mid04: {
      initial: 'mid04a',
      states: {
        mid04a: {
          on: {
            'CLICK.B01': { actions: addScoreP(PointValue.Incorrect, 'mid04a.B01'), target: 'mid04b' },
            'CLICK.B02': { actions: addScoreP(PointValue.Critical, 'mid04a.B02'), target: '#IDSA01.modal03' },
            'CLICK.B03': { actions: addScoreP(PointValue.Incorrect, 'mid04a.B03'), target: 'mid04c' },
          },
        },
        mid04b: {
          on: {
            'CLICK.B04': { target: 'mid04a' },
          },
        },
        mid04c: {
          on: {
            'CLICK.B05': { target: 'mid04a' },
          },
        },
      },
    },
    modal03: {
      on: {
        'CLICK.D01': { target: 'lab03' },
      },
    },
    lab03: {
      on: {
        'ESCAPE': {
          target: 'modal04',
        },
        'TSGS.METADATA': {
          actions: [
            assign(
              (context, event): Partial<typeof context> => ({
                lab03: {
                  ...context.lab03,
                  sectionIndex: event?.currentSection ?? context.lab03.sectionIndex,
                  screenIndex: event?.currentScreen ?? context.lab03.screenIndex,
                },
              }),
            ),
          ],
        },
        'TSGS.NAVIGATED': {
          actions: [
            assign(
              (context, event): Partial<typeof context> => ({
                lab03: {
                  ...context.lab03,
                  screenId: event?.screenId ?? context.lab03.screenId,
                },
              }),
            ),
          ],
        },
        'TSGS.SHAPECLICK': [
          // AADIPUserRiskPolicy (critical points)
          {
            actions: [
              addEntry(`${entPfx}.AADIPUserRiskPolicy`, ['A']),
              addScoreP(PointValue.Critical, 'AADIPUserRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: '5921282f-dd11-4191-ac16-83842b090cfc' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPUserRiskPolicy`, ['B']),
              addScoreP(PointValue.Critical, 'AADIPUserRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: 'b9c87e2d-616b-4205-b511-535e3d8c9d50' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPUserRiskPolicy`, ['C']),
              addScoreP(PointValue.Critical, 'AADIPUserRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: '91fbb583-e39c-4263-8fa6-f734b421a970' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPUserRiskPolicy`, ['D']),
              addScoreP(PointValue.Critical, 'AADIPUserRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: 'f1fc3211-b376-44b2-abed-1bf9b6f2402f' }),
          },
          // AADIPSignInRiskPolicy (path 1 - "user risk: medium and above" - critical points)
          {
            actions: [
              addEntry(`${entPfx}.AADIPSignInRiskPolicy`, ['A']),
              addScoreP(PointValue.Critical, 'AADIPSignInRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: '1c0aa327-3576-4736-b9a9-5a8d634b1647' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPSignInRiskPolicy`, ['B1']),
              removeEntry(`${entPfx}.AADIPSignInRiskPolicy`, ['B2']),
              addScoreP(PointValue.Critical, 'AADIPSignInRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: '9c7e5e45-c2a5-45df-9b39-335990e461af' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPSignInRiskPolicy`, ['C']),
              addScoreP(PointValue.Critical, 'AADIPSignInRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: '272d372d-2a4b-4e3b-ba8c-87e1899a14a8' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPSignInRiskPolicy`, ['D']),
              addScoreP(PointValue.Critical, 'AADIPSignInRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: 'f9efcaa6-b6c5-424e-b027-1c3bfa0f79ce' }),
          },
          // AADIPSignInRiskPolicy (path 2 - "user risk: high")
          {
            actions: [
              addEntry(`${entPfx}.AADIPSignInRiskPolicy`, ['A']),
              addScoreP(PointValue.Critical, 'AADIPSignInRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: '5b1a0054-7128-4939-9bfa-26baee73a11c' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPSignInRiskPolicy`, ['B2']),
              removeEntry(`${entPfx}.AADIPSignInRiskPolicy`, ['B1']),
              addScoreP(PointValue.Critical, 'AADIPSignInRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: '16f236d1-878c-4dcb-beee-f07d5d183dbd' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPSignInRiskPolicy`, ['C']),
              addScoreP(PointValue.Critical, 'AADIPSignInRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: '1d0c6310-68b7-4b22-88b3-f50260875fed' }),
          },
          {
            actions: [
              addEntry(`${entPfx}.AADIPSignInRiskPolicy`, ['D']),
              addScoreP(PointValue.Critical, 'AADIPSignInRiskPolicy'),
            ],
            cond: matchesTS({ shapeId: '373b267e-05e0-4b9c-ac94-9c3a28da8860' }),
          },
          // Exit paths
          {
            target: 'modal04',
            cond: matchesTS({ shapeId: '019aa5e5-ee60-4d80-b334-253dec54a724' }),
          },
          {
            target: 'modal04',
            cond: matchesTS({ shapeId: 'e047572a-3092-42e1-bad2-74087c6e51c7' }),
          },
        ],
      },
    },
    modal04: {
      on: {
        'CLICK.D01': { target: 'done' },
      },
    },
    done: {
      type: 'final',
    },
  },
};

/**
 * Additional machine options.
 */
export const IDSA01Options: TaskOptions<TContext, TEvent> = {};

/**
 * Machine constructor.
 */
export function createIDSA01() {
  return createMachine(IDSA01Config, getDefaultOptions()).withConfig(IDSA01Options);
}
