import { getTheme, IDropdownStyles, IStackStyles, IStackTokens, mergeStyles } from '@fluentui/react';
import _merge from 'lodash/merge';

const theme = getTheme();

const monoFontFamily = 'Monaco, Menlo, Consolas, "Roboto Mono", monospace';

export const queryOptionStyles: Partial<IDropdownStyles> = {
  title: {
    fontFamily: monoFontFamily,
  },
  dropdownOptionText: {
    fontFamily: monoFontFamily,
  },
};

const onlyQueryHeaderStyles: Partial<IDropdownStyles> = {
  dropdown: {
    boxShadow: theme.effects.elevation16,
  },
  dropdownItem: {},
  dropdownItemSelected: {},
  dropdownItemsWrapper: {},
  title: {
    textAlign: 'center',
  },
};

export const queryHeaderStyles: Partial<IDropdownStyles> = _merge({}, queryOptionStyles, onlyQueryHeaderStyles);

export const queryTextStyle = mergeStyles({
  ...theme.fonts.large,
  display: 'block',
  fontFamily: monoFontFamily,
  fontWeight: 400,
  em: {
    color: theme.palette.themePrimary,
  },
});

export const queryStackOuterStyles: IStackStyles = {
  root: {
    backgroundColor: theme.palette.neutralLighterAlt,
    boxShadow: theme.effects.elevation16,
    borderRadius: theme.effects.roundedCorner2,
  },
};

export const queryStackInnerTokens: IStackTokens = {
  childrenGap: 12,
  padding: 16,
};

export const screenModalIconStackHTokens: IStackTokens = { childrenGap: 30 };
export const screenModalIconStackVTokens: IStackTokens = { childrenGap: 16 };
