import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text, Stack } from '@fluentui/react';
import NavPanel from '../NavPanel/NavPanel';
import Entry from '../Entry/Entry';
import { RootState } from '../../store';
import { generateEntries } from '../../lib/entries';

const Policies: React.FunctionComponent = () => {
  const policies = useSelector((state: RootState) => state.policies);
  const { t } = useTranslation('policies');
  const entries = generateEntries(policies, 'all');
  const hostId = 'policies-content';
  const onOpen = () => {
    const scrollableContent = document.querySelector<HTMLElement>(`#${hostId} .ms-Panel-scrollableContent`);
    if (scrollableContent) {
      scrollableContent.scrollTop = scrollableContent.scrollHeight;
    }
  };
  return (
    <NavPanel
      activePanel="policies"
      focusSelector=".selected"
      onOpen={onOpen}
      hostId={hostId}
    >
      <Stack>
        <Stack.Item>
          <Stack>
            <Stack.Item>
              <Text variant="xxLarge" block styles={{ root: { marginBottom: 24 } }}>
                {t('header')}
              </Text>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack verticalAlign="start">
            {entries.map((eProps) => (
              <Entry key={eProps.entry_id} {...eProps} />
            ))}
          </Stack>
        </Stack.Item>
      </Stack>
    </NavPanel>
  );
};
export default Policies;
