import { createMachine } from 'xstate';
import { PointValue } from '../../task-common';

import { addScore, getDefaultOptions } from '../../task-options';
import type {
  TaskConfig,
  TaskContext,
  TaskOptions,
  TaskMachineProps,
  TaskEvent,
  TSXEvent,
  LabContext,
} from '../../task-types';

export interface IDSA03Context extends TaskContext {
  lab01: LabContext;
}
type TContext = IDSA03Context;

export type IDSA03Event = TaskEvent | TSXEvent;
type TEvent = IDSA03Event;

export type IDA01MachineProps = TaskMachineProps<TContext>;

/**
 * Partially applied `addScore` action creator with:
 * `{ type: 'identity', id?: ``Game01.IDSA03.${id}`` }`
 */
const addScoreP = (amount: PointValue, id?: string) => {
  const computedId = id == null ? undefined : `Game01.IDSA03.${id}`;
  return addScore({ type: 'identity', amount, id: computedId });
};

/**
 * Machine config.
 */
export const IDSA03Config: TaskConfig<TContext, TEvent> = {
  id: 'IDSA03',
  initial: 'intro01',
  context: {
    lab01: {
      sectionIndex: 0,
      screenIndex: 0,
    },
  },
  states: {
    intro01: {
      initial: 'intro01a',
      states: {
        intro01a: {
          on: {
            'CLICK.B01': { actions: addScoreP(PointValue.Incorrect, 'intro01b.B01'), target: 'intro01b' },
            'CLICK.B02': { actions: addScoreP(PointValue.Critical, 'intro01b.B02'), target: '#IDSA03.lab01' },
            'CLICK.B03': { actions: addScoreP(PointValue.Incorrect, 'intro01b.B03'), target: 'intro01c' },
          },
        },
        intro01b: {
          on: {
            'CLICK.B04': { target: 'intro01a' },
          },
        },
        intro01c: {
          on: {
            'CLICK.B05': { target: 'intro01a' },
          },
        },
      },
    },
    lab01: {
      on: {
        'CLICK.B01': { target: 'end01' },
      },
    },
    end01: {
      on: {
        'CLICK.B01': { target: 'done' },
      },
    },
    done: {
      type: 'final',
    },
  },
};

/**
 * Additional machine options.
 */
export const IDSA03Options: TaskOptions<TContext, TEvent> = {};

/**
 * Machine constructor.
 */
export function createIDSA03() {
  return createMachine(IDSA03Config, getDefaultOptions()).withConfig(IDSA03Options);
}
