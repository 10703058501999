import { EntryFields } from '../types';

const GA01: Record<string, EntryFields> = {
  ALERT: {
    type: 'alert',
    category: 'alert',
  },
  LEAKED_FILE_ANALYSIS: {
    type: 'team',
    category: 'evidence',
  },
  LEAKED_FILE_PROPERTIES: {
    type: 'team',
    category: 'evidence',
  },
};
export default GA01;
