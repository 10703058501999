import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import ReactIs from 'react-is';
import { ISpinnerStyles, IStackStyles, IStackTokens, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';

export type LoadGateOnLoadCallback = (options: {
  done: () => void;
  setMessages: React.Dispatch<React.SetStateAction<string[]>>;
}) => void;

type LoadGateProps = {
  loading?: React.ComponentType<{ messages?: string[] }> | ReactNode | null;
  onBeforeLift?: () => void | Promise<any> | null;
  onLoad?: LoadGateOnLoadCallback;
};

const defaultOnBeforeLift = () => new Promise((resolve) => setTimeout(resolve, 1000));

const defaultLoaderStackStyles: IStackStyles = {
  root: {
    height: '50vh',
    width: '100vw',
    overflow: 'hidden',
  },
};

const defaultLoaderStackTokens: IStackTokens = {
  padding: 16,
  childrenGap: 16,
};

const defaultLoaderSpinnerStyles: ISpinnerStyles = {
  circle: {
    width: '64px',
    height: '64px',
    borderWidth: '5px',
  },
};

const defaultLoader: React.FC<{ messages?: string[] }> = ({ messages }) => {
  return (
    <>
      <Stack
        horizontalAlign="center"
        styles={defaultLoaderStackStyles}
        tokens={defaultLoaderStackTokens}
        verticalAlign="end"
        verticalFill
      >
        <Stack.Item>
          <Spinner size={SpinnerSize.large} styles={defaultLoaderSpinnerStyles} />
        </Stack.Item>
      </Stack>
      <Stack
        horizontalAlign="center"
        styles={defaultLoaderStackStyles}
        tokens={defaultLoaderStackTokens}
        verticalAlign="start"
        verticalFill
      >
        {messages?.map((msgValue, index) => (
          <Stack.Item key={index}>
            <Text variant="large">{msgValue}</Text>
          </Stack.Item>
        ))}
      </Stack>
    </>
  );
};

const LoadGate: React.FC<LoadGateProps> = ({ loading, onBeforeLift, onLoad, children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [messages, setMessages] = useState<string[]>(['Loading...']);

  // Handler for setIsLoaded() that runs onBeforeLift() once before calling setIsReady(true).
  const done = useCallback(
    () =>
      setIsLoaded((prevState: boolean) => {
        if (prevState === false) {
          Promise.resolve()
            .then(onBeforeLift)
            .finally(() => setIsReady(true));
        }
        return true;
      }),
    [onBeforeLift],
  );

  // Load game data on startup (only runs once)
  useEffect(() => {
    if (isLoaded) {
      return;
    }

    if (onLoad == null) {
      done();
      return;
    }

    if (typeof onLoad === 'function') {
      onLoad({ done, setMessages });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isReady) {
    return <>{children}</>;
  }

  if (ReactIs.isValidElementType(loading)) {
    if (typeof loading === 'function') {
      const LoadingComponent = loading;
      return <LoadingComponent messages={messages} />;
    }
    return <>{loading}</>;
  }

  return null;
};

LoadGate.defaultProps = {
  loading: defaultLoader,
  onBeforeLift: defaultOnBeforeLift,
};

export default LoadGate;
