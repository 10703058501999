import React, { useLayoutEffect, useMemo, useRef } from 'react';
import _set from 'lodash/set';
import _unescape from 'lodash/unescape';

import { DefaultNamespace, Namespace, TFuncKey, Trans, TransProps } from 'react-i18next';

/**
 * Recursive lodash unescape of text nodes.
 */
const unescapeChildNodes = (nodes: NodeListOf<ChildNode>) => {
  nodes.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.nodeValue != null) {
        const newNodeValue = _unescape(node.nodeValue);
        // eslint-disable-next-line no-param-reassign
        node.nodeValue = newNodeValue;
      }
      return;
    }
    if (node.hasChildNodes()) {
      unescapeChildNodes(node.childNodes);
    }
  });
};

/**
 * A version of the react-i18next Trans component with better HTML entities support.
 * NOTE: Basic HTML tags are now supported directly via i18next-react init options,
 * and do not need to be provided in the `components` property.
 */
const RTrans = <
  K extends TFuncKey<N> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  E extends Element = HTMLDivElement,
>(
  props: TransProps<K, N, E>,
): React.ReactElement => {
  // Ensure that i18next _does_ escape values
  const computedProps = useMemo(() => _set({ ...props }, 'tOptions.interpolation.escapeValue', true), [props]);

  // Unescape text nodes after Trans runs, but before React sanitizes.
  const wrapperRef = useRef<HTMLElement>(null);
  useLayoutEffect(() => {
    if (wrapperRef.current != null) {
      const wrapperEl = wrapperRef.current;
      if (wrapperEl.hasChildNodes()) {
        unescapeChildNodes(wrapperEl.childNodes);
      }
    }
  }, [wrapperRef]);

  return (
    <span ref={wrapperRef}>
      <Trans {...computedProps} />
    </span>
  );
};

export default RTrans;
