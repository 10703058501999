import { FontWeights, IModalStyles, IStackTokens, ITextSlots, ITextStyles, IStyleSet } from '@fluentui/react';

export const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
export const stackTokens: IStackTokens = { childrenGap: 15 };

export const dummyLabModalStyles: Partial<IModalStyles> = {
  main: {
    backgroundColor: 'rgba(40, 167, 233, 0.9)',
    width: 1040,
    maxWidth: 1040,
    height: 314,
  },
};

export const textCenterStyles: IStyleSet<ITextSlots> = {
  root: {
    textAlign: 'center',
  },
};

/**
 * Score point value enum.
 */
export enum PointValue {
  Critical = 4,
  Bonus = 1,
  Irrelevant = 0,
  NoPoints = 0,
  SoftIncorrect = -2,
  Incorrect = -4,
}
