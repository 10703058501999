import { persistConfig, persistor, store } from '.';
import { storage } from './persist-storage';
import { LocalSessionData, LocalSessionDataSchema } from '../lib/api/types';
import { TASK_STORAGE_PREFIX } from '../tasks/task-helpers';
import { setGameData } from './game-slice';

interface ClearLocalSessionOptions {
  /**
   * Clear redux-persist and task persistence data.
   */
  persist?: boolean;

  /**
   * Reset redux store to mostly initial state. Meant for logout without reload.
   * (Forces `persist: true` to clear redux-persist and task data.)
   */
   full?: boolean;
}

/**
 * Clear local session data, and optionally additional related persistence and
 * redux data.
 */
export const clearLocalSession = (options: ClearLocalSessionOptions = {}) => {
  storage.removeItemSync(`session:${persistConfig.key}`);

  // Clean persistence data (redux-persist and task machines).
  if (options.persist || options.full) {
    persistor.pause();
    storage.removeItemSync(`persist:${persistConfig.key}`);
    storage.getAllKeysSync().forEach((key) => {
      if (key.startsWith(TASK_STORAGE_PREFIX)) {
        storage.removeItemSync(key);
      }
    });
  }

  // Reset the redux store (except for gameData).
  if (options.full) {
    const savedGameData = store.getState().game.gameData;
    store.dispatch({ type: 'RESET_STORE' });
    store.dispatch(setGameData(savedGameData));
  }
};

/**
 * Update local session data.
 */
export const setLocalSession = (sessionData: LocalSessionData) => {
  storage.setItemSync(`session:${persistConfig.key}`, JSON.stringify(sessionData));
};

/**
 * Attempt to load the local session data.
 */
export const tryGetLocalSession = (): LocalSessionData | undefined => {
  try {
    const sessionData = window.localStorage.getItem(`session:${persistConfig.key}`);
    if (sessionData != null) {
      return LocalSessionDataSchema.parse(JSON.parse(sessionData));
    }
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return undefined;
};
