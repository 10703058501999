/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import pick from 'lodash/pick';

import type {
  APIManagerConfig,
  GameData,
  GameDataParams,
  GetGameParams,
  RegisterPlayerParams,
  RegisterPlayerResult,
  StartSessionParams,
  StartSessionResult,
  ValidateSessionParams,
  ValidateSessionResult,
} from './types';
import { validateSchema } from './validation';

/**
 * APIManager is a singleton intended to manage the connection between
 * The Training Arcade backend and a game.
 */

class APIManager {
  _config: APIManagerConfig;

  private _axios: AxiosInstance;

  constructor() {
    this._config = this.resetConfig();
    this._axios = axios.create();
    this._axios.interceptors.request.use((config: any) => {
      const c = config;
      if (this._config.key) {
        c.headers['X-TGA-KEY'] = this._config.key;
      }
      if (this._config.domain) {
        c.headers['X-DOMAIN'] = this._config.domain;
      }
      return c;
    });
  }

  /**
   * Set configuration of the TTAMManager
   * Should non-destructively merge any valid params
   * @param {Object} p  params allowed are listed in the _config object/schema
   * @param {Boolean=} skipValidation skip the config validation process (use at your own risk!)
   */
  async config(params: Partial<typeof this._config> = {}, skipValidation = false) {
    if (!skipValidation) {
      await validateSchema(params, 'config');
    }
    const newConfig = {
      ...this._config,
      ...params,
    };
    this._config = newConfig;
    return this._config;
  }

  /**
   * Provide the config object
   */
  getConfig() {
    return this._config;
  }

  /**
   * Resets the config object, setting everything to null
   */
  resetConfig() {
    this._config = {
      domain: undefined,
      url: undefined,
      id: undefined,
      key: undefined,
      gameData: undefined,
    };
    return this._config;
  }

  /**
   * Helper function to validate that proper config params are set
   * @param {Array} props array of config property keys
   */
  validateConfig(props: string[]) {
    const c = pick(this._config, props);
    validateSchema(c, 'config', `config props not properly set: ${JSON.stringify(c)}`);
    return c;
  }

  /**
   * Register a player and store the authentication key, domain if valid
   * https://api.thetrainingarcade.com/documentation/#api-Player-RegisterPlayer
   * (username or email required)
   * @param {Object} data
   * @param {string=} data.username
   * @param {string=} data.email
   * @param {string=} data.domain
   * @param {string=} data.password
   * @param {string=} data.first_name
   * @param {string=} data.last_name
   * @param {string=} data.initials
   * @param {string=} data.display_name
   * @param {string=} data.phone
   * @param {string=} data.employee_id
   * @param {string=} data.organization
   * @param {string=} data.country
   */
  async registerPlayer(data: RegisterPlayerParams) {
    if (data.username == null && data.email == null) {
      throw new Error('must provide username or email');
    }
    const config = await this.validateConfig(['url', 'domain']);
    const result: AxiosResponse<RegisterPlayerResult> = await this._axios.request({
      method: 'POST',
      url: `${config.url}/player/register`,
      data: {
        ...pick(config, 'domain'),
        ...data,
      },
    });
    if (result.status === 201) {
      this.config(pick(result.data, 'key', 'id'), true);
    }
    return result;
  }

  /**
   * Start tracking a new game session.
   * https://api.thetrainingarcade.com/documentation/#api-Player-PostSession
   * @param {Object} data
   * @param {Number=} data.game_id
   * @param {Number=} data.language_id
   * @param {string=} data.segment
   * @param {Number=} data.level_id
   * @param {Number=} data.session_group_id
   */
  async startSession(data: GameDataParams<StartSessionParams> = {}) {
    const config = await this.validateConfig(['url', 'key', 'gameData']);
    const result: AxiosResponse<StartSessionResult> = await this._axios.request({
      method: 'POST',
      url: `${config.url}/player/session`,
      data: {
        ...pick(config.gameData, 'game_id', 'language_id', 'session_group_id'),
        ...data,
      },
    });
    return result;
  }

  /**
   * Validate a session.
   * https://api.thetrainingarcade.com/documentation/#api-Player-ValidateSession
   * @param {Object} data
   * @param {Number=} data.game_id
   * @param {string=} data.uuid
   */
  async validateSession(data: GameDataParams<ValidateSessionParams>) {
    const config = await this.validateConfig(['url', 'key', 'gameData']);
    const result: AxiosResponse<ValidateSessionResult> = await this._axios.request({
      method: 'POST',
      url: `${config.url}/player/validate`,
      data: {
        ...pick(this._config.gameData, 'game_id'),
        ...data,
      },
    });
    return result;
  }

  /**
   * Get player's high score for a game.
   * https://api.thetrainingarcade.com/documentation/#api-Player-GetScore
   * @param {Object} params
   * @param {Number=} params.game_id
   * @param {string=} params.best
   */
  async getScore(params: any) {
    const config = await this.validateConfig(['url', 'key', 'gameData']);
    await validateSchema(params, 'getScore');

    try {
      const result = await this._axios({
        method: 'GET',
        url: `${config.url}/player/score`,
        params,
      });
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Add player's score for a game. Only the player's high score for each game
   * is reported in Score and Leaderboard calls.
   * https://api.thetrainingarcade.com/documentation/#api-Player-PostScore
   * @param {Object} data
   * @param {Number=} data.game_id
   * @param {Number=} data.points
   * @param {Number=} data.time
   * @param {Boolean=} data.complete
   * @param {Number=} data.level_id
   */
  async addScore(data: any) {
    const config = await this.validateConfig(['url', 'key', 'gameData']);
    await validateSchema(data, 'addScore');
    const { game_id, session_group_id } = this._config.gameData ?? {};
    try {
      const result = await this._axios({
        method: 'POST',
        url: `${config.url}/player/score`,
        data: {
          game_id,
          ...(session_group_id && { session_group_id }),
          ...data,
        },
      });
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Add player metadata
   * https://api.thetrainingarcade.com/documentation/#api-Player-PostPlayerData
   * @param {Object} data
   */
  async addPlayerMetadata(data: any) {
    const config = await this.validateConfig(['url', 'key']);
    await validateSchema(data, 'addPlayerMetadata');

    try {
      const result = await this._axios({
        method: 'POST',
        url: `${config.url}/player/data`,
        data,
      });
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Add session metadata
   * https://api.thetrainingarcade.com/documentation/#api-Player-PostSessionData
   * @param {Object} data
   * @param {Number=} data.session_id
   * @param {string=} data.data
   */
  async addSessionMetadata(data: any) {
    const config = await this.validateConfig(['url', 'key']);
    await validateSchema(data, 'addSessionMetadata');

    try {
      const result = await this._axios({
        method: 'POST',
        url: `${config.url}/player/data`,
        data,
      });
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Get player metadata
   * https://api.thetrainingarcade.com/documentation/#api-Player-GetPlayerData
   */
  async getPlayerMetadata() {
    const config = await this.validateConfig(['url', 'key']);

    try {
      const result = await this._axios({
        method: 'GET',
        url: `${config.url}/player/data`,
      });
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Get player session metadata
   * https://api.thetrainingarcade.com/documentation/#api-Player-GetSessionData
   * @param {Object} data
   * @param {Number=} data.session_id
   */
  async getPlayerSessionMetadata(data: any) {
    const config = await this.validateConfig(['url', 'key']);
    await validateSchema(data, 'getPlayerSessionMetadata');

    try {
      const result = await this._axios({
        method: 'POST',
        url: `${config.url}/player/data/${data.session_id}`,
        data,
      });
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Get game information. Note: domain should be configured prior and is not required
   * https://api.thetrainingarcade.com/documentation/#api-Game-GetGame
   * @param {Object} params
   * @param {string=} params.domain
   * @param {Number=} params.game_id
   * @param {string=} params.slug
   * @param {string=} params.token
   */
  async getGame(params: GetGameParams) {
    if (params.game_id == null && params.slug == null) {
      throw new Error('need either a game_id or slug');
    }
    const config = await this.validateConfig(['url', 'domain']);
    const result: AxiosResponse<GameData> = await this._axios.request({
      method: 'GET',
      url: `${config.url}/game`,
      params: {
        domain: config.domain,
        ...params,
      },
    });
    this.config({ gameData: result.data });
    return result;
  }

  /**
   * Get list of high scores for a game
   * https://api.thetrainingarcade.com/documentation/#api-Stats-getHighScores
   * @param {Object} params
   * @param {string=} params.game_id
   * @param {string=} params.best
   * @param {Number=} params.limit
   */
  async getHighScores(params: any) {
    const config = await this.validateConfig(['url', 'key']);

    await validateSchema(params, 'getHighScores');

    try {
      const result = await this._axios({
        method: 'GET',
        url: `${config.url}/stats/highscores`,
        params,
      });
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Get highest score for a game
   * https://api.thetrainingarcade.com/documentation/#api-Stats-getHighScore
   * @param {Object} params
   * @param {string=} params.game_id
   * @param {string=} params.best
   * @param {string=} params.start_date
   * @param {string=} params.end_date
   */
  async getHighestScore(params: any) {
    const config = await this.validateConfig(['url', 'key']);

    await validateSchema(params, 'getHighestScores');

    try {
      const result = await this._axios({
        method: 'GET',
        url: `${config.url}/stats/highscore`,
        params,
      });
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Get Get leaderboard for a game
   * https://api.thetrainingarcade.com/documentation/#api-Stats-GetLeaderboard
   * @param {Object} params
   * @param {string=} params.game_id
   * @param {string=} params.best
   * @param {Number=} params.limit
   * @param {string=} params.start_date
   * @param {string=} params.end_date
   */
  async getLeaderboard(params: any) {
    const config = await this.validateConfig(['url', 'key']);

    await validateSchema(params, 'getLeaderboard');

    try {
      const result = await this._axios({
        method: 'GET',
        url: `${config.url}/stats/leaderboard`,
        params,
      });
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}

const APIManagerSingleton = new APIManager();

export { APIManagerSingleton as APIManager };
