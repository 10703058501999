import React from 'react';
import { Text, Stack, DefaultEffects, ITheme, FontWeights } from '@fluentui/react';
import { IR01TaskProps } from '../../interface';
import RTrans from '../../../../../components/RTrans/RTrans';

interface IRSummaryProps extends IR01TaskProps {
  theme: ITheme;
  date: string;
}

const IRSummary: React.FunctionComponent<IRSummaryProps> = ({ t, theme, date }) => {
  return (
    <Stack
      styles={{
        root: {
          backgroundColor: theme.palette.neutralLighterAlt,
          padding: 24,
          boxShadow: DefaultEffects.elevation16,
        },
      }}
      tokens={{ childrenGap: 16 }}
    >
      <Stack.Item>
        <Text variant="xLarge" styles={{ root: { fontWeight: FontWeights.bold } }}>
          {t('ir.summary.header')}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant="mediumPlus">
          <RTrans t={t} components={{ date }} i18nKey="ir.summary.body" />
        </Text>
      </Stack.Item>
    </Stack>
  );
};

export default IRSummary;
