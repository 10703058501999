import React, { useEffect, useMemo } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';
import dateFormat from 'date-fns/format';
import { Stack, getTheme, IStackStyles, IStackTokens, Text, FontWeights, mergeStyles, PrimaryButton } from '@fluentui/react';
import ScreenModal from '../../../components/ScreenModal/ScreenModal';
import { RootState } from '../../../store';
import { maxPossibleScores } from '../../../static/scoring-data';
import { UserRole } from '../../../lib/roles';
import { APIManager } from '../../../lib/api/APIManager';
import { clearLocalSession } from '../../../store/local-session';
import { isDev } from '../../../lib/util';
import { selectUserIsRegistered } from '../../../store/user-slice';

const baseNS = 'Game01.Ending';
const theme = getTheme();

const innerStackStyles: IStackStyles = {
  root: {
    backgroundColor: theme.palette.neutralLighterAlt,
  },
};

const detailStackTokens: IStackTokens = {
  padding: 16,
  childrenGap: 16,
};

const scoreHStackTokens: IStackTokens = {
  padding: '16px 32px',
};

const scoreVStackTokens: IStackTokens = {
  childrenGap: 16,
};

const scoreHeadingStyle = mergeStyles(theme.fonts.medium, {
  fontWeight: FontWeights.bold,
  textTransform: 'uppercase',
});

const scoreValueStyle = mergeStyles(theme.fonts.medium, {});

/**
 * Selector that compiles player scores, maximums and rank based on role.
 */
const calculateScores = ({ user, game: { score } }: RootState) => {
  const out = {
    playerScore: -1,
    maxPlayerScore: -1,
    IRScore: score.IR,
    maxIRScore: maxPossibleScores.IR,
    totalPlayerScore: -1,
    maxTotalPlayerScore: -1,
    playerRank: '?',
  };
  // For the MVP, "player score" is a combination of group and role activity scores.
  switch (user.role) {
    case UserRole.Identity:
      out.playerScore = score.group + score.identity;
      out.maxPlayerScore = maxPossibleScores.group + maxPossibleScores.identity;
      break;
    case UserRole.Security:
      out.playerScore = score.group + score.security;
      out.maxPlayerScore = maxPossibleScores.group + maxPossibleScores.security;
      break;
    default:
      break;
  }
  out.totalPlayerScore = out.playerScore + out.IRScore;
  out.maxTotalPlayerScore = out.maxPlayerScore + out.maxIRScore;

  // Calculate rank from percentage of total player score.
  const percentage = (out.totalPlayerScore / out.maxTotalPlayerScore) * 100;
  if (percentage >= 96) {
    out.playerRank = 'S';
  } else if (percentage >= 86) {
    out.playerRank = 'A';
  } else if (percentage >= 73) {
    out.playerRank = 'B';
  } else if (percentage >= 50) {
    out.playerRank = 'C';
  } else {
    out.playerRank = 'D';
  }

  return out;
};

/**
 * Selector that gets names for roles.
 */
const getNames = ({ user }: RootState) => ({
  idName: user.role === UserRole.Identity ? user.username : '$t(nameUnavailable)',
  secName: user.role === UserRole.Security ? user.username : '$t(nameUnavailable)',
  compName: '$t(nameUnavailable)',
});

/**
 * Selector that gets important raw values for addScore call.
 */
const getAddScoreData = ({ user, game }: RootState) => ({
  role: user.role,
  score: game.score,
  sessionStartTime: game.sessionStartTime,
});

const Ending: React.FC = () => {
  const { t } = useTranslation(baseNS);
  const scores = useSelector(calculateScores, _isEqual);
  const names = useSelector(getNames, _isEqual);
  const isRegistered = useSelector(selectUserIsRegistered);
  const addScoreData = useSelector(getAddScoreData, _isEqual);
  const currentTask = useSelector(({ game: { taskHistory } }: RootState) => taskHistory[taskHistory.length - 1]);
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dateString = useMemo(() => dateFormat(Date.now(), 'PPP'), [scores, names]);

  useEffect(() => {
    // Skip if unregistered.
    if (!isRegistered) {
      // [Staging/Production] Kick user back to the start UNLESS their most recent task is Game01.IR01.
      // TODO: This is kind of a hacky solution, but I can't use history state because we're using HashRouter.
      if (!isDev && currentTask !== 'Game01.IR01') {
        history.push('/');
        return;
      }

      // eslint-disable-next-line no-console
      console.warn('Skipping APIManager.addScore because user is not registered');
      return;
    }

    // Add/update score.
    APIManager.addScore({
      player_role: addScoreData.role,
      group_score: addScoreData.score.group,
      identity_score: addScoreData.score.identity,
      security_score: addScoreData.score.security,
      ir_score: addScoreData.score.IR,
      points: scores.totalPlayerScore,
      time: Date.now() - addScoreData.sessionStartTime,
      complete: true,
    })
      .then((data) => {
        // eslint-disable-next-line no-console
        console.log('APIManager.addScore success:', data);
      })
      .catch((reason) => {
        // eslint-disable-next-line no-console
        console.warn('APIManager.addScore failure:', reason);
      });

    // Only clear the session key, so that persistent state lasts until logout or refresh.
    clearLocalSession();
  }, [currentTask, addScoreData, isRegistered, history, scores]);

  const [showSurvey, { toggle: toggleShowSurvey }] = useBoolean(false);

  return (
    <Stack verticalFill grow={1} verticalAlign="center">
      <Stack.Item styles={{ root: { display: showSurvey ? 'none' : 'block' } }}>
        <ScreenModal title={t('title')}>
          <Stack>
            <Stack.Item>
              <Stack styles={innerStackStyles}>
                <Stack tokens={detailStackTokens}>
                  <Stack.Item>
                    <Trans t={t} i18nKey="incidentID" />
                  </Stack.Item>
                  <Stack.Item>
                    <Trans t={t} i18nKey="date" values={{ date: dateString }} />
                  </Stack.Item>
                  <Stack.Item>
                    <Trans t={t} i18nKey="idName" values={{ name: names.idName }} />
                  </Stack.Item>
                  <Stack.Item>
                    <Trans t={t} i18nKey="secName" values={{ name: names.secName }} />
                  </Stack.Item>
                  <Stack.Item>
                    <Trans t={t} i18nKey="compName" values={{ name: names.compName }} />
                  </Stack.Item>
                </Stack>
                <Stack grow={1} horizontal tokens={scoreHStackTokens}>
                  <Stack grow={1} tokens={scoreVStackTokens}>
                    <Stack.Item>
                      <Text block className={scoreHeadingStyle}>
                        {t('playerScoreHeading')}
                      </Text>
                      <Text block className={scoreValueStyle}>
                        {t('scoreOutOf', { score: scores.playerScore, max: scores.maxPlayerScore })}
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text block className={scoreHeadingStyle}>
                        {t('IRScoreHeading')}
                      </Text>
                      <Text block className={scoreValueStyle}>
                        {t('scoreOutOf', { score: scores.IRScore, max: scores.maxIRScore })}
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text block className={scoreHeadingStyle}>
                        {t('totalPlayerScoreHeading')}
                      </Text>
                      <Text block className={scoreValueStyle}>
                        {t('scoreOutOf', { score: scores.totalPlayerScore, max: scores.maxTotalPlayerScore })}
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text block className={scoreHeadingStyle}>
                        {t('playerRankHeading')}
                      </Text>
                      <Text block className={scoreValueStyle}>
                        {scores.playerRank}
                      </Text>
                    </Stack.Item>
                  </Stack>
                  <Stack grow={1} tokens={scoreVStackTokens}>
                    <Stack.Item>
                      <Text block className={scoreHeadingStyle}>
                        {t('teamScoreHeading')}
                      </Text>
                      <Text block className={scoreValueStyle}>
                        {t('teamScore')}
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text block className={scoreHeadingStyle}>
                        {t('totalTeamScoreHeading')}
                      </Text>
                      <Text block className={scoreValueStyle}>
                        {t('totalTeamScore')}
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text block className={scoreHeadingStyle}>
                        {t('teamRankHeading')}
                      </Text>
                      <Text block className={scoreValueStyle}>
                        {t('teamRank')}
                      </Text>
                    </Stack.Item>
                  </Stack>
                </Stack>
              </Stack>
            </Stack.Item>
          </Stack>
        </ScreenModal>
      </Stack.Item>
      <Stack.Item styles={{ root: { width: 1040, margin: '0 auto', display: showSurvey ? 'none' : 'flex', justifyContent: 'flex-end' } }}>
        <PrimaryButton onClick={() => toggleShowSurvey()}>
          <Trans t={t} i18nKey="toSurvey" />
        </PrimaryButton>
      </Stack.Item>
      <Stack.Item grow={1} styles={{ root: { width: '100%', overflow: 'hidden', display: showSurvey ? 'block' : 'none' } }}>
        {showSurvey && (<iframe title="Project Squire Survey" src="https://forms.office.com/r/w3ZbxkSaUq" style={{ minWidth: 350, minHeight: 350, width: '100%', height: '100%', border: 'none' }} />)}
      </Stack.Item>
    </Stack>
  );
};

export default Ending;
