/**
 * Schema validators and types.
 */
/* eslint-disable @typescript-eslint/no-redeclare */
import { z } from 'zod';

import type { AckResult, AckResultCallback } from './typed-events';

/**
 *
 * Schemas
 *
 */

/**
 * Listen event params for `room:status`
 */
export const ClientEventRoomStatus = z.object({
  room: z.string().min(1),
  action: z.enum(['joined', 'left', 'who']),
  users: z.array(z.string()),
});

export type ClientEventRoomStatus = z.infer<typeof ClientEventRoomStatus>;

/**
 * Emit event params for `room:status`
 */
export const ClientEmitRoomStatus = z.object({
  room: z.string().min(1),
});
export type ClientEmitRoomStatus = z.infer<typeof ClientEmitRoomStatus>;

/**
 * Listen event parameters for 'status:user' event.
 * (Server-side type: {@link IOSocketDataSafe})
 */
export const ClientEventUserStatus = z.object({
  status: z.enum(['connected', 'logged-in']).nullable(),
  username: z.string().nullable(),
  events: z.array(z.string()),
  sessions: z.array(z.string()),
});
export type ClientEventUserStatus = z.infer<typeof ClientEventUserStatus>;

/**
 * Default value generator for StatusEventParameters (i.e. for Redux)
 */
export const getDefaultUserStatusEventParameters = (): ClientEventUserStatus => ({
  status: null,
  username: null,
  events: [],
  sessions: [],
});

/**
 *
 * Helper Methods
 *
 */

export const noopAckResultCallback: AckResultCallback = () => undefined;

/**
 * (Overload `1) Process raw event arguments and extract callback.
 *
 * @param {unknown[]} eventArgs - The raw arguments from the event (e.g. "rest" parameters)
 * @return {[unknown, AckResultCallback]} A tuple containing:
 *   `[0]ack`: An AckResultCallback (extracted from last argument, otherwise a no-op);
 *   `[1]args`: Unprocessed event parameter(s), if any (wrapped in an array if length > 1).
 */
 export function processEventArgs<TResult extends AckResult = AckResult>(
  eventArgs: unknown[],
): [ack: AckResultCallback<TResult>, args: unknown | unknown[]];

/**
 * (Overload `2) Process and validate raw event arguments and extract callback.
 *
 * @param {unknown[]} eventArgs - The raw arguments from the event (e.g. "rest" parameters)
 * @param {z.Schema} schema - A parseable `Zod` schema object.
 * @return {[z.SafeParseReturnType, AckResultCallback]} A tuple containing:
 *   `[1]ack`: An `AckResultCallback` (extracted from last argument, otherwise a no-op);
 *   `[0]args`: A `z.SafeParseReturnType` result.
 */
export function processEventArgs<
  TResult extends AckResult = AckResult,
  Output = unknown,
  Def extends z.ZodTypeDef = z.ZodTypeDef,
  Input = Output,
>(
  eventArgs: unknown[],
  schema: z.Schema<Output, Def, Input>,
): [ack: AckResultCallback<TResult>, args: z.SafeParseReturnType<Input, Output>];

/**
 * Implementation of `processEventArgs()`
 */
export function processEventArgs<
  TResult extends AckResult = AckResult,
  Output = unknown,
  Def extends z.ZodTypeDef = z.ZodTypeDef,
  Input = Output,
>(
  eventArgs: unknown[],
  schema?: z.Schema<Output, Def, Input>,
): [ack: AckResultCallback<TResult>, args: unknown | unknown[] | z.SafeParseReturnType<Input, Output>] {
  // Clone the input array because we will probably mutate it.
  const args = [...eventArgs];

  // If last argument is a function, extract it as an AckResultCallback. Otherwise, provide a stub no-op).
  const ack = (typeof args.slice(-1)[0] === 'function' ? args.pop() : noopAckResultCallback) as AckResultCallback<TResult>;

  // If 1 or fewer arguments remains, unwrap the array.
  const outArg = args.length <= 1 ? args[0] : args;

  // Parse arg(s) with schema or pass them through, and return tuple with callback.
  return [ack, schema == null ? outArg : schema.safeParse(outArg)];
}
