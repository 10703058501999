import React from 'react';
import { motion } from 'framer-motion';
import { Callout, Text, IButtonStyles, DirectionalHint, FontWeights } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_TRANSITION_TIME } from '../../lib/anim-timings';

export const baseIconButtonStyles: IButtonStyles = {
  root: {
    height: 'auto',
    width: 92,
    minHeight: 92,
    paddingTop: 12,
    pointer: 'cursor',
    label: {
      marginTop: 12,
      pointerEvents: 'none',
    },
  },
  rootPressed: {
    height: 'auto',
  },
  flexContainer: {
    flexDirection: 'column',
  },
};

export interface NavBarButtonProps {
  unread: number;
  target: string;
  isActive: boolean;
}

const variants = {
  read: { opacity: 0, scale: 0 },
  unread: { opacity: 1, scale: 1 },
};

const NavBarUnread: React.FunctionComponent<NavBarButtonProps> = ({ unread, target, isActive }) => {
  const show = unread !== -1 && !isActive;
  const { t } = useTranslation('navBar');

  return (
    <>
      {show && (
        <Callout
          target={`#${target}`}
          isBeakVisible
          doNotLayer
          beakWidth={16}
          directionalHint={DirectionalHint.leftCenter}
          styles={{
            root: {
              width: 150,
              padding: '20px',
              top: '-42px!important',
            },
          }}
        >
          <Text
            block
            variant="medium"
            styles={{
              root: {
                fontWeight: FontWeights.bold,
              },
            }}
          >
            {`${unread} ${t(`unread.new${unread < 2 ? 'Entry' : 'Entries'}`)}`}
          </Text>
          <Text block variant="small">
            {t('unread.clickToView')}
          </Text>
        </Callout>
      )}
      <motion.div
        variants={variants}
        initial={{ opacity: 0, scale: 0 }}
        animate={show ? 'unread' : 'read'}
        style={{
          width: 12,
          height: 12,
          left: 4,
          top: -12,
          transformOrigin: 'center',
          position: 'absolute',
          backgroundColor: '#ef476f',
          borderRadius: 120,
        }}
        transition={{ duration: NOTIFICATION_TRANSITION_TIME / 1000 }}
      />
    </>
  );
};

export default NavBarUnread;
