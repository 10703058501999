import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { inspect } from '@xstate/inspect';
import './i18n';
import { mergeStyles } from '@fluentui/merge-styles';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { store } from './store';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { isDev } from './lib/util';
import LoadGate from './components/LoadGate/LoadGate';
import { initAPIAndTryReload } from './lib/api/init';

initializeIcons();
initializeFileTypeIcons();

// Only enable inspector in development mode (i.e. `npm run start`)
if (isDev) {
  inspect({
    url: 'https://statecharts.io/inspect',
    iframe: false,
  });
}

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
  },
});

ReactDOM.render(
  <Router>
    <ReduxProvider store={store}>
      <LoadGate onLoad={initAPIAndTryReload}>
        <App />
      </LoadGate>
    </ReduxProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
