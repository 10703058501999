import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useTaskMachine } from '../../../lib/hooks/useTaskMachine';
import { createIntro } from './machine';
import { ScreenModalSidebarProps } from '../../../components/ScreenModal/ScreenModal';
import Intro1 from './components/Intro1';
import Intro2 from './components/Intro2';
import IntroTutorial from './components/IntroTutorial';

const baseNS = 'Game01.Intro';
const taskKey = 'Game01.Intro';

const Intro: React.FunctionComponent = () => {
  const { t } = useTranslation([baseNS, 'roles']);
  const history = useHistory();

  // Setup task machine
  const [state, send] = useTaskMachine(createIntro, {
    taskKey,
  });

  // Memoize IR manager persona
  const IRManager: ScreenModalSidebarProps = useMemo(
    () => ({
      title: t('IRManager.name'),
      persona: {
        imageInitials: t('IRManager.initials'),
      },
    }),
    [t],
  );

  // enumerating the intro machine states so can have an easier time with the tutorial
  const index = parseInt(state.value.toString().charAt(state.value.toString().length - 1), 10);

  const gotoNext = () => {
    send('NEXT');
  };

  useEffect(() => {
    const isDone = state.matches('done');
    if (isDone) {
      history.push('/tasks/Game01/GA01');
    }
  }, [state, history]);

  return (
    <>
      {/**
       * Intro screen
       */}
      {state.matches('intro1') && <Intro1 t={t} sidebar={IRManager} showModal={state.matches('intro1.modalOpen')} send={send} onClick={gotoNext} />}
      {state.matches('intro2') && <Intro2 t={t} sidebar={IRManager} onClick={gotoNext} />}
      {index > 2 && (<IntroTutorial t={t} onClick={gotoNext} index={index} sidebar={IRManager} />)}
    </>
  );
};

export default Intro;
