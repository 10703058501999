import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text, Stack } from '@fluentui/react';
import NavPanel from '../NavPanel/NavPanel';
import JournalFilterButton from '../JournalFilterButton/JournalFilterButton';
import JournalFilterHeader from '../JournalFilterHeader/JournalFilterHeader';
import Entry from '../Entry/Entry';
import { RootState } from '../../store';
import { generateEntries } from '../../lib/entries';
import { filterOptions } from '../../lib/journal-filter';

const Journal: React.FunctionComponent = () => {
  const journal = useSelector((state: RootState) => state.journal);
  const activeJournalFilter = useSelector((state: RootState) => state.game.activeJournalFilter);
  const lastActiveJournalFilter = useSelector((state: RootState) => state.game.lastActiveJournalFilter);
  const { t } = useTranslation('journal');
  const journalEntries = generateEntries(journal, activeJournalFilter);
  const activeFilters = filterOptions.filter((f) => journalEntries.some((j) => j.type === f.type) || f.type === 'all');
  let entries = journalEntries;
  // this is to re-arrange the order of the entries when switching between filters so the transition looks good
  if (activeJournalFilter !== 'all' && lastActiveJournalFilter !== 'all') {
    entries = journalEntries.filter((j) => j.type !== activeJournalFilter);
    const filtered = journalEntries.filter((j) => j.type === activeJournalFilter);
    filtered.forEach((j) => {
      entries.push(j);
    });
  }
  const hostId = 'journal-content';
  const onOpen = () => {
    const scrollableContent = document.querySelector<HTMLElement>(`#${hostId} .ms-Panel-scrollableContent`);
    if (scrollableContent) {
      scrollableContent.scrollTop = scrollableContent.scrollHeight;
    }
  };
  return (
    <NavPanel
      activePanel="journal"
      focusSelector=".selected"
      onOpen={onOpen}
      hostId={hostId}
    >
      <Stack>
        <Stack.Item>
          <Stack>
            <Stack.Item>
              <Text variant="xxLarge" block styles={{ root: { marginBottom: 24 } }}>
                {t('header')}
              </Text>
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal tokens={{ childrenGap: 12 }}>
                {activeFilters.map(({ icon, type }) => (
                  <JournalFilterButton key={type} icon={icon} type={type} />
                ))}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <JournalFilterHeader />
        </Stack.Item>
        <Stack.Item>
          <Stack verticalAlign="start">
            {entries.map((jProps) => (
              <Entry key={jProps.entry_id} {...jProps} />
            ))}
          </Stack>
        </Stack.Item>
      </Stack>
    </NavPanel>
  );
};
export default Journal;
