/**
 * i18next NS: Game01.Registration
 */
export default {
  title: {
    title: "The Blunder at Best for You Organics",
    release: "v1.1 Release Oct 25, 2021",
    button: "Start",
  },

  registration: {
    title: "Registration",
    form: {
      displayName: "Type Display Name",
      role: "Select Role<br/><i>Make sure to communicate with your partner and select different roles.</i>",
      required: "This field is required",
      dropdownPlaceholder: "Select a role",
      done: "Done",
    },
  },
};
