import { createMachine } from 'xstate';

import { getDefaultOptions } from '../../task-options';
import type { TaskConfig, TaskContext, TaskOptions, TaskMachineProps, TaskEvent, TSXEvent } from '../../task-types';

export interface IntroContext extends TaskContext { }
type TContext = IntroContext;

export type IntroEvent = TaskEvent | TSXEvent;
type TEvent = IntroEvent;

export type IntroMachineProps = TaskMachineProps<TContext>;

/**
 * Machine config.
 */
export const IntroConfig: TaskConfig<TContext, TEvent> = {
  id: 'Intro',
  initial: 'intro1',
  states: {
    intro1: {
      initial: 'modalClosed',
      states: {
        modalClosed: {
          on: {
            'CLICK.MODAL_OPEN': { target: 'modalOpen' },
          },
        },
        modalOpen: {
          on: {
            'CLICK.MODAL_CLOSE': { target: 'modalClosed' },
          },
        },
      },
      on: {
        NEXT: {
          target: 'intro2',
        },
      },
    },
    intro2: {
      on: {
        NEXT: {
          target: 'intro3',
        },
      },
    },
    intro3: {
      on: {
        NEXT: {
          target: 'intro4',
        },
      },
    },
    intro4: {
      on: {
        NEXT: {
          target: 'intro5',
        },
      },
    },
    intro5: {
      on: {
        NEXT: 'intro6',
      },
    },
    intro6: {
      on: {
        NEXT: 'done',
      },
    },
    done: {
      type: 'final',
    },
  },
};

/**
 * Additional machine options.
 */
export const IntroOptions: TaskOptions<TContext, TEvent> = {};

/**
 * Machine constructor.
 */
export function createIntro() {
  return createMachine(IntroConfig, getDefaultOptions()).withConfig(IntroOptions);
}
