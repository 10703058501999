import Registration from "./Registration";
import Intro from "./Intro";
import GA01 from "./GA01";
import IDSA01 from "./IDSA01";
import IDSA03 from "./IDSA03";
import SECSA02 from "./SECSA02";
import SECSA03 from "./SECSA03";
import IR01 from "./IR01";
import Ending from "./Ending";

export default {
  "Game01.Registration": Registration,
  "Game01.Intro": Intro,
  "Game01.GA01": GA01,
  "Game01.IDSA01": IDSA01,
  "Game01.IDSA03": IDSA03,
  "Game01.SECSA02": SECSA02,
  "Game01.SECSA03": SECSA03,
  "Game01.IR01": IR01,
  "Game01.Ending": Ending,
};
