/* eslint-disable max-len */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import NavPanel from '../NavPanel/NavPanel';
import PanelUnavailable from '../PanelUnavailable/PanelUnavailable';
import { clearLocalSession } from '../../store/local-session';

const Settings: React.FunctionComponent = () => {
  const { t } = useTranslation('settings');
  const history = useHistory();

  const [isLogoutDialogHidden, { setTrue: hideLogoutDialog, toggle: toggleLogoutDialog }] = useBoolean(true);
  const logout = useCallback(() => {
    hideLogoutDialog();
    clearLocalSession({ full: true });
    history.push('/');
  }, [hideLogoutDialog, history]);

  return (
    <NavPanel activePanel="settings">
      <Text variant="xxLarge" block styles={{ root: { marginBottom: 24 } }}>
        {t('header')}
      </Text>
      <Stack>
        <Stack.Item>
          <PrimaryButton iconProps={{ iconName: 'signout' }} onClick={toggleLogoutDialog}>Logout</PrimaryButton>
        </Stack.Item>
      </Stack>
      <PanelUnavailable />
      <Dialog
        hidden={isLogoutDialogHidden}
        onDismiss={toggleLogoutDialog}
        dialogContentProps={{
          type: DialogType.close,
          title: t('confirmationDialog.title'),
          subText: t('confirmationDialog.body'),
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={logout} text={t('confirmationDialog.confirmButton')} />
          <DefaultButton onClick={toggleLogoutDialog} text={t('confirmationDialog.cancelButton')} />
        </DialogFooter>
      </Dialog>
    </NavPanel>
  );
};
export default Settings;
