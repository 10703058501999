export interface JournalFilterInterface {
  icon: string;
  type: string;
}

export const filterColors = [
  {
    type: 'all',
    color: '#480ca8',
    backgroundColor: '#ece6f6',
  },
  {
    type: 'identity',
    color: '#ffd166',
    backgroundColor: '#fffaef',
  },
  {
    type: 'security',
    color: '#06d6a0',
    backgroundColor: '#e6faf5',
  },
  {
    type: 'compliance',
    color: '#4895ef',
    backgroundColor: '#ecf4fd',
  },
  {
    type: 'alert',
    color: '#ef476f',
    backgroundColor: '#fdecf0',
  },
  {
    type: 'team',
    color: '#FB9101',
    backgroundColor: '#fdedd5',
  },
];

export const filterOptions: JournalFilterInterface[] = [
  {
    icon: 'ViewAll',
    type: 'all',
  },
  {
    icon: 'Group',
    type: 'team',
  },
  {
    icon: 'Zoom',
    type: 'identity',
  },
  {
    icon: 'ShieldSolid',
    type: 'security',
  },
  {
    icon: 'DecisionSolid',
    type: 'compliance',
  },
  {
    icon: 'Important',
    type: 'alert',
  },
];
