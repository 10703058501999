import React, { useState } from 'react';
import { Text, FontWeights, DefaultButton, Stack, Icon, Checkbox, PrimaryButton } from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { useStore } from 'react-redux';
import ScreenModal, { ScreenModalSidebarProps } from '../../../../components/ScreenModal/ScreenModal';
import { GA01ComponentProps } from '../interface';
import ResultModal from '../../../../components/ResultModal/ResultModal';
import RTrans from '../../../../components/RTrans/RTrans';
import { PointValue } from '../../../task-common';
import { addScore } from '../../../../store/game-slice';

interface CriticalInformationProps extends GA01ComponentProps {
  sidebar: ScreenModalSidebarProps;
  state: any;
}

const checkboxData = [
  {
    id: 'title',
    type: PointValue.Critical,
    localeKey: 'checkbox1Text',
  },
  {
    id: 'author',
    type: PointValue.Critical,
    localeKey: 'checkbox2Text',
  },
  {
    id: 'program',
    type: PointValue.Irrelevant,
    localeKey: 'checkbox3Text',
  },
  {
    id: 'created',
    type: PointValue.Bonus,
    localeKey: 'checkbox4Text',
  },
  {
    id: 'saved',
    type: PointValue.Bonus,
    localeKey: 'checkbox5Text',
  },
];

const hintTotal = 3;
const defaultStackTokens = { childrenGap: 24 };

const CriticalInformation: React.FunctionComponent<CriticalInformationProps> = ({
  t,
  send = (e) => e,
  sidebar,
  state,
}) => {
  const [hintCount, setHintCount] = useState(0);
  const [lastState, setLastState] = useState('');
  const [checked, setChecked] = useState([] as string[]);
  const store = useStore();

  const components = {
    hintCount,
    hintTotal,
  };

  const { criticalInformation } = state;
  const currentState = lastState.length ? lastState : criticalInformation;
  const hintOpen = currentState === 'receiveHint';
  const forceSelectCorrect = currentState === 'forceSelectCorrect';

  function hasCriticalProperties() {
    const criticalProps = checkboxData.filter((c) => c.type === PointValue.Critical).map((c) => c.id);
    return criticalProps.every((p) => checked.indexOf(p) > -1);
  }

  function clickDoneButton() {
    if (lastState.length < 1) {
      const computedPoints = checked.reduce(
        (memo, checkedId) => memo + (checkboxData.find((element) => checkedId === element.id)?.type ?? 0),
        0,
      );
      store.dispatch(addScore({ type: 'group', amount: computedPoints, id: 'Game01.GA01.criticalInformation' }));
      if (hasCriticalProperties()) {
        setLastState(currentState);
        send({ type: 'SET_CHECKED', checked });
        send('CLICK.DONE');
      } else {
        send('CLICK.DONE.INCORRECT');
      }
    }
  }

  function clickHintButton() {
    send('CLICK.HINT');
  }

  function unlockHint() {
    if (hintCount < 3) {
      const newHintCount = hintCount + 1;
      // First one is free, second costs 1x, third costs 2x.
      const pointCostMultiplier = Math.max(0, newHintCount - 1);
      // Cost is multiplier + 2 critical answers on this question
      const pointCost = pointCostMultiplier * 2;
      if (pointCost > 0) {
        store.dispatch(
          addScore({ type: 'group', amount: -pointCost, id: `Game01.GA01.criticalInformationHint${newHintCount}` }),
        );
      }
      setHintCount(newHintCount);
    }
  }

  function updateChecked(id: string, isChecked: boolean | undefined) {
    if (isChecked === undefined) return;
    setChecked((arr: string[]) => (isChecked ? [...arr, id] : [...arr.filter((c) => c !== id)]));
  }

  const checkboxesToRender = forceSelectCorrect
    ? checkboxData.filter((c) => c.type === PointValue.Critical)
    : checkboxData;
  return (
    <div style={{ height: '100%', pointerEvents: lastState.length < 1 ? 'all' : 'none' }}>
      <ScreenModal
        sidebar={sidebar}
        title={t(forceSelectCorrect ? 'criticalInformation.incorrectHeader' : 'criticalInformation.header')}
      >
        <Stack tokens={defaultStackTokens} verticalAlign="space-between" verticalFill>
          <Stack horizontal tokens={defaultStackTokens}>
            <Stack.Item>
              <Icon {...getFileTypeIconProps({ extension: 'xlsx', size: 96 })} />
            </Stack.Item>
            <Stack tokens={{ childrenGap: 16 }}>
              <Text variant="large">
                {t(forceSelectCorrect ? 'criticalInformation.incorrectBody' : 'criticalInformation.body')}
              </Text>
              {!forceSelectCorrect && (
                <Text styles={{ root: { fontWeight: FontWeights.semibold } }} variant="large">
                  {t('criticalInformation.discuss')}
                </Text>
              )}
              <Stack tokens={{ childrenGap: 16 }}>
                {checkboxesToRender.map((c, i) => (
                  <Stack.Item key={`criticalInfoCheckboxItem_${i}`}>
                    <Checkbox
                      onChange={(e, isChecked) => updateChecked(c.id, isChecked)}
                      onRenderLabel={() => (
                        <Text styles={{ root: { fontWeight: FontWeights.regular } }}>
                          <RTrans t={t} i18nKey={`criticalInformation.${c.localeKey}`} />
                        </Text>
                      )}
                    />
                  </Stack.Item>
                ))}
              </Stack>
            </Stack>
          </Stack>
          <Stack horizontal horizontalAlign="end" tokens={defaultStackTokens}>
            {!forceSelectCorrect && (
              <PrimaryButton disabled={forceSelectCorrect} onClick={() => clickHintButton()}>
                <RTrans t={t} components={components} i18nKey="criticalInformation.hintButton" />
              </PrimaryButton>
            )}
            <DefaultButton
              onClick={() => clickDoneButton()}
              disabled={
                checked.length === 0 ||
                (checked.length === checkboxData.length && !forceSelectCorrect) ||
                (forceSelectCorrect && !hasCriticalProperties())
              }
            >
              <RTrans t={t} components={components} i18nKey="criticalInformation.doneButton" />
            </DefaultButton>
          </Stack>
        </Stack>
        <ResultModal
          isOpen={hintOpen}
          hidePersona
          styles={{
            main: {
              minWidth: 675,
              maxWidth: 800,
            },
          }}
          title={t('criticalInformation.hintModalTitle')}
          body={
            <Stack.Item styles={{ root: { marginBottom: 12 } }}>
              <Stack tokens={defaultStackTokens}>
                {[...Array(3)].map((n, i) => (
                  <Stack.Item key={`criticalInfoHintItem_${i}`}>
                    <Text variant="mediumPlus" block>
                      <RTrans
                        t={t}
                        components={{ ...components, i: i + 1 }}
                        i18nKey="criticalInformation.hintPreface"
                      />
                      <RTrans
                        t={t}
                        components={components}
                        i18nKey={`criticalInformation.${hintCount >= i + 1 ? `hint${i + 1}` : 'hintLocked'}`}
                      />
                    </Text>
                  </Stack.Item>
                ))}
              </Stack>
            </Stack.Item>
          }
          footer={
            <>
              <PrimaryButton disabled={hintCount === hintTotal} onClick={() => unlockHint()}>
                {t('criticalInformation.unlockHintBtn')}
              </PrimaryButton>
              <DefaultButton onClick={() => send('CLICK.CLOSE')}>
                {t('criticalInformation.hintCloseButton')}
              </DefaultButton>
            </>
          }
          onDismiss={() => send('CLICK.CLOSE')}
        />
      </ScreenModal>
    </div>
  );
};

export default CriticalInformation;
