import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setJournalEntriesSeen, setPolicyEntriesSeen } from '../../store/game-slice';

export interface EntryCheckerComponent {
  entry_id: string;
  active: boolean;
  category: string;
}

const EntrySeenChecker: React.FunctionComponent<EntryCheckerComponent> = ({ entry_id, active, category }) => {
  const dispatch = useDispatch();
  const activePanel = useSelector((state: RootState) => state.game.activePanel);
  useEffect(() => {
    if (active) {
      switch (category) {
        case 'policy':
          if (activePanel === 'policies') {
            dispatch(setPolicyEntriesSeen(entry_id));
          }
          break;
        default:
          if (activePanel === 'journal') {
            dispatch(setJournalEntriesSeen(entry_id));
          }
      }
    }
  }, [active, activePanel, dispatch, entry_id, category]);
  return null;
};
export default EntrySeenChecker;
