import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTaskMachine } from '../../../lib/hooks/useTaskMachine';
import { createGA01 } from './machine';
import { ScreenModalSidebarProps } from '../../../components/ScreenModal/ScreenModal';
import UrgentMessage from './components/01_UrgentMessage';
import SuspiciousFile from './components/03_SuspiciousFile';
import CriticalInformation from './components/04_CriticalInformation';
import JournalCallout from './components/05_JournalCallout';
import { RootState } from '../../../store';
import WhatHappened from './components/02_WhatHappened';
import SuspectIdentified from './components/06_SuspectIdentified';

const baseNS = 'Game01.GA01';
const taskKey = 'Game01.GA01';

const GA01: React.FunctionComponent = () => {
  const { t } = useTranslation([baseNS, 'common']);
  const history = useHistory();

  // TODO: creating a standalone route for group-activity for Nov 9th release
  // should remove this logic and any connected to it at a later date
  const isStandaloneRoute = history.location.pathname === '/group-activity';
  // Setup task machine
  const [state, send] = useTaskMachine(createGA01, {
    taskKey,
  });

  // Memoize IR manager persona
  const IRManager: ScreenModalSidebarProps = useMemo(
    () => ({
      title: t('IRManager.name'),
      persona: {
        imageInitials: t('IRManager.initials'),
      },
    }),
    [t],
  );

  const role = useSelector((s: RootState) => s.user.role);

  useEffect(() => {
    const isDone = state.matches('done');
    if (isDone && !isStandaloneRoute) {
      switch (role) {
        case 'identity':
          history.push('/tasks/Game01/IDSA01');
          break;
        case 'security':
          history.push('/tasks/Game01/SECSA02');
          break;
        default:
          // eslint-disable-next-line no-console
          console.warn('There is no role so I went to the home screen');
          history.push('/');
          break;
      }
    }
  }, [state, history, role, isStandaloneRoute]);

  return (
    <>
      {/**
       *  UrgentMessage
       */}
      {state.matches('urgentMessage') &&
        <UrgentMessage
          t={t}
          onClick={() => send('NEXT')}
          isStandaloneRoute={isStandaloneRoute}
          onStandaloneRoute={() => send('STATIC_ROUTE_SKIP')}
        />}
      {state.matches('whatHappened') && <WhatHappened t={t} onClick={() => send('NEXT')} />}
      {state.matches('suspiciousFile') && <SuspiciousFile t={t} send={send} state={state.value} sidebar={IRManager} />}
      {['criticalInformation', 'journalCallout'].some(state.matches) && (
        <CriticalInformation t={t} send={send} state={state.value} sidebar={IRManager} />
      )}
      {state.matches('journalCallout') && <JournalCallout t={t} send={send} />}
      {state.matches('suspectIdentified') && <SuspectIdentified t={t} onClick={() => send('NEXT')} isStandaloneRoute={isStandaloneRoute} />}
    </>
  );
};

export default GA01;
