import { createMachine } from 'xstate';

import { getDefaultOptions } from '../../task-options';
import type { TaskConfig, TaskContext, TaskOptions, TaskMachineProps, TaskEvent, TSXEvent } from '../../task-types';

export interface RegistrationContext extends TaskContext {}
type TContext = RegistrationContext;

export type RegistrationEvent = TaskEvent | TSXEvent;
type TEvent = RegistrationEvent;

export type RegistrationMachineProps = TaskMachineProps<TContext>;

/**
 * Machine config.
 */
export const RegistrationConfig: TaskConfig<TContext, TEvent> = {
  id: 'Registration',
  initial: 'title',
  states: {
    title: {
      on: {
        NEXT: {
          target: 'register',
        },
      },
    },
    register: {
      on: {
        NEXT: {
          target: 'done',
        },
      },
    },
    done: {
      type: 'final',
    },
  },
};

/**
 * Additional machine options.
 */
export const RegistrationOptions: TaskOptions<TContext, TEvent> = {};

/**
 * Machine constructor.
 */
export function createRegistration() {
  return createMachine(RegistrationConfig, getDefaultOptions()).withConfig(RegistrationOptions);
}
