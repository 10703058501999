import React from 'react';
import { DefaultButton, Text } from '@fluentui/react';
import RTrans from '../../../../components/RTrans/RTrans';
import ScreenModal, { ScreenModalSidebarProps } from '../../../../components/ScreenModal/ScreenModal';
import { IntroTaskProps } from '../interface';

interface IntroComponentProps extends IntroTaskProps {
  onClick: Function;
  sidebar: ScreenModalSidebarProps;
}

const Intro2: React.FunctionComponent<IntroComponentProps> = ({ onClick, t, sidebar }) => {
  return (

    <ScreenModal
      sidebar={sidebar}
      title={t('intro2.title')}
      body={
        <Text variant="large">
          <RTrans t={t} i18nKey={['intro2.content']} />
        </Text>
      }
      footer={
        <DefaultButton onClick={() => onClick()}>{t('buttons.next')}</DefaultButton>
      }
    />

  );
};

export default Intro2;
