import { createMachine } from 'xstate';
import { PointValue } from '../../task-common';

import { addScore, getDefaultOptions } from '../../task-options';
import type {
  TaskConfig,
  TaskContext,
  TaskOptions,
  TaskMachineProps,
  TaskEvent,
  TSXEvent,
  LabContext,
} from '../../task-types';

export interface SECSA03Context extends TaskContext {
  lab01: LabContext;
}
type TContext = SECSA03Context;

export type SECSA03Event = TaskEvent | TSXEvent;
type TEvent = SECSA03Event;

export type SECSA03MachineProps = TaskMachineProps<TContext>;

// Entry ID prefix
// const entPfx = 'Game01.SECSA03';

/**
 * Partially applied `addScore` action creator with
 * `{ type: 'security', id?: ``Game01.SECSA03.${id}`` }`
 */
const addScoreP = (amount: PointValue, id?: string) => {
  const computedId = id == null ? undefined : `Game01.SECSA03.${id}`;
  return addScore({ type: 'security', amount, id: computedId });
};

/**
 * Game01.SECSA03 machine config
 */
export const SECSA03Config: TaskConfig<TContext, TEvent> = {
  id: 'SECSA03',
  initial: 'intro01',
  context: {
    lab01: {
      sectionIndex: 0,
      screenIndex: 0,
    },
  },
  states: {
    intro01: {
      initial: 'intro01a',
      states: {
        intro01a: {
          on: {
            'CLICK.B01': { actions: [addScoreP(PointValue.Critical, 'intro01a.B01')], target: '#SECSA03.lab01' },
            'CLICK.B02': { actions: [addScoreP(PointValue.Incorrect, 'intro01a.B02')], target: 'intro01b' },
            'CLICK.B03': { actions: [addScoreP(PointValue.Incorrect, 'intro01a.B03')], target: 'intro01c' },
          },
        },
        intro01b: {
          on: {
            'CLICK.B04': { target: 'intro01a' },
          },
        },
        intro01c: {
          on: {
            'CLICK.B05': { target: 'intro01a' },
          },
        },
      },
    },
    lab01: {
      on: {
        'CLICK.B01': { target: 'end01' },
      },
    },
    end01: {
      on: {
        'CLICK.B01': { target: 'done' },
      },
    },
    done: {
      type: 'final',
    },
  },
};

/**
 * Additional machine options.
 */
export const SECSA03Options: TaskOptions<TContext, TEvent> = {};

/**
 * Machine constructor.
 */
export function createSECSA03() {
  return createMachine(SECSA03Config, getDefaultOptions()).withConfig(SECSA03Options);
}
