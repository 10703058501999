import React from 'react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { Text, Stack, FontWeights } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { CONTENT_TRANSITION_TIME } from '../../lib/anim-timings';
import { EntryContentProps } from '../../lib/entries';
import RTrans from '../RTrans/RTrans';

const EntryContent: React.FunctionComponent<EntryContentProps> = ({ date, description, content, entry_id }) => {
  const { t } = useTranslation('entries');
  return (
    <Stack>
      <Stack.Item>
        <Text variant="medium" block>
          {format(new Date(date), 'h:mm aaa MMMM dd, yyyy')}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant="mediumPlus" block styles={{ root: { fontWeight: FontWeights.bold } }}>
          {t(description)}
        </Text>
      </Stack.Item>
      {content.map((c, i) => (
        <Stack.Item key={`${entry_id}_content_${i}`}>
          <motion.div
            key={`${entry_id}_content_child`}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            transition={{ duration: CONTENT_TRANSITION_TIME / 1000 }}
          >
            <Text variant="medium" block>
              <RTrans t={t} i18nKey={c} />
            </Text>
          </motion.div>
        </Stack.Item>
      ))}
    </Stack>
  );
};
export default EntryContent;
