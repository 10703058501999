import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultEffects, Stack, Panel, PanelType } from '@fluentui/react';
import { RootState } from '../../store';
import { baseIconButtonStyles } from '../NavBar/NavBar';
import { setActivePanel } from '../../store/game-slice';

interface NavPanelInterface {
  activePanel: string;
  children: React.ReactNode;
  focusSelector?: string;
  hostId?: string;
  onOpen?: Function;
}

const NavPanel: React.FunctionComponent<NavPanelInterface> = ({
  children,
  activePanel,
  onOpen = () => { },
  focusSelector = '.first-focus',
  hostId = 'panel-content',
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.game.activePanel === activePanel);
  const onDismiss = React.useCallback(
    (ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent | undefined) => {
      if (ev == null || ev instanceof KeyboardEvent) {
        return;
      }
      const srcElement = ev.nativeEvent.srcElement as Element | null;
      const closeBtnKeyboard = srcElement && srcElement.className.indexOf('ms-Panel-closeButton') !== -1;
      const closeBtnMouse = srcElement && (srcElement.className.indexOf('ms-Button-icon') !== -1 || srcElement.className.indexOf('ms-Button-flexContainer') !== -1);
      const lightDismissed = srcElement && srcElement.className.indexOf('ms-Overlay') !== -1;
      if (closeBtnKeyboard || closeBtnMouse || lightDismissed) {
        dispatch(setActivePanel(activePanel));
      }
    },
    [dispatch, activePanel],
  );
  /**
   * this is not the React way, but I just need something that works rn for keyboard accessibility
   * if there is an element with the class 'first-focus' it gets focus on open
   * ALSO, since the panels don't have close buttons I disabled the trap focus feature of Panels
   * */
  const panelOnOpen = () => {
    document.querySelector<HTMLElement>(focusSelector)?.focus();
    onOpen();
  };
  return (
    <Panel
      isOpen={isOpen}
      isHiddenOnDismiss
      isLightDismiss
      onDismiss={onDismiss}
      type={PanelType.custom}
      customWidth="600px"
      onOpen={panelOnOpen}
      styles={{
        main: {
          boxShadow: DefaultEffects.elevation16,
        },
        contentInner: {
          paddingRight: baseIconButtonStyles.root.width,
        },
        scrollableContent: {
          minHeight: '100%',
        },
        commands: {
          paddingTop: 0,
        },
        content: {
          height: '100%',
          paddingBottom: 0,
        },
        navigation: {
          position: 'absolute',
          right: 0,
        },
      }}
      layerProps={{ hostId }}
    >
      <Stack grow verticalFill>
        {children}
      </Stack>
    </Panel>
  );
};
export default NavPanel;
