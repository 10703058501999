/* eslint-disable no-console */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PersonaPresence, IComboBoxOption, DefaultButton, Stack, Text, Label } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useForm } from 'react-hook-form';
import { ControlledDropdown, ControlledTextField } from '../../../../components/HookFormComponents/HookFormComponents';
import { getEnumValue, nameof } from '../../../../lib/util';
// this should eventually be game specific
import { roleData, UserRole } from '../../../../lib/roles';
import { setUserValues } from '../../../../store/user-slice';
import RTrans from '../../../../components/RTrans/RTrans';
import { RegisterTaskComponentProps } from '../interface';
import { setSessionStartTime } from '../../../../store/game-slice';
import { registerNewSession } from '../../../../lib/api/init';

interface RegisterProps extends RegisterTaskComponentProps {
  onSubmit: Function;
}

type Form = {
  username: string;
  role: string;
};

const Register: React.FunctionComponent<RegisterProps> = ({ onSubmit, t }) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const { handleSubmit, control } = useForm<Form>({
    defaultValues: {
      username: '',
    },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const roles: IComboBoxOption[] = roleData.game01.map((r) => ({
    key: r,
    text: t(`roles:${r}`),
  }));

  const onSave = () => {
    handleSubmit(
      async (data) => {
        setSubmitting(true);
        try {
          const { sessionUuid, sessionStartTime } = await registerNewSession();
          dispatch(setSessionStartTime(sessionStartTime));
          const { username, role } = data;
          dispatch(
            setUserValues({
              username,
              role: getEnumValue(UserRole, role, UserRole.None),
              presence: PersonaPresence.online,
              sessionUuid,
            }),
          );
          onSubmit();
        } catch (error: any) {
          setSubmitting(false);
          throw new Error(error);
        }
      },
      (err) => {
        console.log('RegistrationError:', err);
      },
    )();
  };

  const required = t('registration.form.required');
  const fieldStyles = {
    root: {
      width: 475,
    },
  };

  const dropdownId = useId('dropdownId');

  return (
    <Stack
      verticalFill
      verticalAlign="center"
      horizontalAlign="center"
      tokens={{
        childrenGap: 24,
        padding: 10,
      }}
    >
      <Text variant="mega" block>
        {t('registration.title')}
      </Text>
      <form onSubmit={handleSubmit(onSave)}>
        <Stack
          horizontalAlign="start"
          verticalAlign="start"
          tokens={{
            childrenGap: 16,
          }}
        >
          <Stack.Item>
            <ControlledTextField
              required
              label={t('registration.form.displayName')}
              control={control}
              name={nameof<Form>('username')}
              rules={{ required }}
              styles={fieldStyles}
            />
          </Stack.Item>
          <Stack.Item>
            <ControlledDropdown
              required
              dropdownWidth="auto"
              options={roles}
              control={control}
              name={nameof<Form>('role')}
              placeholder={t('registration.form.dropdownPlaceholder')}
              rules={{ required }}
              styles={{
                ...fieldStyles,
                dropdown: {
                  ':before': {
                    display: 'none',
                  },
                },
              }}
              id={dropdownId}
              onRenderLabel={() => (
                <Label required htmlFor={dropdownId}>
                  <RTrans t={t} i18nKey={['registration.form.role']} />
                </Label>
              )}
            />
          </Stack.Item>
          <Stack.Item>
            <DefaultButton type="submit" text={t('registration.form.done')} disabled={submitting} />
          </Stack.Item>
        </Stack>
      </form>
    </Stack>
  );
};

export default Register;
