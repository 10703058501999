/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react';
import NavPanel from '../NavPanel/NavPanel';
import PanelUnavailable from '../PanelUnavailable/PanelUnavailable';

const Chat: React.FunctionComponent = () => {
  const { t } = useTranslation('chat');
  return (
    <NavPanel activePanel="chat">
      <Text variant="xxLarge" block styles={{ root: { marginBottom: 24 } }}>
        {t('header')}
      </Text>
      <PanelUnavailable />
    </NavPanel>
  );
};
export default Chat;
