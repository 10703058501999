/**
 * Adapted from @xstate/react/lib/useInterpret
 * See: https://github.com/statelyai/xstate/blob/c2d6a24bcc7174af543e30aefd2a62c95a9b83ca/packages/xstate-react/src/useInterpret.ts (October 18, 2021)
 */
import { useMemo, useState } from 'react';
import type { MaybeLazy, Subscription, UseMachineOptions } from '@xstate/react/lib/types';
import useIsomorphicLayoutEffect from 'use-isomorphic-layout-effect';
import {
  interpret,
  EventObject,
  StateMachine,
  State,
  Interpreter,
  InterpreterOptions,
  MachineOptions,
  Typestate,
  Observer,
} from 'xstate';
import { useConstant } from './useConstant';
import { useReactEffectActions } from './useReactEffectActions';

// copied from core/src/utils.ts
// it avoids a breaking change between this package and XState which is its peer dep
function toObserver<T>(
  nextHandler: Observer<T> | ((value: T) => void),
  errorHandler?: (error: any) => void,
  completionHandler?: () => void,
): Observer<T> {
  if (typeof nextHandler === 'object') {
    return nextHandler;
  }

  const noop = () => undefined;

  return {
    next: nextHandler,
    error: errorHandler || noop,
    complete: completionHandler || noop,
  };
}

export function useInterpretReloadable<
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext },
>(
  getMachine: MaybeLazy<StateMachine<TContext, any, TEvent, TTypestate>>,
  options: Partial<InterpreterOptions> &
    Partial<UseMachineOptions<TContext, TEvent>> &
    Partial<MachineOptions<TContext, TEvent>> = {},
  observerOrListener?:
    | Observer<State<TContext, TEvent, any, TTypestate>>
    | ((value: State<TContext, TEvent, any, TTypestate>) => void),
): Interpreter<TContext, any, TEvent, TTypestate> {
  const machine = useConstant(() => {
    return typeof getMachine === 'function' ? getMachine() : getMachine;
  });

  if (process.env.NODE_ENV !== 'production' && typeof getMachine !== 'function') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [initialMachine] = useState(machine);

    if (getMachine !== initialMachine) {
      // eslint-disable-next-line no-console
      console.warn(
        'Machine given to `useMachine` has changed between renders. This is not supported and might lead to unexpected results.\n' +
          'Please make sure that you pass the same Machine as argument each time.',
      );
    }
  }

  const {
    context,
    guards,
    actions,
    activities,
    services,
    delays,
    state: rehydratedState,
    ...interpreterOptions
  } = options;

  const service = useConstant(() => {
    const machineConfig = {
      context,
      guards,
      actions,
      activities,
      services,
      delays,
    };
    const machineWithConfig = machine.withConfig(machineConfig, () => ({
      ...machine.context,
      ...context,
    }));

    return interpret(machineWithConfig, {
      deferEvents: true,
      ...interpreterOptions,
    });
  });

  // [TB ADDED]: Track first load
  // const [isFirstLoad, setIsFirstLoad] = useState(true);

  // [TB ADDED]: Memoize initialState to load, recalculate when it changes.
  const initialState = useMemo(() => {
    return rehydratedState ? (State.create(rehydratedState) as any) : undefined;
  }, [rehydratedState]);

  // [TB CHANGED]: Add dependencies on service and initialState
  useIsomorphicLayoutEffect(() => {
    // console.log(isFirstLoad ? 'sub' : 'resub');
    let sub: Subscription;
    if (observerOrListener) {
      sub = service.subscribe(toObserver(observerOrListener));
    }

    return () => {
      // console.log('unsub');
      sub?.unsubscribe();
    };
  }, [observerOrListener, service, initialState]);

  // [TB CHANGED]: Add dependency on initialState
  useIsomorphicLayoutEffect(() => {
    // console.log(isFirstLoad ? 'start' : 'restart');
    service.start(initialState);
    // setIsFirstLoad(false);

    return () => {
      // console.log('stop');
      service.stop();
    };
  }, [initialState]);

  // Make sure options are kept updated when they change.
  // This mutation assignment is safe because the service instance is only used
  // in one place -- this hook's caller.
  useIsomorphicLayoutEffect(() => {
    Object.assign(service.machine.options.actions, actions);
    Object.assign(service.machine.options.guards, guards);
    Object.assign(service.machine.options.activities, activities);
    Object.assign(service.machine.options.services, services);
    Object.assign(service.machine.options.delays, delays);
  }, [actions, guards, activities, services, delays]);

  useReactEffectActions(service);

  return service;
}
