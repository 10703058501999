import React from 'react';
import { Text, FontWeights, DefaultButton, Stack, getTheme } from '@fluentui/react';
import RTrans from '../../../../components/RTrans/RTrans';
import ScreenModal from '../../../../components/ScreenModal/ScreenModal';
import { GA01ComponentProps } from '../interface';

interface UrgentMessageProps extends GA01ComponentProps {
  isStandaloneRoute: boolean;
  onStandaloneRoute: Function;
}

const UrgentMessage: React.FunctionComponent<UrgentMessageProps> = (
  { t, onClick = () => { }, isStandaloneRoute, onStandaloneRoute },
) => {
  if (isStandaloneRoute) {
    onStandaloneRoute();
    return null;
  }
  const theme = getTheme();
  return (
    <ScreenModal
      title={
        <Text variant="xxLarge" styles={{ root: { fontWeight: FontWeights.bold } }}>
          {t('urgentMessage.header')}
        </Text>
      }
      body={
        <Stack tokens={{ childrenGap: 72 }}>
          <Stack.Item>
            <Text variant="large">
              <RTrans t={t} i18nKey="urgentMessage.body" />
            </Text>
          </Stack.Item>
          <Stack.Item styles={{ root: { margin: 'auto' } }}>
            <Stack
              styles={{
                root: {
                  backgroundColor: theme.palette.neutralLighterAlt,
                  maxWidth: 450,
                  padding: 40,
                  borderRadius: theme.effects.roundedCorner2,
                  boxShadow: theme.effects.elevation16,
                },
              }}
              tokens={{ childrenGap: 16 }}
            >
              <Stack.Item>
                <Text variant="large" block styles={{ root: { fontWeight: FontWeights.bold, textAlign: 'center' } }}>
                  <RTrans t={t} i18nKey="urgentMessage.demandTitle" />
                </Text>
              </Stack.Item>
              <Text variant="medium" block>
                <RTrans t={t} i18nKey="urgentMessage.demandBody" />
              </Text>
              <Stack.Item />
            </Stack>
          </Stack.Item>
        </Stack>

      }
      footer={<DefaultButton onClick={() => onClick()}>{t('buttons.next')}</DefaultButton>}
    />
  );
};

export default UrgentMessage;
