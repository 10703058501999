import { IR01BonusFlags, IR01Result } from '../interface';
import {
  IR01Alert2Options,
  IR01AuthorOptions,
  IR01IncidentReasonOptions,
  IR01DatabaseTypeOptions,
  IR01DataLossOptions,
  IR01FileNameOptions,
  IR01HelpDeskAgentOptions,
  IR01IdentityActionOptions,
  IR01IdentityTypeOptions,
  IR01IdentityRemediationOptions,
  IR01IdentityRiskPolicyActionOptions,
  IR01IdentityRiskPolicyGroupOptions,
  IR01IdentityRiskPolicyLevelOptions,
  IR01ImpactOptions,
  IR01LastActivityOptions,
  IR01RiskyLocationOptions,
  IR01ReportingUserOptions,
  IR01SecurityActionOptions,
  IR01SecurityByOptions,
  IR01SecurityUsingOptions,
  IR01IdentityInvestigateUsingOptions,
  IR01VMStateOptions,
  IR01IdentityProtectUsingOptions,
} from './options';

export const IR01ImpactEventOptions = [
  {
    key: 'incidentImpact',
    namespace: 'ir.pageHead.incidentImpact',
    options: IR01ImpactOptions,
  },
];

export const IR01DataLossEventOptions = [
  {
    key: 'dataLoss',
    namespace: 'ir.pageHead.dataLoss',
    options: IR01DataLossOptions,
  },
];

export const IR01LeakedFileEventOptions = [
  {
    key: 'fileName',
    namespace: 'ir.reported.leakedFile.fileNameOptions',
    options: IR01FileNameOptions,
  },
  {
    key: 'fileAuthor',
    namespace: 'ir.reported.leakedFile.fileAuthorOptions',
    options: IR01AuthorOptions,
  },
];

export const IR01IdentityInvestigate1EventOptions = [
  {
    key: 'identityInvestigateUsing',
    namespace: 'ir.reported.identityInvestigate1.identityInvestigateUsingOptions',
    options: IR01IdentityInvestigateUsingOptions,
  },
  {
    key: 'identityType',
    namespace: 'ir.reported.identityInvestigate1.identityTypeOptions',
    options: IR01IdentityTypeOptions,
  },
  {
    key: 'riskyLocation',
    namespace: 'ir.reported.identityInvestigate1.riskyLocationOptions',
    options: IR01RiskyLocationOptions,
  },
  {
    key: 'incidentReason',
    namespace: 'ir.reported.identityInvestigate1.incidentReasonOptions',
    options: IR01IncidentReasonOptions,
  },
];

export const IR01IdentityBonus1EventOptions = [
  {
    key: 'identityAction',
    namespace: 'ir.reported.identityBonus1.identityActionOptions',
    options: IR01IdentityActionOptions,
  },
];

export const IR01IdentityRemediate1EventOptions = [
  {
    key: 'identityRemediation',
    namespace: 'ir.reported.identityRemediate1.identityRemediationOptions',
    options: IR01IdentityRemediationOptions,
  },
];

export const IR01IdentityRemediate2EventOptions = [
  {
    key: 'riskPolicyGroup',
    namespace: 'ir.reported.identityRemediate2.riskPolicyGroupOptions',
    options: IR01IdentityRiskPolicyGroupOptions,
  },
  {
    key: 'riskPolicyLevel',
    namespace: 'ir.reported.identityRemediate2.riskPolicyLevelOptions',
    options: IR01IdentityRiskPolicyLevelOptions,
  },
  {
    key: 'riskPolicyAction',
    namespace: 'ir.reported.identityRemediate2.riskPolicyActionOptions',
    options: IR01IdentityRiskPolicyActionOptions,
  },
];

export const IR01Alert2EventOptions = [
  {
    key: 'alert2',
    namespace: 'ir.reported.alert2.alert2Options',
    options: IR01Alert2Options,
  },
];

export const IR01SecurityInvestigate1EventOptions = [
  {
    key: 'lastActivity',
    namespace: 'ir.reported.securityInvestigate1.lastActivityOptions',
    options: IR01LastActivityOptions,
  },
];

export const IR01SecurityBonus1aEventOptions = [
  {
    key: 'reportingUser',
    namespace: 'ir.reported.securityBonus1a.reportingUserOptions',
    options: IR01ReportingUserOptions,
  },
];

export const IR01SecurityBonus1bEventOptions = [
  {
    key: 'helpDeskAgent',
    namespace: 'ir.reported.securityBonus1b.helpDeskAgentOptions',
    options: IR01HelpDeskAgentOptions,
  },
];

export const IR01SecurityBonus1cEventOptions = [
  {
    key: 'reportingUser',
    namespace: 'ir.reported.securityBonus1c.reportingUserOptions',
    options: IR01ReportingUserOptions,
  },
  {
    key: 'helpDeskAgent',
    namespace: 'ir.reported.securityBonus1c.helpDeskAgentOptions',
    options: IR01HelpDeskAgentOptions,
  },
];

export const IR01SecurityInvestigate2EventOptions = [
  {
    key: 'databaseType',
    namespace: 'ir.reported.securityInvestigate2.databaseTypeOptions',
    options: IR01DatabaseTypeOptions,
  },
  {
    key: 'securityUsing',
    namespace: 'ir.reported.securityInvestigate2.securityUsingOptions',
    options: IR01SecurityUsingOptions,
  },
  {
    key: 'vmState',
    namespace: 'ir.reported.securityInvestigate2.vmStateOptions',
    options: IR01VMStateOptions,
  },
  {
    key: 'securityBy',
    namespace: 'ir.reported.securityInvestigate2.securityByOptions',
    options: IR01SecurityByOptions,
  },
  {
    key: 'securityAction',
    namespace: 'ir.reported.securityInvestigate2.securityActionOptions',
    options: IR01SecurityActionOptions,
  },
];

export const IR01IdentityProtect1EventOptions = [
  {
    key: 'identityProtectUsing',
    namespace: 'ir.reported.identityProtect1.identityProtectUsingOptions',
    options: IR01IdentityProtectUsingOptions,
  },
];

const allIREvents = [
  ...IR01ImpactEventOptions,
  ...IR01DataLossEventOptions,
  ...IR01LeakedFileEventOptions,
  ...IR01IdentityInvestigate1EventOptions,
  ...IR01IdentityBonus1EventOptions,
  ...IR01IdentityRemediate1EventOptions,
  ...IR01IdentityRemediate2EventOptions,
  ...IR01Alert2EventOptions,
  ...IR01SecurityInvestigate1EventOptions,
  ...IR01SecurityBonus1aEventOptions,
  ...IR01SecurityBonus1bEventOptions,
  ...IR01SecurityBonus1cEventOptions,
  ...IR01SecurityInvestigate2EventOptions,
  ...IR01IdentityProtect1EventOptions,
];

export function getIRResults(irValues: Record<string, string>) {
  const data: IR01Result = {
    responses: {},
    total: 0,
  };
  Object.entries(irValues).forEach(([responseKey, responseValue]) => {
    // Find the possible answers
    const eOption = allIREvents.find((eo) => eo.key === responseKey);
    if (!eOption) {
      // eslint-disable-next-line no-console
      console.error(`getIRResults: no eOption with key ${responseKey}`);
      return;
    }
    // Find the given answer
    const vOption = eOption.options.find((o) => o.key === responseValue);
    if (!vOption) {
      // eslint-disable-next-line no-console
      console.error(`getIRResults: no vOption with key ${responseValue}`);
      return;
    }
    data.responses[responseKey] = vOption;
  });

  // Sum total values of all given answers.
  data.total = Object.values(data.responses).reduce((memo, { value }) => memo + value, 0);

  return data;
}

export function generateIRParts(bonuses: IR01BonusFlags) {
  return {
    IR01EventsPart1: [
      {
        i18nKey: 'ir.reported.leakedFile.text',
        options: IR01LeakedFileEventOptions,
      },
      {
        i18nKey: 'ir.reported.identityInvestigate1.text',
        options: IR01IdentityInvestigate1EventOptions,
      },
      ...(bonuses.id1
        ? [
          {
            i18nKey: 'ir.reported.identityBonus1.text',
            options: IR01IdentityBonus1EventOptions,
          },
        ]
        : []),
      {
        i18nKey: 'ir.reported.identityRemediate1.text',
        options: IR01IdentityRemediate1EventOptions,
      },
      {
        i18nKey: 'ir.reported.identityRemediate2.text',
        options: IR01IdentityRemediate2EventOptions,
      },
    ],
    IR01EventsPart2: [
      {
        i18nKey: 'ir.reported.securityInvestigate1.text',
        options: IR01SecurityInvestigate1EventOptions,
      },
      ...(bonuses.sec1a
        ? [
          {
            i18nKey: 'ir.reported.securityBonus1a.text',
            options: IR01SecurityBonus1aEventOptions,
          },
        ]
        : []),
      ...(bonuses.sec1b
        ? [
          {
            i18nKey: 'ir.reported.securityBonus1b.text',
            options: IR01SecurityBonus1bEventOptions,
          },
        ]
        : []),
      ...(bonuses.sec1c
        ? [
          {
            i18nKey: 'ir.reported.securityBonus1c.text',
            options: IR01SecurityBonus1cEventOptions,
          },
        ]
        : []),
      {
        i18nKey: 'ir.reported.securityInvestigate2.text',
        options: IR01SecurityInvestigate2EventOptions,
      },
      {
        i18nKey: 'ir.reported.identityProtect1.text',
        options: IR01IdentityProtect1EventOptions,
      },
    ],
  };
}
