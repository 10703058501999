import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector as useXSelector } from '@xstate/react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Checkbox, DefaultButton, Dropdown, Icon, IDropdownOption, PrimaryButton, Stack, Text } from '@fluentui/react';
import _times from 'lodash/times';
import _isEqual from 'lodash/isEqual';

import { useTaskMachine } from '../../../lib/hooks/useTaskMachine';
import { createSECSA02 } from './machine';
import * as styles from './styles';
import LabFrame, { LabFrameOnMessage } from '../../../components/LabFrame/LabFrame';
import ResultModal from '../../../components/ResultModal/ResultModal';
import ScreenModal, { ScreenModalSidebarProps } from '../../../components/ScreenModal/ScreenModal';
import { ExtractTEvent } from '../../task-types';
import RTrans from '../../../components/RTrans/RTrans';
import { isDev } from '../../../lib/util';
import LabFrameHints from '../../../components/LabFrameHints/LabFrameHints';

const baseNS = 'Game01.SECSA02';
const taskKey = 'Game01.SECSA02';

const labURI = 'https://octe.azurewebsites.net/Microsoft/viewer/750/index.html#/0/0';

const SECSA02: React.FC = () => {
  const { t } = useTranslation(baseNS);
  const history = useHistory();

  // Setup task machine
  const [state, send, service] = useTaskMachine(createSECSA02, {
    taskKey,
  });

  // Move along to the next task if state is done.
  useEffect(() => {
    if (state.done) {
      history.push('/tasks/Game01/SECSA03');
    }
  }, [state, history]);

  // Memoize IR manager persona
  const IRManager: ScreenModalSidebarProps = useMemo(
    () => ({
      title: t('IRManager.name'),
      persona: {
        imageInitials: t('IRManager.initials'),
      },
    }),
    [t],
  );

  // const cbSendNext = useCallback(() => send('NEXT'), [send]);
  const cbSendClickB01 = useCallback(() => send('CLICK.B01'), [send]);
  const cbSendClickB02 = useCallback(() => send('CLICK.B02'), [send]);
  const cbSendClickB03 = useCallback(() => send('CLICK.B03'), [send]);
  const cbSendClickB04 = useCallback(() => send('CLICK.B04'), [send]);
  const cbSendClickB05 = useCallback(() => send('CLICK.B05'), [send]);
  const cbSendClickHT01 = useCallback(() => send('CLICK.HT01'), [send]);
  const cbSendClickHT02 = useCallback(() => send('CLICK.HT02'), [send]);
  const cbSendClickD01 = useCallback(() => send('CLICK.D01'), [send]);

  const labMessageHandler = useCallback<LabFrameOnMessage>(
    (message) => {
      const { data } = message;
      if (isDev) {
        // eslint-disable-next-line no-console
        console.log('labMessageHandler', data);
      }
      const xEvent: ExtractTEvent<typeof send> = {
        type: String(data.action).toUpperCase(),
        ...data,
      };
      send(xEvent);
    },
    [send],
  );

  const updateDelete01Choices = useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
      if (!(ev?.target instanceof HTMLInputElement)) {
        return;
      }
      send('SET.DELETE01.CHOICES', { name: ev.target.name, checked: !!checked });
    },
    [send],
  );

  const updateReflect01Choices = useCallback(
    (ev?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option == null) {
        return;
      }
      send('SET.REFLECT01.CHOICES', { id: option.id, key: option.key });
    },
    [send],
  );

  // Disable delete01a submit button if every or no checkbox is checked.
  const isDisabledDelete01a = useXSelector(
    service,
    ({ context: { delete01Choices } }) =>
      Object.values(delete01Choices).every((val) => val === true) ||
      Object.values(delete01Choices).every((val) => val === false),
  );

  // Disable delete01 unlock hint button if all hints are unlocked.
  const isDisabledDelete01Hint = useXSelector(
    service,
    ({ context: { delete01HintTotal, delete01HintsUnlocked } }) => delete01HintsUnlocked >= delete01HintTotal,
  );

  // Disable reflect01 submit button if any dropdowns are blank.
  const isDisabledReflect01a = useXSelector(service, ({ context: { reflect01Choices } }) =>
    Object.values(reflect01Choices).some((val) => val == null),
  );

  // Memoize rendered hints for delete01 based on total/unlock
  const delete01Hints = useMemo(() => {
    return (
      <>
        <Stack.Item styles={{ root: { marginBottom: 12 } }}>
          {_times(state.context.delete01HintTotal, (i) => {
            const values = {
              index: i + 1,
              hintTotal: state.context.delete01HintTotal,
              hintsUnlocked: state.context.delete01HintsUnlocked,
            };
            return (
              <Stack.Item key={`delete01HT-item-${values.index}`}>
                <Text variant="mediumPlus" block>
                  <RTrans t={t} i18nKey="delete01HT.hintPreface" values={values} />
                  <RTrans
                    t={t}
                    i18nKey={`delete01HT.${
                      values.hintsUnlocked >= values.index ? `hint${values.index}` : 'hintLocked'
                    }`}
                  />
                </Text>
              </Stack.Item>
            );
          })}
        </Stack.Item>
      </>
    );
  }, [t, state.context.delete01HintTotal, state.context.delete01HintsUnlocked]);

  // Calculate reflect01b answer correctness
  const reflect01bCheckedAnswers = useXSelector(
    service,
    ({ context: { reflect01Choices } }) => ({
      DD01: reflect01Choices.DD01 === 'DD01a',
      DD02: reflect01Choices.DD02 === 'DD02b',
      DD03: reflect01Choices.DD03 === 'DD03c',
      DD04: reflect01Choices.DD04 === 'DD04b',
    }),
    _isEqual,
  );

  // Disable reflect01b button if any answers are still incorrect
  const isDisabledReflect01b = useMemo(
    () => Object.values(reflect01bCheckedAnswers).some((val) => val === false),
    [reflect01bCheckedAnswers],
  );

  return (
    <>
      {/**
       * intro01
       */}
      {(state.matches('intro01') || state.matches('modal01')) && (
        <ScreenModal
          sidebar={IRManager}
          title={t('intro01.title')}
          body={
            <Text>
              <RTrans t={t} i18nKey="intro01.body" />
            </Text>
          }
          footer={<DefaultButton onClick={cbSendClickB01}>{t('intro01.B01')}</DefaultButton>}
        />
      )}

      {/**
       * modal01
       */}
      <ResultModal
        isOpen={state.matches('modal01')}
        title={t('modal01.title')}
        body={<Text>{t('modal01.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB01}>{t('modal01.B01')}</DefaultButton>}
        onDismiss={cbSendClickB01}
      />

      {/**
       * delete01.delete01a / delete01.delete01c1
       */}
      {(state.matches('delete01.delete01a') ||
        state.matches('delete01.delete01HT') ||
        state.matches('delete01.delete01c1')) && (
        <ScreenModal
          sidebar={IRManager}
          title={t('delete01a.title')}
          footer={
            <>
              <DefaultButton onClick={cbSendClickHT01}>
                <RTrans t={t} i18nKey="delete01a.HT01" values={state.context} />
              </DefaultButton>
              <DefaultButton disabled={isDisabledDelete01a} onClick={cbSendClickB01}>
                {t('delete01a.B01')}
              </DefaultButton>
            </>
          }
        >
          <Stack horizontal tokens={styles.screenModalIconStackHTokens}>
            <Stack.Item>
              <Text variant="mega">
                <Icon aria-label="FileBug" iconName="FileBug" />
              </Text>
            </Stack.Item>
            <Stack tokens={styles.screenModalIconStackVTokens}>
              <Stack.Item>
                <Text>
                  <RTrans t={t} i18nKey="delete01a.body" />
                </Text>
              </Stack.Item>
              <Stack.Item>
                <Text variant="large">
                  <strong>{t('delete01a.subheading')}</strong>
                </Text>
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label={t('delete01a.CH01')}
                  name="CH01"
                  checked={state.context.delete01Choices.CH01}
                  onChange={updateDelete01Choices}
                />
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label={t('delete01a.CH02')}
                  name="CH02"
                  checked={state.context.delete01Choices.CH02}
                  onChange={updateDelete01Choices}
                />
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label={t('delete01a.CH03')}
                  name="CH03"
                  checked={state.context.delete01Choices.CH03}
                  onChange={updateDelete01Choices}
                />
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label={t('delete01a.CH04')}
                  name="CH04"
                  checked={state.context.delete01Choices.CH04}
                  onChange={updateDelete01Choices}
                />
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label={t('delete01a.CH05')}
                  name="CH05"
                  checked={state.context.delete01Choices.CH05}
                  onChange={updateDelete01Choices}
                />
              </Stack.Item>
            </Stack>
          </Stack>
        </ScreenModal>
      )}
      <ResultModal
        isOpen={state.matches('delete01.delete01HT')}
        hidePersona
        title={t('delete01HT.title')}
        body={delete01Hints}
        footer={
          <>
            <PrimaryButton disabled={isDisabledDelete01Hint} onClick={cbSendClickHT02}>
              {t('delete01HT.HT02')}
            </PrimaryButton>
            <DefaultButton onClick={cbSendClickD01}>{t('delete01HT.D01')}</DefaultButton>
          </>
        }
        onDismiss={cbSendClickD01}
      />

      {/**
       * delete01.delete01b / delete01.delete01c2
       */}
      {(state.matches('delete01.delete01b') || state.matches('delete01.delete01c2')) && (
        <ScreenModal
          sidebar={IRManager}
          title={t('delete01b.title')}
          footer={
            <>
              <DefaultButton onClick={cbSendClickB02}>{t('delete01b.B02')}</DefaultButton>
            </>
          }
        >
          <Stack horizontal tokens={styles.screenModalIconStackHTokens}>
            <Stack.Item>
              <Text variant="mega">
                <Icon aria-label="FileBug" iconName="FileBug" />
              </Text>
            </Stack.Item>
            <Stack tokens={styles.screenModalIconStackVTokens}>
              <Stack.Item>
                <Text>
                  <RTrans t={t} i18nKey="delete01b.body" />
                </Text>
              </Stack.Item>
              <Stack.Item>
                <Text variant="large">
                  <strong>{t('delete01a.subheading')}</strong>
                </Text>
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label={t('delete01a.CH03')}
                  name="CH03"
                  checked={state.context.delete01Choices.CH03}
                  onChange={updateDelete01Choices}
                />
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label={t('delete01a.CH04')}
                  name="CH04"
                  checked={state.context.delete01Choices.CH04}
                  onChange={updateDelete01Choices}
                />
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label={t('delete01a.CH05')}
                  name="CH05"
                  checked={state.context.delete01Choices.CH05}
                  onChange={updateDelete01Choices}
                />
              </Stack.Item>
            </Stack>
          </Stack>
        </ScreenModal>
      )}

      {/**
       * delete01.delete01c1 / delete01.delete01c2
       */}
      <ResultModal
        isOpen={state.matches('delete01.delete01c1') || state.matches('delete01.delete01c2')}
        title={t('delete01c.title')}
        body={<Text>{t('delete01c.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB03}>{t('delete01c.B03')}</DefaultButton>}
        onDismiss={cbSendClickB03}
      />

      {/**
       * delete02 / delete02.delete02a
       */}
      {state.matches('delete02') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('delete02a.title')}
          body={<Text>{t('delete02a.body')}</Text>}
          footer={
            <>
              <DefaultButton onClick={cbSendClickB01}>{t('delete02a.B01')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB02}>{t('delete02a.B02')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB03}>{t('delete02a.B03')}</DefaultButton>
            </>
          }
        />
      )}

      {/**
       * delete02.delete02b
       */}
      <ResultModal
        isOpen={state.matches('delete02.delete02b')}
        title={t('delete02b.title')}
        body={<Text>{t('delete02b.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB04}>{t('delete02b.B04')}</DefaultButton>}
        onDismiss={cbSendClickB04}
      />

      {/**
       * delete02.delete02c
       */}
      <ResultModal
        isOpen={state.matches('delete02.delete02c')}
        title={t('delete02c.title')}
        body={<Text>{t('delete02c.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB05}>{t('delete02c.B05')}</DefaultButton>}
        onDismiss={cbSendClickB05}
      />

      {/**
       * lab01
       */}
      {state.matches('lab01') && (
        <>
          <LabFrame
            src={labURI}
            title="Lab01"
            sectionIndex={state.context.lab01.sectionIndex}
            screenIndex={state.context.lab01.screenIndex}
            onMessage={labMessageHandler}
          />
          <LabFrameHints screenId={state.context.lab01.screenId || 'start'} namespace="labHints" t={t} />
        </>
      )}

      {/**
       * delete03 / delete02.delete03a
       */}
      {state.matches('delete03') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('delete03a.title')}
          body={
            <>
              <Text>
                <Trans t={t} i18nKey="delete03a.body" />
              </Text>
            </>
          }
          footer={
            <>
              <DefaultButton onClick={cbSendClickB01}>{t('delete03a.B01')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB02}>{t('delete03a.B02')}</DefaultButton>
            </>
          }
        />
      )}

      {/**
       * delete03.delete03b
       */}
      <ResultModal
        isOpen={state.matches('delete03.delete03b')}
        title={t('delete03b.title')}
        body={
          <Text>
            <RTrans t={t} i18nKey="delete03b.body" />
          </Text>
        }
        footer={<DefaultButton onClick={cbSendClickB03}>{t('delete03b.B03')}</DefaultButton>}
        onDismiss={cbSendClickB03}
      />

      {/**
       * reflect01.reflect01a
       */}
      {state.matches('reflect01.reflect01a') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('reflect01a.title')}
          body={
            <>
              <Text>
                <RTrans t={t} i18nKey="reflect01a.body" />
              </Text>
              <Stack styles={styles.queryStackOuterStyles}>
                <Dropdown
                  styles={styles.queryHeaderStyles}
                  options={[
                    { id: 'DD01', key: 'DD01a', text: t('reflect01a.DD01a') },
                    { id: 'DD01', key: 'DD01b', text: t('reflect01a.DD01b') },
                    { id: 'DD01', key: 'DD01c', text: t('reflect01a.DD01c') },
                    { id: 'DD01', key: 'DD01d', text: t('reflect01a.DD01d') },
                  ]}
                  selectedKey={state.context.reflect01Choices.DD01}
                  onChange={updateReflect01Choices}
                />
                <Stack tokens={styles.queryStackInnerTokens}>
                  <Stack.Item>
                    <Text className={styles.queryTextStyle}>
                      <RTrans t={t} i18nKey="reflect01a.where01" />
                    </Text>
                    <Dropdown
                      styles={styles.queryOptionStyles}
                      options={[
                        { id: 'DD02', key: 'DD02a', text: t('reflect01a.DD02a') },
                        { id: 'DD02', key: 'DD02b', text: t('reflect01a.DD02b') },
                        { id: 'DD02', key: 'DD02c', text: t('reflect01a.DD02c') },
                        { id: 'DD02', key: 'DD02d', text: t('reflect01a.DD02d') },
                      ]}
                      selectedKey={state.context.reflect01Choices.DD02}
                      onChange={updateReflect01Choices}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Text className={styles.queryTextStyle}>
                      <RTrans t={t} i18nKey="reflect01a.where02" />
                    </Text>
                    <Dropdown
                      styles={styles.queryOptionStyles}
                      options={[
                        { id: 'DD03', key: 'DD03a', text: t('reflect01a.DD03a') },
                        { id: 'DD03', key: 'DD03b', text: t('reflect01a.DD03b') },
                        { id: 'DD03', key: 'DD03c', text: t('reflect01a.DD03c') },
                        { id: 'DD03', key: 'DD03d', text: t('reflect01a.DD03d') },
                      ]}
                      selectedKey={state.context.reflect01Choices.DD03}
                      onChange={updateReflect01Choices}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Text className={styles.queryTextStyle}>
                      <RTrans t={t} i18nKey="reflect01a.where03" />
                    </Text>
                    <Dropdown
                      styles={styles.queryOptionStyles}
                      options={[
                        { id: 'DD04', key: 'DD04a', text: t('reflect01a.DD04a') },
                        { id: 'DD04', key: 'DD04b', text: t('reflect01a.DD04b') },
                        { id: 'DD04', key: 'DD04c', text: t('reflect01a.DD04c') },
                        { id: 'DD04', key: 'DD04d', text: t('reflect01a.DD04d') },
                      ]}
                      selectedKey={state.context.reflect01Choices.DD04}
                      onChange={updateReflect01Choices}
                    />
                  </Stack.Item>
                  <Stack.Item align="start">
                    <DefaultButton disabled={isDisabledReflect01a} onClick={cbSendClickB01}>
                      {t('reflect01a.B01')}
                    </DefaultButton>
                  </Stack.Item>
                </Stack>
              </Stack>
            </>
          }
        />
      )}

      {/**
       * reflect01.reflect01b
       */}
      {state.matches('reflect01.reflect01b') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('reflect01b.title')}
          body={
            <>
              <Text>
                <RTrans t={t} i18nKey="reflect01b.body" />
              </Text>
              <Stack styles={styles.queryStackOuterStyles}>
                <Dropdown
                  styles={styles.queryHeaderStyles}
                  options={[
                    { id: 'DD01', key: 'DD01a', text: t('reflect01a.DD01a') },
                    { id: 'DD01', key: 'DD01b', text: t('reflect01a.DD01b') },
                    { id: 'DD01', key: 'DD01c', text: t('reflect01a.DD01c') },
                    { id: 'DD01', key: 'DD01d', text: t('reflect01a.DD01d') },
                  ]}
                  selectedKey={state.context.reflect01Choices.DD01}
                  onChange={updateReflect01Choices}
                  errorMessage={reflect01bCheckedAnswers.DD01 ? undefined : t('reflect01b.DD01error')}
                />
                <Stack tokens={styles.queryStackInnerTokens}>
                  <Stack.Item>
                    <Text className={styles.queryTextStyle}>
                      <RTrans t={t} i18nKey="reflect01a.where01" />
                    </Text>
                    <Dropdown
                      styles={styles.queryOptionStyles}
                      options={[
                        { id: 'DD02', key: 'DD02a', text: t('reflect01a.DD02a') },
                        { id: 'DD02', key: 'DD02b', text: t('reflect01a.DD02b') },
                        { id: 'DD02', key: 'DD02c', text: t('reflect01a.DD02c') },
                        { id: 'DD02', key: 'DD02d', text: t('reflect01a.DD02d') },
                      ]}
                      selectedKey={state.context.reflect01Choices.DD02}
                      onChange={updateReflect01Choices}
                      errorMessage={reflect01bCheckedAnswers.DD02 ? undefined : t('reflect01b.DD02error')}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Text className={styles.queryTextStyle}>
                      <RTrans t={t} i18nKey="reflect01a.where02" />
                    </Text>
                    <Dropdown
                      styles={styles.queryOptionStyles}
                      options={[
                        { id: 'DD03', key: 'DD03a', text: t('reflect01a.DD03a') },
                        { id: 'DD03', key: 'DD03b', text: t('reflect01a.DD03b') },
                        { id: 'DD03', key: 'DD03c', text: t('reflect01a.DD03c') },
                        { id: 'DD03', key: 'DD03d', text: t('reflect01a.DD03d') },
                      ]}
                      selectedKey={state.context.reflect01Choices.DD03}
                      onChange={updateReflect01Choices}
                      errorMessage={reflect01bCheckedAnswers.DD03 ? undefined : t('reflect01b.DD03error')}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Text className={styles.queryTextStyle}>
                      <RTrans t={t} i18nKey="reflect01a.where03" />
                    </Text>
                    <Dropdown
                      styles={styles.queryOptionStyles}
                      options={[
                        { id: 'DD04', key: 'DD04a', text: t('reflect01a.DD04a') },
                        { id: 'DD04', key: 'DD04b', text: t('reflect01a.DD04b') },
                        { id: 'DD04', key: 'DD04c', text: t('reflect01a.DD04c') },
                        { id: 'DD04', key: 'DD04d', text: t('reflect01a.DD04d') },
                      ]}
                      selectedKey={state.context.reflect01Choices.DD04}
                      onChange={updateReflect01Choices}
                      errorMessage={reflect01bCheckedAnswers.DD04 ? undefined : t('reflect01b.DD04error')}
                    />
                  </Stack.Item>
                  <Stack.Item align="start">
                    <DefaultButton disabled={isDisabledReflect01b} onClick={cbSendClickB02}>
                      {t('reflect01b.B02')}
                    </DefaultButton>
                  </Stack.Item>
                </Stack>
              </Stack>
            </>
          }
        />
      )}

      {/**
       * reflect03 / reflect03.reflect03a
       */}
      {state.matches('reflect03') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('reflect03a.title')}
          body={<Text>{t('reflect03a.body')}</Text>}
          footer={
            <>
              <DefaultButton onClick={cbSendClickB01}>{t('reflect03a.B01')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB02}>{t('reflect03a.B02')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB03}>{t('reflect03a.B03')}</DefaultButton>
            </>
          }
        />
      )}

      {/**
       * reflect03.reflect03b
       */}
      <ResultModal
        isOpen={state.matches('reflect03.reflect03b')}
        title={t('reflect03b.title')}
        body={<Text>{t('reflect03b.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB04}>{t('reflect03b.B04')}</DefaultButton>}
        onDismiss={cbSendClickB04}
      />

      {/**
       * reflect03.reflect03c
       */}
      <ResultModal
        isOpen={state.matches('reflect03.reflect03c')}
        title={t('reflect03c.title')}
        body={<Text>{t('reflect03c.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB05}>{t('reflect03c.B05')}</DefaultButton>}
        onDismiss={cbSendClickB05}
      />

      {/**
       * reflect04
       */}
      {state.matches('reflect04') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('reflect04.title')}
          body={
            <Text>
              <Trans t={t} i18nKey="reflect04.body" />
            </Text>
          }
          footer={
            <>
              <DefaultButton onClick={cbSendClickB01}>{t('reflect04.B01')}</DefaultButton>
            </>
          }
        />
      )}
    </>
  );
};

export default SECSA02;
