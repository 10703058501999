import { z } from 'zod';

// Basic types for JSON requests.
type JsonPrimitive = string | number | boolean | null;
type JsonValue = JsonPrimitive | JsonObject | JsonArray;
type JsonArray = JsonValue[];
type JsonObject = { [key: string]: JsonValue };

export interface InitAPIResponse {
  status: string;
  messages: string[];
  session: LocalSessionData | null;
  sessionIsValid: boolean;
  apiConfig: APIManagerConfig | null;
  gameData: GameData | null;
}

export const LocalSessionDataSchema = z.object({
  id: z.number(),
  key: z.string(),
  sessionId: z.number(),
  sessionUuid: z.string(),
  sessionStartTime: z.number(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LocalSessionData = z.infer<typeof LocalSessionDataSchema>;

///
/// APIManager Types
///

export type APIManagerConfig = {
  url: string | undefined;
  domain: string | undefined;
  id: number | undefined;
  key: string | undefined;
  gameData: GameData | undefined;
};

export type GetGameParams = (
  | {
      game_id: number;
      slug?: string;
    }
  | {
      game_id?: number;
      slug: string;
    }
) & {
  domain?: string;
  token?: string;
  language_id?: number;
};

export type GameData = {
  game_id: number;
  language_id: number;
  session_group_id: number;
};

export type RegisterPlayerParams = (
  | {
      username: string;
      email?: string;
    }
  | {
      username?: string;
      email: string;
    }
) & {
  domain?: string;
  password?: string;
  first_name?: string;
  last_name?: string;
  initials?: string;
  display_name?: string;
  phone?: string;
  employee_id?: string;
  organization?: string;
  country?: string;
};

export type RegisterPlayerResult = {
  message: string;
  id?: number;
  key?: string;
  exists?: boolean;
};

export type StartSessionParams = {
  game_id: number;
  language_id?: number;
  segment?: string;
  level_id?: number;
  session_group_id?: number;
};

export type StartSessionResult = {
  message: string;
  session_id?: number;
  uuid?: string;
};

export type ValidateSessionParams = {
  game_id: number;
  uuid: string;
};

export type ValidateSessionResult = {
  message: string;
  uuid?: string;
};

// Generic type for parameters that will fall back to using GameData. Makes
// any parameter matching a key of GameData optional.
export type GameDataParams<T extends Record<string, JsonValue>> = Partial<Pick<T, keyof GameData>> &
  Omit<T, keyof GameData>;
