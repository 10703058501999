/**
 * i18next NS: `Game01.SECSA03`
 */
export default {
  common: {
    btnTryAgain: "Oops! Try again",
  },
  intro01a: {
    title: "Take action to protect against future risks.",
    body: "How can you proactively manage deletion events?",
    B01: "Scheduled Query in Sentinel",
    B02: "Azure Resource Lock",
    B03: "Azure Policy",
  },
  intro01b: {
    title: "Are you sure?",
    body: "Azure Resource Locks prevent ALL users from deleting or changing a resource. Try something that will work with Role Based Access Controls (RBAC) instead.",
    B04: "$t(common.btnTryAgain)",
  },
  intro01c: {
    title: "Are you sure?",
    body: "Azure Policy provides an aggregated view of your environment so that you can enforce organizational standards and assess compliance at-scale. But right now, you need to set up an ALERT so that you know if any future incidents occur.",
    B05: "$t(common.btnTryAgain)",
  },
  lab01: {
    title: "",
    comingSoon: "Configure Scheduled Query for VM Deletion in Azure Sentinel<br />Simulation Coming Soon!",
    B01: "Continue",
  },
  end01: {
    title: "Part 1 of Security tasks complete!",
    body: "You'll continue the story during the full experience. But now it's time for your final challenge.",
    B01: "Continue to Incident Report",
  },
};
