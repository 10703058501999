import { PointValue } from '../tasks/task-common';

/**
 * These are set for the MVP manually, and may be a little wrong.
 */
export const maxPossibleScores = Object.freeze({
  group: 4 * PointValue.Critical + 2 * PointValue.Bonus,
  identity: 11 * PointValue.Critical + 3 * PointValue.Bonus,
  security: 10 * PointValue.Critical + 0 * PointValue.Bonus,
  IR: 20 * PointValue.Critical + 3 * PointValue.Bonus,
});
