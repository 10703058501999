import type { WebStorage } from 'redux-persist/lib/types';

const storageBackend = window.localStorage;

export interface WebStorageSync extends WebStorage {
  getItemSync: (key: string) => string | null;
  setItemSync: (key: string, item: string) => void;
  removeItemSync: (key: string) => void;
  getAllKeysSync: () => Array<string>;
}

export const storage: WebStorageSync = Object.freeze({
  getItem: (key: string): Promise<string | null> => {
    return new Promise((resolve) => {
      resolve(storageBackend.getItem(key));
    });
  },
  getItemSync: (key: string): string | null => {
    return storageBackend.getItem(key);
  },
  setItem: (key: string, item: string): Promise<void> => {
    return new Promise((resolve) => {
      resolve(storageBackend.setItem(key, item));
    });
  },
  setItemSync: (key: string, item: string): void => {
    return storageBackend.setItem(key, item);
  },
  removeItem: (key: string): Promise<void> => {
    return new Promise((resolve) => {
      resolve(storageBackend.removeItem(key));
    });
  },
  removeItemSync: (key: string): void => {
    return storageBackend.removeItem(key);
  },
  getAllKeys(): Promise<Array<string>> {
    return Promise.resolve(Object.keys(storageBackend));
  },
  getAllKeysSync(): Array<string> {
    return Object.keys(storageBackend);
  },
});
