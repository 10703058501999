/**
 * Copied from https://github.com/statelyai/xstate/blob/c2d6a24bcc7174af543e30aefd2a62c95a9b83ca/packages/xstate-react/src/useConstant.ts (October 18, 2021)
 */
import { useRef } from 'react';

interface ResultBox<T> {
  value: T;
}

export function useConstant<T>(cb: () => T): T {
  const ref = useRef<ResultBox<T>>();

  if (ref.current == null) {
    ref.current = { value: cb() };
  }

  return ref.current.value;
}
