import { PersonaInitialsColor } from '@fluentui/react';

export enum UserRole {
  None = '',
  Identity = 'identity',
  Security = 'security',
}

export const roleData = {
  game01: [UserRole.Identity, UserRole.Security],
};

export const roleColors = {
  identity: PersonaInitialsColor.blue,
  security: PersonaInitialsColor.red,
};
