import { createMachine } from 'xstate';
import { assign, pure } from 'xstate/lib/actions';
import { PointValue } from '../../task-common';

import { addEntry, addScore, getDefaultOptions, matchesTS } from '../../task-options';
import type {
  TaskConfig,
  TaskContext,
  TaskOptions,
  TaskMachineProps,
  TaskEvent,
  TSXEvent,
  LabContext,
} from '../../task-types';

export interface SECSA02Context extends TaskContext {
  lab01: LabContext;
  delete01Choices: Record<string, boolean>;
  delete01HintTotal: number;
  delete01HintsUnlocked: number;
  reflect01Choices: Record<string, string | number | null>;
}
type TContext = SECSA02Context;

export type SECSA02Event =
  | TaskEvent
  | TSXEvent
  | { type: 'SET.DELETE01.CHOICES'; name: string; checked: boolean }
  | { type: 'SET.REFLECT01.CHOICES'; id?: string; key: string | number };
type TEvent = SECSA02Event;

export type SECSA02MachineProps = TaskMachineProps<TContext>;

// Entry ID prefix
const entPfx = 'Game01.SECSA02';

const delete01CorrectAnswers = {
  // Don't include irrelevant answer because we're matching.
  CH03: true,
  CH04: true,
  CH05: true,
};

const reflect01CorrectAnswers = {
  DD01: 'DD01a',
  DD02: 'DD02b',
  DD03: 'DD03c',
  DD04: 'DD04b',
};

/**
 * Partially applied `addScore` action creator with
 * `{ type: 'security', id?: ``Game01.SECSA02.${id}`` }`
 */
const addScoreP = (amount: PointValue, id?: string) => {
  const computedId = id == null ? undefined : `${entPfx}.${id}`;
  return addScore({ type: 'security', amount, id: computedId });
};

// Add all selected properties of delete01 checkboxes to entry.
const addDelete01Entries = ({ delete01Choices }: TContext) => {
  const statesToAdd = Object.keys(delete01Choices).filter((key) => delete01Choices[key]);
  return addEntry(`${entPfx}.supportTicket`, statesToAdd);
};

// Score delete01a checkboxes.
const scoreDelete01a = ({ delete01Choices }: TContext) => {
  const computedPoints = Object.entries(delete01CorrectAnswers).reduce((memo, [key, value]) => {
    return memo + (delete01Choices[key] === value ? PointValue.Critical : 0);
  }, 0);
  return addScoreP(computedPoints, 'delete01a.B01');
};

// Add selected correct properties of reflect01 dropdowns to entry.
const addReflect01Entries = ({ reflect01Choices }: TContext) => {
  const statesToAdd = Object.entries(reflect01CorrectAnswers)
    .filter(([k, v]) => reflect01Choices[k] === v)
    .map(([k]) => k);
  return addEntry(`${entPfx}.KQLQuery`, statesToAdd);
};

const scoreReflect01a = ({ reflect01Choices }: TContext) => {
  const computedPoints = Object.entries(reflect01CorrectAnswers).reduce((memo, [key, correctValue]) => {
    return memo + (reflect01Choices[key] === correctValue ? PointValue.Critical : PointValue.SoftIncorrect);
  }, 0);
  return addScoreP(computedPoints, 'reflect01a.B01');
};

/**
 * Game01.SECSA02 machine config
 */
export const SECSA02Config: TaskConfig<TContext, TEvent> = {
  id: 'SECSA02',
  initial: 'intro01',
  context: {
    lab01: {
      sectionIndex: 0,
      screenIndex: 0,
    },
    delete01Choices: {
      CH01: false,
      CH02: false,
      CH03: false,
      CH04: false,
      CH05: false,
    },
    delete01HintTotal: 3,
    delete01HintsUnlocked: 0,
    reflect01Choices: {
      DD01: null,
      DD02: null,
      DD03: null,
      DD04: null,
    },
  },
  states: {
    intro01: {
      on: {
        'CLICK.B01': {
          target: 'modal01',
        },
      },
    },
    modal01: {
      entry: addEntry(`${entPfx}.alertVMUnreachable`),
      on: {
        'CLICK.B01': {
          target: 'delete01',
        },
      },
    },
    delete01: {
      initial: 'delete01a',
      on: {
        'SET.DELETE01.CHOICES': {
          actions: assign((context, event) => {
            context.delete01Choices[event.name] = event.checked;
            return context;
          }),
        },
      },
      states: {
        delete01a: {
          on: {
            'CLICK.B01': [
              // When correct, goto delete01c
              {
                target: 'delete01c1',
                cond: 'validateDelete01Choices',
                actions: [pure(addDelete01Entries), pure(scoreDelete01a)],
              },
              // Otherwise, goto delete01b
              {
                target: 'delete01b',
                actions: [pure(addDelete01Entries), pure(scoreDelete01a)],
              },
            ],
            'CLICK.HT01': {
              target: 'delete01HT',
            },
          },
        },
        delete01HT: {
          on: {
            'CLICK.D01': {
              target: 'delete01a',
            },
            'CLICK.HT02': {
              actions: [
                assign((context) => {
                  if (context.delete01HintsUnlocked < context.delete01HintTotal) {
                    context.delete01HintsUnlocked += 1;
                  }
                  return context;
                }),
                pure(({ delete01HintsUnlocked }) => {
                  // delete01HintsUnlocked will already be updated by the previous action
                  // First one is free, second costs 1x, third costs 2x.
                  const pointCostMultiplier = Math.max(0, delete01HintsUnlocked - 1);
                  // Cost is multiplier + 3 critical answers on this question
                  const pointCost = pointCostMultiplier * 3;
                  return addScoreP(-pointCost, `delete01HT.hint${delete01HintsUnlocked}`);
                }),
              ],
            },
          },
        },
        delete01b: {
          on: {
            'CLICK.B02': {
              target: 'delete01c2',
              cond: 'validateDelete01Choices',
              actions: pure(addDelete01Entries),
            },
          },
        },
        // Needed 2 different `delete01c` states because it's a modal that pops up over top of either
        // the `delete01a` or `delete01b cards.
        delete01c1: {
          on: {
            'CLICK.B03': { target: '#SECSA02.delete02' },
          },
        },
        delete01c2: {
          on: {
            'CLICK.B03': { target: '#SECSA02.delete02' },
          },
        },
      },
    },
    delete02: {
      initial: 'delete02a',
      states: {
        delete02a: {
          on: {
            'CLICK.B01': { actions: [addScoreP(PointValue.Critical, 'delete02a.B01')], target: '#SECSA02.delete03' },
            'CLICK.B02': { actions: [addScoreP(PointValue.Incorrect, 'delete02a.B02')], target: 'delete02b' },
            'CLICK.B03': { actions: [addScoreP(PointValue.Incorrect, 'delete02a.B03')], target: 'delete02c' },
          },
        },
        delete02b: {
          on: {
            'CLICK.B04': { target: 'delete02a' },
          },
        },
        delete02c: {
          on: {
            'CLICK.B05': { target: 'delete02a' },
          },
        },
      },
    },
    delete03: {
      initial: 'delete03a',
      states: {
        delete03a: {
          on: {
            'CLICK.B01': { target: 'delete03b' },
            'CLICK.B02': { target: '#SECSA02.lab01' },
          },
        },
        delete03b: {
          on: {
            'CLICK.B03': { target: 'delete03a' },
          },
        },
      },
    },
    lab01: {
      on: {
        'ESCAPE': { target: 'reflect01' },
        'TSGS.METADATA': {
          actions: [
            assign(
              (context, event): Partial<typeof context> => ({
                lab01: {
                  ...context.lab01,
                  sectionIndex: event?.currentSection ?? context.lab01.sectionIndex,
                  screenIndex: event?.currentScreen ?? context.lab01.screenIndex,
                },
              }),
            ),
          ],
        },
        'TSGS.NAVIGATED': [
          {
            actions: [
              assign(
                (context, event): Partial<typeof context> => ({
                  lab01: {
                    ...context.lab01,
                    screenId: event?.screenId ?? context.lab01.screenId,
                  },
                }),
              ),
            ],
          },
          {
            target: 'reflect01',
            cond: matchesTS({ sectionIndex: 0, screenIndex: 87 }),
          },
        ],
        'TSGS.SHAPECLICK': [
          // KQLAuthorization_dEvidence - first opportunity (#/0/56)
          {
            actions: addEntry(`${entPfx}.KQLAuthorization_dEvidence`, ['A']),
            cond: matchesTS({ shapeId: '461d12b5-e6ff-40ed-bea5-620eef298ecf' }),
          },
          {
            actions: addEntry(`${entPfx}.KQLAuthorization_dEvidence`, ['B']),
            cond: matchesTS({ shapeId: '361f6067-48d3-446e-aee7-1003f6a664fa' }),
          },
          {
            actions: addEntry(`${entPfx}.KQLAuthorization_dEvidence`, ['C']),
            cond: matchesTS({ shapeId: 'f8d57d9b-bfb8-478c-9477-b75009d12f43' }),
          },
          // KQLProperties_dEvidence - first opportunity (#/0/60)
          {
            actions: addEntry(`${entPfx}.KQLProperties_dEvidence`, ['A']),
            cond: matchesTS({ shapeId: '4706cf3b-f64b-4e5e-ab6a-6413693b0d65' }),
          },
          {
            actions: addEntry(`${entPfx}.KQLProperties_dEvidence`, ['B']),
            cond: matchesTS({ shapeId: '09c2af68-5aa5-407f-8b3d-470e26ae4b39' }),
          },
          // KQLAuthorization_dEvidence - second opportunity (#/0/69)
          {
            actions: addEntry(`${entPfx}.KQLAuthorization_dEvidence`, ['A']),
            cond: matchesTS({ shapeId: '68b463c4-11f4-4449-916b-e87edea0b653' }),
          },
          {
            actions: addEntry(`${entPfx}.KQLAuthorization_dEvidence`, ['B']),
            cond: matchesTS({ shapeId: 'e69593db-db49-4bae-86fa-a134ff25bcad' }),
          },
          {
            actions: addEntry(`${entPfx}.KQLAuthorization_dEvidence`, ['C']),
            cond: matchesTS({ shapeId: '442ad057-06af-4869-855b-f10c5b23ebb6' }),
          },
          // KQLProperties_dEvidence - second opportunity (#/0/73)
          {
            actions: addEntry(`${entPfx}.KQLProperties_dEvidence`, ['A']),
            cond: matchesTS({ shapeId: '4f44a53b-67b5-4487-8df0-893d189f99c6' }),
          },
          {
            actions: addEntry(`${entPfx}.KQLProperties_dEvidence`, ['B']),
            cond: matchesTS({ shapeId: '92f94cf1-3120-4596-9f3e-dfeee84686c5' }),
          },
          // KQLAuthorization_dEvidence - third opportunity (#/0/81)
          {
            actions: addEntry(`${entPfx}.KQLAuthorization_dEvidence`, ['A']),
            cond: matchesTS({ shapeId: '61554dbe-6c31-4bc6-ad18-296d53206cf9' }),
          },
          {
            actions: addEntry(`${entPfx}.KQLAuthorization_dEvidence`, ['B']),
            cond: matchesTS({ shapeId: 'a2cc8da0-92bb-4c1a-b155-47bed160aa7b' }),
          },
          {
            actions: addEntry(`${entPfx}.KQLAuthorization_dEvidence`, ['C']),
            cond: matchesTS({ shapeId: 'cea9ba30-4c8b-4a65-9ebe-e8963c568a19' }),
          },
        ],
      },
    },
    reflect01: {
      initial: 'reflect01a',
      on: {
        'SET.REFLECT01.CHOICES': {
          actions: assign((context, event) => {
            if (event == null || event.id == null) {
              return context;
            }
            context.reflect01Choices[event.id] = event.key;
            return context;
          }),
        },
      },
      states: {
        reflect01a: {
          on: {
            'CLICK.B01': [
              // When correct, goto reflect03
              {
                target: '#SECSA02.reflect03',
                cond: 'validateReflect01Choices',
                actions: [pure(addReflect01Entries), pure(scoreReflect01a)],
              },
              // Otherwise, goto reflect01b
              {
                target: 'reflect01b',
                actions: [pure(addReflect01Entries), pure(scoreReflect01a)],
              },
            ],
          },
        },
        reflect01b: {
          on: {
            'CLICK.B02': {
              target: '#SECSA02.reflect03',
              cond: 'validateReflect01Choices',
              actions: pure(addReflect01Entries),
            },
          },
        },
      },
    },
    reflect03: {
      initial: 'reflect03a',
      states: {
        reflect03a: {
          on: {
            'CLICK.B01': { actions: [addScoreP(PointValue.Incorrect, 'reflect03a.B01')], target: 'reflect03b' },
            'CLICK.B02': { actions: [addScoreP(PointValue.Critical, 'reflect03a.B02')], target: '#SECSA02.reflect04' },
            'CLICK.B03': { actions: [addScoreP(PointValue.Incorrect, 'reflect03a.B03')], target: 'reflect03c' },
          },
        },
        reflect03b: {
          on: {
            'CLICK.B04': { target: 'reflect03a' },
          },
        },
        reflect03c: {
          on: {
            'CLICK.B05': { target: 'reflect03a' },
          },
        },
      },
    },
    reflect04: {
      on: {
        'CLICK.B01': { target: 'done' },
      },
    },
    done: {
      type: 'final',
    },
  },
};

/**
 * Additional machine options.
 */
export const SECSA02Options: TaskOptions<TContext, TEvent> = {
  guards: {
    validateDelete01Choices: ({ delete01Choices }) =>
      Object.entries(delete01CorrectAnswers).every(([k, v]) => delete01Choices[k] === v),
    validateReflect01Choices: ({ reflect01Choices }) =>
      Object.entries(reflect01CorrectAnswers).every(([k, v]) => reflect01Choices[k] === v),
  },
};

/**
 * Machine constructor.
 */
export function createSECSA02() {
  return createMachine(SECSA02Config, getDefaultOptions()).withConfig(SECSA02Options);
}
