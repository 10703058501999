import React, { useMemo } from 'react';
import { Stack } from '@fluentui/react';
import { LabFrameProps, labFrameStyles } from '../LabFrame/LabFrame';

const { stackTokens, stackItemStyles, aspectRatioDivBaseStyles, iFrameStyles: baseDummyFrameStyle } = labFrameStyles;

const DummyLabFrame: React.FC<LabFrameProps> = ({ id, src, title }) => {
  const dummyFrameStyle = useMemo(() => {
    return src == null
      ? baseDummyFrameStyle
      : {
          ...baseDummyFrameStyle,
          backgroundImage: `url(${src})`,
          backgroundSize: 'contain',
        };
  }, [src]);

  return (
    <Stack grow={1} horizontal horizontalAlign="center" tokens={stackTokens} verticalAlign="start" verticalFill>
      <Stack.Item grow={1} styles={stackItemStyles} tokens={stackTokens}>
        <div className="aspect-ratio-box" style={aspectRatioDivBaseStyles}>
          <div id={id} className="dummy-lab-frame" title={title} style={dummyFrameStyle} />
        </div>
      </Stack.Item>
    </Stack>
  );
};

export default DummyLabFrame;
