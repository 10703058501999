/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Stack, Text } from '@fluentui/react';
import _isEqual from 'lodash/isEqual';
import NavPanel from '../NavPanel/NavPanel';
import { maxPossibleScores } from '../../static/scoring-data';
import { RootState } from '../../store';
import PanelUnavailable from '../PanelUnavailable/PanelUnavailable';
import { isDev } from '../../lib/util';

const Help: React.FunctionComponent = () => {
  const { t } = useTranslation('help');
  const score = useSelector((state: RootState) => state.game.score, _isEqual);
  const usedScoreIds = useSelector((state: RootState) => state.game.usedScoreIds, _isEqual);
  return (
    <NavPanel activePanel="help">
      <Text variant="xxLarge" block styles={{ root: { marginBottom: 24 } }}>
        {t('header')}
      </Text>
      {isDev ? (
        <Stack tokens={{ childrenGap: 16, padding: '32px 0' }}>
          <Stack.Item>
            <Link to="/">Return to Home Screen</Link>
          </Stack.Item>
          <Stack.Item>
            <Text block variant="large">
              Score
            </Text>
            <div>
              {Object.entries(score).map(([type, amount]) => {
                return (
                  <div key={type}>
                    <strong>{type}</strong>
                    {': '}
                    {amount}
                    {' / '}
                    {(maxPossibleScores as any)[type] ?? '?'}
                  </div>
                );
              })}
            </div>
          </Stack.Item>
          <Stack.Item>
            <Text block variant="large">
              Used Score IDs
            </Text>
            <Text variant="smallPlus">
              {Object.entries(usedScoreIds)
                .filter(([, value]) => value)
                .map(([id]) => id)
                .join('; ')}
            </Text>
          </Stack.Item>
        </Stack>
      ) : (
        <PanelUnavailable />
      )}
    </NavPanel>
  );
};
export default Help;
