import { io } from 'socket.io-client';
import * as socketIOParser from 'socket.io-parser';

import type { ManagerOptions, SocketOptions } from 'socket.io-client';
import { EventNames, EventParams } from '@socket.io/component-emitter';

import { processEventArgs, ClientEventRoomStatus, ClientEventUserStatus } from './schemas';
import { actions as rdxMPActions } from '../../store/multiplayer-slice';
import { isDev } from '../util';

import type { CoerceNever, Last, SafeReturnType } from '../types';
import type { IOSocket } from './types';
import type { AppDispatch, StoreType } from '../../store';
import type { ClientEmitEventsMap } from './typed-events';

type IOOptions = Partial<ManagerOptions & SocketOptions>;

/**
 * Multiplayer manager singleton.
 *
 * Handles socket.io connections and dispatching events to Redux.
 */
/* eslint-disable class-methods-use-this, no-use-before-define */
export class MPManager {
  static get instance(): MPManager {
    if (MPManager.#instance == null) {
      MPManager.#instance = new MPManager();
    }
    return MPManager.#instance;
  }

  /** Keep the single instance inside a private field */
  static #instance: MPManager;

  /** Forced socket options */
  readonly #forcedSocketOptions: IOOptions = Object.freeze({
    transports: ['websocket'],
  });

  /**
   * Default socket options.
   *
   * Unless otherwise noted, these are the normal Socket.IO defaults.
   */
  readonly #defaultSocketOptions: IOOptions = Object.freeze({
    forceNew: false,
    multiplex: true,
    transports: ['polling', 'websockets'], // Overridden by #forceSocketOptions
    upgrade: true,
    rememberUpgrade: false,
    path: '/socket.io/',
    query: {},
    extraHeaders: {},
    withCredentials: false,
    forceBase64: false,
    timestampRequests: true,
    timestampParam: 't',
    closeOnBeforeunload: true,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    randomizationFactor: 0.5,
    timeout: 20000,
    autoConnect: false, // Changed. Normally `true`.
    parser: socketIOParser,
    auth: {},
  });

  /** Constant dummy socket Identifier & URL */
  readonly #dummySocketID = 'invalid';

  /** Current socket instance */
  get socket() {
    return this.#socket;
  }

  /** Current socket `emit()` method. */
  get emit() {
    // Need to use bound private variable or it doesn't work.
    return this.#socketEmit;
  }

  /** Current socket ID */
  get socketID() {
    return this.#socket.id;
  }

  /** Store dispatch() method. */
  get dispatch() {
    return this.#storeDispatch;
  }

  /** Store getState() method. */
  get getState() {
    return this.#storeGetState;
  }

  /** (Private) URI passed in to the socket instance */
  #socketURI: string = this.#dummySocketID;

  /** (Private) Socket instance. */
  #socket: IOSocket = this.#createDummySocket();

  /** (Private) Socket instance bound `emit()`. */
  #socketEmit = this.#socket.emit.bind(this.#socket);

  /** (Private) Redux store intance. */
  #store: StoreType | null = null;

  /** (Private) Store instance bound `dispatch()`. */
  #storeDispatch = this.#dummyDispatch as AppDispatch;

  /** (Private) Store instance bound `getState()`. */
  #storeGetState = this.#dummyGetState as StoreType['getState'];

  /** Singleton is instantiated by `MPManager.instance` getter */
  private constructor() {
    if (
      process.env.REACT_APP_SOCKET_URL != null &&
      process.env.REACT_APP_FORCE_SLUG != null &&
      process.env.REACT_APP_SOCKET_AUTH_USERNAME != null &&
      process.env.REACT_APP_SOCKET_AUTH_KEY != null
    ) {
      this.#socket = this.setupSocket(process.env.REACT_APP_SOCKET_URL, {
        auth: {
          slug: process.env.REACT_APP_FORCE_SLUG,
          username: process.env.REACT_APP_SOCKET_AUTH_USERNAME,
          key: process.env.REACT_APP_SOCKET_AUTH_KEY,
        },
      });
      this.#socket.connect();
    }
  }

  /**
   * Promisified socket `emit()` that wraps event callbacks.
   */
  emitPromise<
    Ev extends EventNames<ClientEmitEventsMap>,
    Output extends CoerceNever<SafeReturnType<Last<EventParams<ClientEmitEventsMap, Ev>>>, void>,
  >(ev: Ev, ...args: EventParams<ClientEmitEventsMap, Ev>): Promise<Output> {
    const socket = this.#socket;
    return new Promise((resolve, reject) => {
      // If the last event parameter is a callback, replace it with a wrapped version
      // that resolves or rejects when it completes
      const callback = args[args.length - 1];
      if (typeof callback === 'function') {
        const newArgs: EventParams<ClientEmitEventsMap, Ev> = [...args];
        const wrappedCallback = async (...cbArgs: any) => {
          try {
            // It's possible that the callback is async or returns a promise.
            const result: Output = await callback(...cbArgs);
            resolve(result);
          } catch (e: any) {
            reject(e);
          }
        };
        newArgs[newArgs.length - 1] = wrappedCallback;
        socket.emit(ev, ...newArgs);
        return;
      }

      // Otherwise, just emit and resolve unless any errors occure.
      try {
        socket.emit(ev, ...args);
        resolve(undefined as Output);
      } catch (e: any) {
        reject(e);
      }
    });
  }

  /**
   * Inject a Redux store into the MP Manager.
   */
  injectStore(store: StoreType) {
    this.#store = store;
    this.#storeDispatch = this.#store.dispatch.bind(this.#store);
    this.#storeGetState = this.#store.getState.bind(this.#store);
    return this.#store;
  }

  /**
   * Set up a new socket
   */
  setupSocket(uri: string = this.#socketURI, options: Partial<ManagerOptions & SocketOptions> = {}) {
    // Return the existing socket if the URI is the same.
    if (uri === this.#socketURI) {
      return this.#socket;
    }

    // Otherwise, shut down the socket and remove all listeners.
    // NOTE: We skip generating the new dummy socket here because we'll immediately replace it
    this.teardownSocket(false);

    this.#devLog('Creating socket to', uri);
    this.#socket = io(uri, {
      ...this.#defaultSocketOptions,
      ...options,
      ...this.#forcedSocketOptions,
    });
    this.#socketEmit = this.#socket.emit.bind(this.#socket);
    this.#socket.id = ''; // Initial value is `undefined` despite TS type.
    this.#socketURI = uri;
    this.#registerSocketHandlers();
    return this.#socket;
  }

  /**
   * Tear down the existing socket instance.
   * Optionally replace it with a new dummy instance.
   */
  teardownSocket(generateNewDummySocket = true) {
    if (this.#socket.connected) {
      this.#socket.disconnect();
    }
    this.#socket.removeAllListeners();
    this.#socket.id = undefined as any;
    this.#socketURI = '';

    // Optionally replace the old socket instance with a dummy.
    if (generateNewDummySocket) {
      const dummySocket = this.#createDummySocket();
      this.#socket = dummySocket;
      this.#socketEmit = this.#socket.emit.bind(this.#socket);
    }
    return this.#socket;
  }

  #registerSocketHandlers() {
    const socket = this.#socket;

    // Register Socket reserved handlers
    socket.on('connect', () => {
      this.#devLog('Socket connected.');
      this.dispatch(rdxMPActions.updateUserStatus({ status: 'connected' }));
    });

    socket.on('connect_error', (error) => {
      this.#devLog('Socket connection error:', error.message);
      this.dispatch(rdxMPActions.updateUserStatus({ status: null }));
    });

    socket.on('disconnect', (reason: string) => {
      this.#devLog('Socket disconnected:', reason);
      this.dispatch(rdxMPActions.updateUserStatus({ status: null }));
    });

    // Register socket listen event handlers
    socket.on('status:room', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventRoomStatus);
      if (!args.success) {
        this.#errLog('ClientEventRoomStatus Validation error:', args.error.message);
        return;
      }
      this.#devLog('Room status update:', args.data);
    });

    socket.on('status:user', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventUserStatus);
      if (!args.success) {
        this.#errLog('ClientEventUserStatus Validation error:', args.error.message);
        return;
      }
      this.dispatch(rdxMPActions.updateUserStatus(args.data));
    });

    // Register catch-all debug listener
    if (isDev) {
      socket.onAny((eventName: string, ...args: any[]) => {
        this.#devLog(`Socket event received ('${eventName}')`, ...args);
      });
    }

    return socket;
  }

  #devLog(...args: any[]) {
    if (!isDev) {
      return;
    }
    // eslint-disable-next-line no-console
    console.log(`[MPManager][#${this.socket.id ?? ''}]`, ...args, { context: this });
  }

  #errLog(...args: any[]) {
    if (!isDev) {
      return;
    }
    // eslint-disable-next-line no-console
    console.error(`[MPManager][#${this.socket.id ?? ''}]`, ...args, { context: this });
  }

  /** Return a dummy socket */
  #createDummySocket(): IOSocket {
    const newSocket = io('dummy.invalid', { autoConnect: false, ...this.#forcedSocketOptions });
    newSocket.id = this.#dummySocketID;
    return newSocket;
  }

  /** Dummy `dispatch()` function for when the store is unset */
  #dummyDispatch() {
    throw new Error('Tried to `dispatch()` with undefined store.');
  }

  /** Dummy `getState()` function for when the store is unset */
  #dummyGetState() {
    throw new Error('Tried to `getState()` with undefined store.');
  }
}
