import React from 'react';
import { Text, Stack, DefaultEffects, ITheme } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { Control } from 'react-hook-form';
import { IR01Option, IR01TaskProps, IRForm } from '../../interface';
import { RootState } from '../../../../../store';
import RTrans from '../../../../../components/RTrans/RTrans';
import { ControlledDropdown } from '../../../../../components/HookFormComponents/HookFormComponents';
import { nameof } from '../../../../../lib/util';
import { generateOptions } from '../../data/options';

// can't use static data because it doens't have compilance yet
const roles = ['identity', 'security', 'compliance'];

const pageHeadChoiceStyles = {
  root: {
    minWidth: 120,
  },
};

interface IRPageHeadProps extends IR01TaskProps {
  control: Control<IRForm, object>;
  incidentImpactOptions: IR01Option[];
  dataLossOptions: IR01Option[];
  theme: ITheme;
  date: string;
}

const IRPageHead: React.FunctionComponent<IRPageHeadProps> = ({
  t,
  incidentImpactOptions,
  dataLossOptions,
  control,
  theme,
  date,
}) => {
  const role = useSelector((state: RootState) => state.user.role);
  const username = useSelector((state: RootState) => state.user.username);
  return (
    <Stack
      styles={{
        root: {
          backgroundColor: theme.palette.neutralLighterAlt,
          padding: 24,
          boxShadow: DefaultEffects.elevation16,
        },
      }}
      tokens={{ childrenGap: 16 }}
    >
      <Stack.Item>
        <Text variant="mediumPlus">{t('ir.pageHead.incidentID')}</Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant="mediumPlus">{t('ir.pageHead.date', { date })}</Text>
      </Stack.Item>
      {roles.map((r) => (
        <Stack.Item key={`ir_pagehead_${r}`}>
          <Text variant="mediumPlus">
            {t(`ir.pageHead.${r}`, {
              playerName: role === r ? username : t('ir.pageHead.unavailable'),
            })}
          </Text>
        </Stack.Item>
      ))}
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item styles={pageHeadChoiceStyles}>
            <Text variant="mediumPlus">
              <RTrans t={t} i18nKey="ir.pageHead.incidentImpact.header" />
            </Text>
          </Stack.Item>
          <Stack.Item>
            <ControlledDropdown
              required
              rules={{ required: true }}
              dropdownWidth="auto"
              options={generateOptions(incidentImpactOptions, 'ir.pageHead.incidentImpact.options', t)}
              control={control}
              name={nameof<IRForm>('incidentImpact')}
              placeholder={t('ir.placeholder')}
              id="incidentImpact"
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item styles={pageHeadChoiceStyles}>
            <Text variant="mediumPlus">
              <RTrans t={t} i18nKey="ir.pageHead.dataLoss.header" />
            </Text>
          </Stack.Item>
          <Stack.Item>
            <ControlledDropdown
              required
              rules={{ required: true }}
              dropdownWidth="auto"
              options={generateOptions(dataLossOptions, 'ir.pageHead.dataLoss.options', t)}
              control={control}
              name={nameof<IRForm>('dataLoss')}
              placeholder={t('ir.placeholder')}
              id="dataLoss"
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default IRPageHead;
