import React from 'react';
import { motion } from 'framer-motion';
import { Stack } from '@fluentui/react';
import { ENTRY_SLIDE_TIME } from '../../lib/anim-timings';
import EntrySeenChecker from './EntrySeenChecker';
import { EntryComponentProps, getEntryColor } from '../../lib/entries';
import EntryUnread from './EntryUnread';
import EntryShowMore from './EntryShowMore';
import EntryContent from './EntryContent';
import EntryIconCol from './EntryIconCol';

const variants = {
  open: { height: 'auto', opacity: 1 },
  closed: { height: 0, opacity: 0 },
};

const Entry: React.FunctionComponent<EntryComponentProps> = ({
  category,
  type,
  date,
  description,
  content,
  more,
  entry_id,
  unread,
  active,
}) => {
  return (
    <motion.div
      id={`${entry_id}`}
      transition={{ duration: ENTRY_SLIDE_TIME / 1000 }}
      layout="position"
      animate={active ? 'open' : 'closed'}
      variants={variants}
      style={{ margin: '0px -24px', overflow: 'hidden', position: 'relative' }}
      initial={{ opacity: 0 }}
    >
      <EntryUnread unread={unread} />
      <Stack
        horizontal
        tokens={{ childrenGap: 24 }}
        styles={{
          root: {
            backgroundColor: getEntryColor(type).backgroundColor,
            padding: 24,
          },
        }}
      >
        <Stack.Item>
          <EntryIconCol category={category} type={type} />
        </Stack.Item>
        <Stack.Item styles={{ root: { position: 'relative' } }}>
          <Stack.Item>
            <EntryContent date={date} description={description} content={content} entry_id={entry_id} />
          </Stack.Item>
          <Stack.Item>
            {more && more.length > 0 && <EntryShowMore more={more} type={type} entry_id={entry_id} />}
          </Stack.Item>
        </Stack.Item>
        <Stack.Item />
      </Stack>
      {unread && <EntrySeenChecker entry_id={entry_id} active={active} category={category} />}
    </motion.div>
  );
};
export default Entry;
