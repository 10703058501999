import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';
import { ActionButton, Text } from '@fluentui/react';
import { SHOW_MORE_SLIDE_TIME } from '../../lib/anim-timings';
import { EntryShowMoreProps, generateShowMoreButtonStyles } from '../../lib/entries';
import RTrans from '../RTrans/RTrans';

const variants = {
  open: { height: 'auto', opacity: 1 },
  closed: { height: 0, opacity: 0 },
};

const EntryShowMore: React.FunctionComponent<EntryShowMoreProps> = ({ more, entry_id, type }) => {
  const { t } = useTranslation('entries');
  const [showMore, { toggle: setShowMore }] = useBoolean(false);

  return (
    <div>
      <ActionButton
        aria-expanded={showMore}
        aria-controls={`${entry_id}-panel`}
        toggle
        checked={showMore}
        onClick={setShowMore}
        styles={generateShowMoreButtonStyles(type)}
        iconProps={{
          iconName: showMore ? 'SkypeCircleMinus' : 'CirclePlus',
        }}
        text={t('journalEntry:seeMore')}
      />
      <motion.div
        id={`${entry_id}-panel`}
        transition={{ duration: SHOW_MORE_SLIDE_TIME / 1000 }}
        animate={showMore ? 'open' : 'closed'}
        initial={variants.closed}
        variants={variants}
        style={{ overflow: 'hidden' }}
      >
        {more.map((m, i) => (
          <Text key={`${entry_id}_more_${i}`} variant="medium" block>
            <RTrans t={t} i18nKey={m} />
          </Text>
        ))}
      </motion.div>
    </div>
  );
};
export default EntryShowMore;
