import React from 'react';
import { DefaultButton, Stack, Text } from '@fluentui/react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { RegisterTaskComponentProps } from '../interface';
import { RootState } from '../../../../store';

interface TitleProps extends RegisterTaskComponentProps {
  onClick: Function;
}

const Title: React.FunctionComponent<TitleProps> = ({ onClick, t }) => {
  const gameData = useSelector((state: RootState) => state.game.gameData);
  return (
    <Stack
      verticalFill
      verticalAlign="center"
      horizontalAlign="center"
      tokens={{ childrenGap: 24 }}
      styles={{
        root: {
          textAlign: 'center',
        },
      }}
    >
      <Stack.Item>
        <Text variant="mega" block>
          {t('title.title')}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant="xxLarge" block>
          {t('title.release')}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <DefaultButton disabled={isEmpty(gameData)} onClick={() => onClick()}>
          {t('title.button')}
        </DefaultButton>
      </Stack.Item>
    </Stack>
  );
};

export default Title;
