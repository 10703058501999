import _assign from 'lodash/assign';
import { createMachine, assign } from 'xstate';
import { PointValue } from '../../task-common';
import { getDefaultOptions, addEntry, addScore } from '../../task-options';
import type { TaskConfig, TaskContext, TaskOptions, TaskMachineProps, TaskEvent, TSXEvent } from '../../task-types';

export interface GA01Context extends TaskContext {
  criticalInformationChecked: string[];
}
type TContext = GA01Context;

type SetCheckedEvent = {
  type: 'SET_CHECKED';
  checked: string[];
};

type StaticRouteSkipEvent = {
  type: 'STATIC_ROUTE_SKIP';
};

export type GA01Event = TaskEvent | TSXEvent | SetCheckedEvent | StaticRouteSkipEvent;
type TEvent = GA01Event;

export type GA01MachineProps = TaskMachineProps<TContext>;

/**
 * Partially applied `addScore` action creator with
 * `{ type: 'group', id?: ``Game01.GA01.${id}`` }`
 */
const addScoreP = (amount: PointValue, id?: string) => {
  const computedId = id == null ? undefined : `Game01.GA01.${id}`;
  return addScore({ type: 'group', amount, id: computedId });
};

/**
 * Machine config.
 */
export const GA01Config: TaskConfig<TContext, TEvent> = {
  id: 'GA01',
  initial: 'urgentMessage',
  context: {
    criticalInformationChecked: [],
  },
  states: {
    urgentMessage: {
      entry: [addEntry('Game01.GA01.ALERT'), addScoreP(PointValue.Critical, 'ALERT')],
      on: {
        STATIC_ROUTE_SKIP: {
          target: 'suspiciousFile',
        },
        NEXT: {
          target: 'whatHappened',
        },
      },
    },
    whatHappened: {
      on: {
        NEXT: {
          target: 'suspiciousFile',
        },
      },
    },
    suspiciousFile: {
      initial: 'makeChoice',
      states: {
        makeChoice: {
          on: {
            'CLICK.OPEN': { actions: [addScoreP(PointValue.Incorrect, 'suspiciousFile.open')], target: 'wrongOpen' },
            'CLICK.FORWARD': {
              actions: [addScoreP(PointValue.Incorrect, 'suspiciousFile.forward')],
              target: 'wrongForward',
            },
            'CLICK.CHECK': {
              actions: [addScoreP(PointValue.Critical, 'suspiciousFile.check')],
              target: '#GA01.criticalInformation',
            },
          },
        },
        wrongOpen: {
          on: {
            'CLICK.CLOSE': { target: 'makeChoice' },
          },
        },
        wrongForward: {
          on: {
            'CLICK.CLOSE': { target: 'makeChoice' },
          },
        },
      },
    },
    // NOTE: Scoring for this is currently handled in 03_CriticalInformation.tsx
    criticalInformation: {
      entry: [addEntry('Game01.GA01.LEAKED_FILE_ANALYSIS')],
      initial: 'selectProps',
      states: {
        selectProps: {
          on: {
            'CLICK.HINT': { target: 'receiveHint' },
            'CLICK.DONE.INCORRECT': { target: 'forceSelectCorrect' },
            'CLICK.DONE': {
              target: '#GA01.journalCallout',
              actions: ['addCheckedEntries'],
            },
            'SET_CHECKED': {
              actions: assign({
                criticalInformationChecked: (context, event: SetCheckedEvent) => event.checked,
              }),
            },
          },
        },
        receiveHint: {
          on: {
            'CLICK.CLOSE': { target: 'selectProps' },
          },
        },
        forceSelectCorrect: {
          on: {
            'CLICK.DONE': {
              actions: ['addCheckedEntries'],
              target: '#GA01.journalCallout',
            },
            'SET_CHECKED': {
              actions: assign({
                criticalInformationChecked: (context, event: SetCheckedEvent) => event.checked,
              }),
            },
          },
        },
      },
    },
    journalCallout: {
      on: {
        NEXT: {
          target: 'suspectIdentified',
        },
      },
    },
    suspectIdentified: {
      on: {
        NEXT: {
          target: 'done',
        },
      },
    },
    done: {
      type: 'final',
    },
  },
};

/**
 * Additional machine options.
 */
export const GA01Options: TaskOptions<TContext, TEvent> = {
  actions: {
    addCheckedEntries(context, event, meta) {
      const { machine } = meta.state;
      if (machine) {
        const { actions } = machine.options;
        if (actions && typeof actions.addJournalEntry === 'function') {
          const m = _assign(meta, {
            action: {
              type: 'addEntry',
              id: 'Game01.GA01.LEAKED_FILE_PROPERTIES',
              states: context.criticalInformationChecked,
            },
          });
          actions.addJournalEntry(context, event, m);
        }
      }
    },
  },
};
/**
 * Machine constructor.
 */
export function createGA01() {
  return createMachine(GA01Config, getDefaultOptions()).withConfig(GA01Options);
}
