import React from 'react';
import { Text, FontWeights, DefaultButton, Stack } from '@fluentui/react';
import RTrans from '../../../../components/RTrans/RTrans';
import ScreenModal from '../../../../components/ScreenModal/ScreenModal';
import { GA01ComponentProps } from '../interface';

interface SuspectIdentifiedProps extends GA01ComponentProps {
  isStandaloneRoute: boolean;
}

const SuspectIdentified: React.FunctionComponent<SuspectIdentifiedProps> = (
  { t, onClick = () => { }, isStandaloneRoute },
) => {
  return (
    <ScreenModal
      title={
        <Text variant="xxLarge" styles={{ root: { fontWeight: FontWeights.bold } }}>
          {t('suspectIdentified.header')}
        </Text>
      }
      body={
        <Stack tokens={{ childrenGap: 24 }}>
          <Stack.Item>
            <Text variant="large">
              <RTrans t={t} i18nKey="suspectIdentified.body" />
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Text variant="large">
              <ul>
                {[...Array(4)].map((v, i) => (
                  <li key={`si_ul_li_${i}`} style={{ marginBottom: 16 }}>
                    {t(`suspectIdentified.point${i + 1}`)}
                  </li>
                ))}
              </ul>
            </Text>
          </Stack.Item>
        </Stack>

      }
      footerAlign="start"
      footer={(
        <>
          {isStandaloneRoute && (
            <Text variant="large" styles={{ root: { fontWeight: FontWeights.bold, width: '100%' } }}>
              <RTrans t={t} i18nKey="suspectIdentified.staticRouteMsg" />
            </Text>
          )}
          {!isStandaloneRoute && (<DefaultButton onClick={() => onClick()}>{t('buttons.next')}</DefaultButton>)}
        </>
      )}
    />
  );
};

export default SuspectIdentified;
