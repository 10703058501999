import React, { useEffect, useState } from 'react';
import { DefaultButton, Text } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import ResultModal from '../../../../components/ResultModal/ResultModal';
import { RootState } from '../../../../store';
import { setActiveJournalFilter, setActivePanel } from '../../../../store/game-slice';
import { GA01ComponentProps } from '../interface';

interface JournalCalloutProps extends GA01ComponentProps { }

const JournalCallout: React.FunctionComponent<JournalCalloutProps> = ({ t, send = () => { } }) => {
  const dispatch = useDispatch();
  const [modalUp, setModalUp] = useState(false);
  const activePanel = useSelector((state: RootState) => state.game.activePanel);
  // need to selectively go to proper role task here
  function completeTask() {
    dispatch(setActivePanel(''));
    send('NEXT');
  }
  useEffect(() => {
    dispatch(setActiveJournalFilter('all'));
    if (!modalUp) {
      if (activePanel !== 'journal') {
        setTimeout(() => {
          dispatch(setActivePanel('journal'));
        }, 1000);
      }
    }
    setTimeout(() => {
      setModalUp(true);
    }, 2000);
  }, [dispatch, setModalUp, activePanel, modalUp]);

  return (
    <>
      {!modalUp && (
        <div
          className="blocker"
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            top: 0,
            pointerEvents: 'all',
            zIndex: 99999999,
          }}
        />
      )}

      <ResultModal
        isOpen={modalUp}
        onDismiss={() => completeTask()}
        title={t('journalCallout.header')}
        body={<Text>{t('journalCallout.body')}</Text>}
        footer={<DefaultButton onClick={() => completeTask()}>{t('journalCallout.button')}</DefaultButton>}
      />
    </>
  );
};

export default JournalCallout;
