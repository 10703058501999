import React from 'react';
import { DefaultButton, PrimaryButton, Text } from '@fluentui/react';
import RTrans from '../../../../components/RTrans/RTrans';
import ScreenModal, { ScreenModalSidebarProps } from '../../../../components/ScreenModal/ScreenModal';
import { IntroTaskProps } from '../interface';
import ResultModal from '../../../../components/ResultModal/ResultModal';

interface IntroComponentProps extends IntroTaskProps {
  onClick: Function;
  sidebar: ScreenModalSidebarProps;
  showModal: boolean;
}

const Intro1: React.FunctionComponent<IntroComponentProps> = ({ onClick, t, sidebar, showModal, send = (e) => e }) => {
  return (
    <>
      <ScreenModal
        sidebar={sidebar}
        title={t('intro1.title')}
        body={
          <Text variant="large">
            <RTrans t={t} i18nKey={['intro1.content']} />
          </Text>
        }
        footer={
          <>
            <PrimaryButton onClick={() => send('CLICK.MODAL_OPEN')}>{t('buttons.readMore')}</PrimaryButton>
            <DefaultButton onClick={() => onClick()}>{t('buttons.first')}</DefaultButton>
          </>
        }
      />
      <ResultModal
        isOpen={showModal}
        title={t('readMoreModal.title')}
        body={<RTrans>{t('readMoreModal.content')}</RTrans>}
        footer={<DefaultButton onClick={() => send('CLICK.MODAL_CLOSE')}>{t('buttons.close')}</DefaultButton>}
        onDismiss={() => send('CLICK.MODAL_CLOSE')}
      />
    </>
  );
};

export default Intro1;
