import type { EntryDefinition } from '../types';

/**
 * Game01.SECSA02 entry definitions
 */
const SECSA02: EntryDefinition = {
  alertVMUnreachable: {
    type: 'alert',
    category: 'alert',
  },
  supportTicket: {
    type: 'security',
    category: 'evidence',
  },
  // lab01
  KQLAuthorization_dEvidence: {
    type: 'security',
    category: 'evidence',
  },
  KQLProperties_dEvidence: {
    type: 'security',
    category: 'evidence',
  },
  // reflect01
  KQLQuery: {
    type: 'security',
    category: 'evidence',
  },
};

export default SECSA02;
