/**
 * Adapted from @xstate/react/lib/useMachine
 * See: https://github.com/statelyai/xstate/blob/c2d6a24bcc7174af543e30aefd2a62c95a9b83ca/packages/xstate-react/src/useMachine.ts (October 18, 2021)
 */

import { useCallback, useState } from 'react';
import {
  EventObject,
  StateMachine,
  State,
  Interpreter,
  InterpreterOptions,
  MachineOptions,
  StateConfig,
  Typestate,
  ActionFunction,
} from 'xstate';
import { MaybeLazy, ReactActionFunction, ReactEffectType } from '@xstate/react/lib/types';
import { useInterpretReloadable } from './useInterpretReloadable';

function createReactActionFunction<TContext, TEvent extends EventObject>(
  exec: ActionFunction<TContext, TEvent>,
  tag: ReactEffectType,
): ReactActionFunction<TContext, TEvent> {
  const effectExec: unknown = (...args: Parameters<typeof exec>) => {
    // don't execute; just return
    return () => {
      return exec(...args);
    };
  };

  Object.defineProperties(effectExec, {
    name: { value: `effect:${exec.name}` },
    __effect: { value: tag },
  });

  return effectExec as ReactActionFunction<TContext, TEvent>;
}

export function asEffect<TContext, TEvent extends EventObject>(
  exec: ActionFunction<TContext, TEvent>,
): ReactActionFunction<TContext, TEvent> {
  return createReactActionFunction(exec, ReactEffectType.Effect);
}

export function asLayoutEffect<TContext, TEvent extends EventObject>(
  exec: ActionFunction<TContext, TEvent>,
): ReactActionFunction<TContext, TEvent> {
  return createReactActionFunction(exec, ReactEffectType.LayoutEffect);
}

export interface UseMachineOptions<TContext, TEvent extends EventObject> {
  /**
   * If provided, will be merged with machine's `context`.
   */
  context?: Partial<TContext>;
  /**
   * The state to rehydrate the machine to. The machine will
   * start at this state instead of its `initialState`.
   */
  state?: StateConfig<TContext, TEvent>;
}

export function useMachineReloadable<
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext },
>(
  getMachine: MaybeLazy<StateMachine<TContext, any, TEvent, TTypestate>>,
  options: Partial<InterpreterOptions> &
    Partial<UseMachineOptions<TContext, TEvent>> &
    Partial<MachineOptions<TContext, TEvent>> = {},
): [
  State<TContext, TEvent, any, TTypestate>,
  Interpreter<TContext, any, TEvent, TTypestate>['send'],
  Interpreter<TContext, any, TEvent, TTypestate>,
] {
  const listener = useCallback((nextState: State<TContext, TEvent, any, TTypestate>) => {
    // Only change the current state if:
    // - the incoming state is the "live" initial state (since it might have new actors)
    // - OR the incoming state actually changed.
    //
    // The "live" initial state will have .changed === undefined.
    const initialStateChanged = nextState.changed === undefined && Object.keys(nextState.children).length;

    // ADDED: Check machine property to see if new state was just loaded.
    const stateReloaded = nextState.machine == null;

    if (nextState.changed || initialStateChanged || stateReloaded) {
      // console.log('updated');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setState(nextState);
    }
  }, []);

  const service = useInterpretReloadable(getMachine, options, listener);

  const [state, setState] = useState(() => {
    const { initialState } = service.machine;
    return (options.state ? State.create(options.state) : initialState) as State<TContext, TEvent, any, TTypestate>;
  });

  return [state, service.send, service];
}
