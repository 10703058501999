/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useTaskMachine } from '../../../lib/hooks/useTaskMachine';
import { createIR01 } from './machine';
import { ScreenModalSidebarProps } from '../../../components/ScreenModal/ScreenModal';
import Intro from './components/01_Intro';
import IncidentReport from './components/02_IncidentReport';

const baseNS = 'Game01.IR01';
const taskKey = 'Game01.IR01';

const IR01: React.FunctionComponent = () => {
  const { t } = useTranslation([baseNS, 'roles']);
  const history = useHistory();

  // Setup task machine
  const [state, send] = useTaskMachine(createIR01, {
    taskKey,
  });

  // Move along to the next task if state is done.
  useEffect(() => {
    if (state.done) {
      history.push('/tasks/Game01/Ending');
    }
  }, [state, history]);

  // Memoize IR manager persona
  const IRManager: ScreenModalSidebarProps = useMemo(
    () => ({
      title: t('IRManager.name'),
      persona: {
        imageInitials: t('IRManager.initials'),
      },
    }),
    [t],
  );

  const gotoNext = () => {
    send('NEXT');
  };

  return (
    <>
      {state.matches('intro') && <Intro t={t} sidebar={IRManager} onClick={gotoNext} />}
      {state.matches('incidentReport') && <IncidentReport t={t} state={state.value} send={send} />}
    </>
  );
};

export default IR01;
