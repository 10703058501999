import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, IIconProps, IButtonStyles, IconButton } from '@fluentui/react';
import { setActivePanel } from '../../store/game-slice';
import { RootState } from '../../store';
import NavBarUnread from './NavBarUnread';
import NavBarCallout from './NavBarCallout';

export const baseIconButtonStyles: IButtonStyles = {
  root: {
    height: 'auto',
    width: 92,
    minHeight: 92,
    paddingTop: 12,
    pointer: 'cursor',
    label: {
      marginTop: 12,
      pointerEvents: 'none',
    },
  },
  rootPressed: {
    height: 'auto',
  },
  flexContainer: {
    flexDirection: 'column',
  },
};

export interface NavBarButtonProps {
  panel: string;
  icon: string;
  activeIcon?: string;
  disabled: boolean;
  unread: number;
  title: string;
  label: string;
}

function createIconProps(fontSize: number, iconName: string): IIconProps {
  return {
    styles: {
      root: {
        fontSize,
        display: 'block',
      },
    },
    iconName,
  };
}

const NavBarButton: React.FunctionComponent<NavBarButtonProps> = ({
  panel,
  icon,
  activeIcon,
  title,
  label,
  unread,
  disabled,
}) => {
  const activeCallout = useSelector((state: RootState) => state.game.activeCallout.indexOf(panel) !== -1);
  const activePanel = useSelector((state: RootState) => state.game.activePanel);
  const dispatch = useDispatch();
  function onClick() {
    dispatch(setActivePanel(panel));
  }
  const isActive = activePanel === panel;

  return (
    <IconButton
      iconProps={createIconProps(36, isActive ? activeIcon || icon : icon)}
      id={`navBarButton_${panel}`}
      title={title}
      ariaLabel={title}
      toggle
      disabled={disabled}
      styles={baseIconButtonStyles}
      onClick={() => {
        onClick();
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <NavBarCallout active={activeCallout} />
        <NavBarUnread isActive={isActive} target={`navBarButton_${panel}`} unread={unread} />
        <Label>{label}</Label>
      </div>
    </IconButton>
  );
};

export default NavBarButton;
