import _pick from 'lodash/pick';
import _toArray from 'lodash/toArray';
import _toString from 'lodash/toString';
import _uniq from 'lodash/uniq';
import _union from 'lodash/union';
import _difference from 'lodash/difference';

/**
 * Short-hand for entry ID.
 */
export type EntryID = string;

/**
 * Short-hand for list of states.
 */
export type StateList = Array<string>;

/**
 * Stored journal entry with date of update and states.
 */
export interface JournalEntry {
  id: EntryID;
  category: string;
  type: string;
  date: number;
  unread: boolean;
  states: StateList;
}

export interface JournalEntryUpdate {
  id: EntryID;
  date?: number;
  unread?: boolean;
  states?: StateList;
}

const JournalEntryFields = ['id', 'date', 'states', 'unread'];

/**
 * Query options for inventory search.
 */
export interface JournalEntryQueryOptions {
  every?: StateList;
  some?: StateList;
  none?: StateList;
}

/**
 * Full item query (with query options).
 */
export interface JournalEntryQuery extends JournalEntryQueryOptions {
  id: EntryID;
}

export function matchesEntry(entry?: JournalEntry, options?: JournalEntryQueryOptions): boolean {
  // If entry doesn't exist, then they don't have it.
  if (entry == null) {
    return false;
  }

  // Short-circuit true if no query options are provided.
  if (options == null) {
    return true;
  }

  // Get states for condition check
  const entryStates = new Set(entry.states);

  // Check every/all condition
  if (options.every != null && options.every.length > 0 && !options.every.every((state) => entryStates.has(state))) {
    return false;
  }

  // Check some/any condition
  if (options.some != null && options.some.length > 0 && !options.some.some((state) => entryStates.has(state))) {
    return false;
  }

  // Check none condition
  if (options.none != null && options.none.length > 0 && options.none.some((state) => entryStates.has(state))) {
    return false;
  }

  // Return true if every check was passed.
  return true;
}

export function updateEntry(baseEntry?: JournalEntry, updates?: JournalEntryUpdate) {
  const entry = sanitizeEntry(baseEntry);
  if (updates == null) {
    return entry;
  }

  const { id, date, states } = updates;
  let didChange = false;
  let dateSet = false;

  if (typeof id === 'string' && id !== entry.id) {
    entry.id = id;
    didChange = true;
  }

  if (typeof date === 'string' && date !== entry.date) {
    entry.date = date;
    didChange = true;
    dateSet = true;
  }

  if (Array.isArray(states)) {
    const currentStates = _uniq(entry.states);
    const combinedStates = _union(currentStates, updates.states);
    if (combinedStates.length !== currentStates.length) {
      entry.states = combinedStates;
      didChange = true;
    }
  }

  if (didChange && !dateSet) {
    entry.date = Date.now();
  }

  if (didChange) {
    entry.unread = true;
  }

  return sanitizeEntry(entry);
}

export function removeStates(baseEntry?: JournalEntry, states?: StateList) {
  // Null entry remains null.
  if (baseEntry == null) {
    return null;
  }

  const entry = sanitizeEntry(baseEntry);

  if (Array.isArray(states) && states.length > 0) {
    // Passing '*' as a state to remove will delete the entry and all its states.
    if (states.includes('*')) {
      return null;
    }

    // Remove provided states and update timestamp.
    const currentStates = _uniq(entry.states);
    const newStates = _difference(currentStates, states);

    // Passing '~' as a state to remove will keep the entry even if you remove all its states.
    if (newStates.length === 0 && !states.includes('~')) {
      return null;
    }

    // Update states and timestamp if they changed.
    if (newStates.length !== currentStates.length) {
      entry.states = newStates;
      entry.date = Date.now();
    }
  } else if (entry.states.length === 0) {
    // If no states were passed to remove and the entry doesn't currently have any, delete it.
    return null;
  }

  return sanitizeEntry(entry);
}

export function sanitizeEntry(entry?: Partial<JournalEntry>) {
  const newEntry = _pick(entry, ...JournalEntryFields) as JournalEntry;
  const { id, states, unread } = newEntry;
  if (typeof id !== 'string') {
    newEntry.id = _toString(id);
  }
  if (typeof unread !== 'boolean') {
    newEntry.unread = true;
  }
  if (!Array.isArray(states)) {
    newEntry.states = _toArray(states);
  }
  newEntry.states = _uniq(newEntry.states).sort();

  return newEntry;
}
