import type { EntryLocaleContent } from "../types";

/**
 * i18Next `entries:Game01.SECSA02` entry text content
 */
const SECSA02: EntryLocaleContent = {
  alertVMUnreachable: {
    description: "ALERT: Missing VM",
    default: "The Help Desk forwarded a support ticket regarding an unreachable Production VM.",
  },
  supportTicket: {
    description: "VM Support Ticket",
    CH01: "Reporting User: Debra Berger",
    CH02: "Help Desk Agent: Grady Archie",
    CH03: "VM Name: FinanceVM23",
    CH04: "Issue: Connection to FinanceVM23 fails",
    CH05: "Last Used: 4 days ago",
  },
  // lab01
  KQLAuthorization_dEvidence: {
    description: "Authorization_d evidence",
    A: "principalId: 6da49e83b6b141dcb062de23bc88008b",
    B: "roleAssignmentScope: /subscriptions/76c86e96-91d3-4777-a917-d7b06aea69b1/resourceGroups/rgFinance",
    C: "roleDefinitionId: 8e3af657a8ff443ca75c2fe8c4bcb635",
  },
  KQLProperties_dEvidence: {
    description: "Properties_d evidence",
    A: "caller: LeeG@M365x489897.OnMicrosoft.com",
    B: "clientIpAddress: 24.22.48.103",
  },
  KQLQuery: {
    description: "KQL Query",
    DD01: "AzureActivity",
    DD02: '| where Properties contains "FinanaceVM23"',
    DD03: '| where OperationNameValue == "MICROSOFT.COMPUTE/VIRTUALMACHINES/DELETE"',
    DD04: '| where ActivityStatusValue == "Success"',
  },
};

export default SECSA02;
