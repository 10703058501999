import _pick from 'lodash/pick';
import { v4 as uuidv4 } from 'uuid';
import differenceInHours from 'date-fns/differenceInHours';

import { APIManager } from './APIManager';
import { checkEnvVar, devLog, getSlug, getSubdomain } from '../util';
import { clearLocalSession, setLocalSession, tryGetLocalSession } from '../../store/local-session';
import { persistor, store } from '../../store';
import { setGameData, setSessionStartTime } from '../../store/game-slice';
import { setUserValues } from '../../store/user-slice';

import { InitAPIResponse, LocalSessionData, LocalSessionDataSchema, RegisterPlayerParams } from './types';
import type { LoadGateOnLoadCallback } from '../../components/LoadGate/LoadGate';

/**
 * Initialize API config (with env vars and overrides).
 * Throws user-friendly error messages on failure.
 */
const initAPIConfig = async () => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error('API URL Missing');
  }

  const forceSubdomain = checkEnvVar(process.env.REACT_APP_FORCE_DOMAIN);
  const domain = forceSubdomain ? process.env.REACT_APP_FORCE_DOMAIN : getSubdomain();
  try {
    const apiConfig = await APIManager.config({
      url: process.env.REACT_APP_API_URL,
      domain,
    });
    return apiConfig;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error('Error initializing API.');
  }
};

/**
 * Get game data from API (with env vars and overrides)
 * Throws user-friendly error messages on failure.
 */
const getGameData = async () => {
  const forceSlug = checkEnvVar(process.env.REACT_APP_FORCE_SLUG);
  const slug = forceSlug ? process.env.REACT_APP_FORCE_SLUG : getSlug();
  if (slug == null || slug.length === 0) {
    throw new Error('Missing game slug.');
  }
  try {
    const res = await APIManager.getGame({ slug });
    return res.data;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error('Could not get game data.');
  }
};

/**
 * Validate a local session
 */
const validateLocalSession = async (session: LocalSessionData | null) => {
  if (session == null || session.sessionUuid == null) {
    return false;
  }
  try {
    const result = await APIManager.validateSession({ uuid: session.sessionUuid });
    return result.status === 200 && session.sessionUuid === result.data.uuid;
  } catch (e: any) {
    return false;
  }
};

/**
 * Register a new player/session, save to local storage, and start persistence.
 */
export async function registerNewSession(params?: RegisterPlayerParams) {
  clearLocalSession({ persist: true });

  const registerResult = await APIManager.registerPlayer(params ?? { username: uuidv4() });
  const sessionResult = await APIManager.startSession();

  try {
    const sessionData: LocalSessionData = LocalSessionDataSchema.parse({
      id: registerResult.data.id,
      key: registerResult.data.key,
      sessionId: sessionResult.data.session_id,
      sessionUuid: sessionResult.data.uuid,
      sessionStartTime: Date.now(),
    });

    setLocalSession(sessionData);
    persistor.persist();
    return sessionData;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error('Valid player or session data could not be parsed from responses.');
  }
}

/**
 * Initialize API and get game data
 */
export const initAPIAndGetData = async () => {
  const response: InitAPIResponse = {
    status: 'pending',
    messages: [],
    session: null,
    sessionIsValid: false,
    apiConfig: null,
    gameData: null,
  };

  // Initial API configuration (no session)
  try {
    response.apiConfig = await initAPIConfig();
  } catch (e: any) {
    response.messages.push(String(e));
    response.status = 'error';
    return response;
  }

  // Initial load game data.
  try {
    response.gameData = await getGameData();
  } catch (e: any) {
    response.messages.push(String(e));
    response.status = 'error';
    return response;
  }

  // Check if local session exists and is under 24 hours old.
  const localSession = tryGetLocalSession();
  if (localSession == null || differenceInHours(Date.now(), localSession.sessionStartTime) >= 24) {
    response.status = 'done';
    return response;
  }

  try {
    // Load local session info into APIManager
    response.session = localSession;
    response.apiConfig = await APIManager.config(_pick(response.session, 'id', 'key'));
    response.sessionIsValid = await validateLocalSession(response.session);

    // If session isn't valid, clear userId and API key from APIManager.
    if (!response.sessionIsValid) {
      response.apiConfig = await APIManager.config({ id: undefined, key: undefined });
    }

    response.status = 'done';
    return response;
  } catch (e: any) {
    response.messages.push(String(e));
    response.status = 'error';
    return response;
  }
};

/**
 * Initialize API, get game data, and try to validate/reload an existing session.
 * Designed to be passed into a `LoadGate.onLoad` prop.
 */
export const initAPIAndTryReload: LoadGateOnLoadCallback = async ({ done, setMessages }) => {
  // Initialize API, load game and attempt to validate session.
  const data = await initAPIAndGetData();

  // Update messages in LoadGate, if there are any (like errors).
  if (data.messages.length > 0) {
    setMessages(data.messages);
  }

  // Bail out on any fatal error.
  if (data.status === 'error') {
    return;
  }

  // Runs after loading is done, triggers transition effect.
  const postLoad = () => {
    devLog('postLoad');
    // ALWAYS re-load gameData into Redux
    if (data.gameData != null) {
      store.dispatch(setGameData(data.gameData));
    }
    done();
  };

  // IF saved session is VALID:
  //   - Start persistor.
  //   - Refresh certain data in Redux store when persistor is loaded.
  //   - Call postLoad().
  if (data.sessionIsValid && data.session != null) {
    devLog('initAPIAndTryReload - reloading session');
    const { sessionStartTime, sessionUuid } = data.session;
    // Handle persistor load event.
    const handlePersistor = () => {
      if (persistor.getState().bootstrapped) {
        // Run only once.
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        unsubscribe();

        // Reset sessionStartTime and sessionUuid in redux based on saved session.
        if (sessionStartTime != null) {
          store.dispatch(setSessionStartTime(sessionStartTime));
        }
        if (sessionUuid != null) {
          store.dispatch(
            setUserValues({
              sessionUuid,
            }),
          );
        }

        devLog('initAPIAndTryReload - session reloaded');
        // Finish loading when persistor is set up.
        postLoad();
      }
    };
    const unsubscribe = persistor.subscribe(handlePersistor);
    // Start persistor and check immediately.
    persistor.persist();
    handlePersistor();

    // End execution. State should be restored.
    return;
  }

  // IF saved session is INVALID:
  //   - Ensure persistor is paused.
  //   - Clear saved session and data.
  //   - Call postLoad().
  // (On registration, persistence will be re-enabled.)
  devLog('initAPIAndTryReload - new session');
  clearLocalSession({ persist: true });
  postLoad();
};
