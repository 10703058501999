/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react';
import { RootState } from '../../store';
import { ENTRY_SLIDE_TIME } from '../../lib/anim-timings';
import { filterColors } from '../../lib/journal-filter';

const JournalFilterHeader: React.FunctionComponent = () => {
  const activeJournalFilter = useSelector((state: RootState) => state.game.activeJournalFilter);
  function setBackgroundColor() {
    const filter = filterColors.find((e) => e.type === activeJournalFilter);
    if (filter) {
      return filter.color;
    }
    throw new Error('setBackgroundColor called with invalid type');
  }
  const { t } = useTranslation('journalFilter');
  return (
    <Text
      variant="xLarge"
      block
      styles={{
        root: {
          backgroundColor: setBackgroundColor(),
          transition: `background-color ${ENTRY_SLIDE_TIME}ms`,
          color: 'white',
          margin: '16px -24px 0px',
          padding: '4px 24px',
        },
      }}
    >
      {t(`header.${activeJournalFilter}`)}
    </Text>
  );
};
export default JournalFilterHeader;
