import React, { useMemo } from 'react';
import _merge from 'lodash/merge';
import {
  FontSizes,
  FontWeights,
  getTheme,
  IButtonStyles,
  IconButton,
  IModalProps,
  IModalStyles,
  IStackTokens,
  Modal,
  Persona,
  PersonaSize,
  Stack,
  Text,
} from '@fluentui/react';

const theme = getTheme();

/**
 * Static config. Maybe make configurable at instance level in future.
 */
const config = {
  closeButtonColor: theme.palette.neutralPrimary,
  closeButtonIconName: 'Cancel',
  closeButtonInset: 8,
  contentChildrenGap: 16,
  maxWidth: 552,
  outerPadding: '24px 40px',
  personaSize: PersonaSize.size56,
  titleTextStyles: { root: { fontSize: FontSizes.xLarge, fontWeight: FontWeights.semibold } },
};

const modalCloseButtonIconProps = { iconName: config.closeButtonIconName };

const modalCloseButtonStyles: IButtonStyles = {
  root: {
    color: config.closeButtonColor,
    position: 'absolute',
    top: config.closeButtonInset,
    right: config.closeButtonInset,
  },
};

const modalHStackTokens: IStackTokens = { childrenGap: config.contentChildrenGap, padding: config.outerPadding };

const modalVStackTokens: IStackTokens = { childrenGap: config.contentChildrenGap };

const modalStyles: Partial<IModalStyles> = {
  main: {
    maxWidth: config.maxWidth,
    display: 'flex',
  },
  scrollableContent: {
    flexBasis: '100%',
  },
};

const modalBodyTokens: IStackTokens = {
  childrenGap: config.contentChildrenGap,
};

const modalFooterTokens: IStackTokens = {
  childrenGap: config.contentChildrenGap,
};

export interface ResultModalProps {
  title?: string | React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  isOpen?: boolean;
  hidePersona?: boolean;
  onDismiss?: IModalProps['onDismiss'];
  onDismissed?: IModalProps['onDismissed'];
  styles?: Partial<IModalStyles>;
}

/**
 * Render string title as <Text> component.
 */
const renderTitle = (title?: string | React.ReactNode): React.ReactNode => {
  if (typeof title === 'string') {
    return <Text styles={config.titleTextStyles}>{title}</Text>;
  }
  return title;
};

/**
 * Render body prop as stack.
 */
const renderBody = (body?: React.ReactNode): React.ReactNode => {
  if (body == null) {
    return null;
  }

  return <Stack tokens={modalBodyTokens}>{body}</Stack>;
};

/**
 * Render footer prop as stack.
 */
const renderFooter = (footer?: React.ReactNode): React.ReactNode => {
  if (footer == null) {
    return null;
  }

  return (
    <Stack
      className="result-modal-footer"
      horizontal
      horizontalAlign="end"
      tokens={modalFooterTokens}
      verticalAlign="end"
    >
      {footer}
    </Stack>
  );
};

/**
 * Render persona on side.
 */
const renderPersona = (hidePersona?: boolean): React.ReactNode => {
  if (hidePersona) {
    return null;
  }
  return <Persona size={config.personaSize} hidePersonaDetails />;
};

/**
 * ResultModal component.
 */
const ResultModal: React.FC<ResultModalProps> = ({
  title,
  body,
  footer,
  isOpen,
  hidePersona,
  onDismiss,
  onDismissed,
  children,
  styles,
}) => {
  // Recursive merge base styles and prop styles into new memoized object
  const computedModalStyles = useMemo(() => _merge({}, modalStyles, styles), [styles]);

  return (
    <Modal isBlocking isOpen={isOpen} onDismiss={onDismiss} onDismissed={onDismissed} styles={computedModalStyles}>
      <IconButton styles={modalCloseButtonStyles} iconProps={modalCloseButtonIconProps} onClick={onDismiss as any} />
      <Stack horizontal tokens={modalHStackTokens} verticalFill>
        <Stack.Item>{renderPersona(hidePersona)}</Stack.Item>
        <Stack grow={1} tokens={modalVStackTokens} verticalAlign="space-between" verticalFill>
          <Stack.Item className="result-modal-title" align="start">
            {renderTitle(title) ?? ''}
          </Stack.Item>
          <Stack.Item className="result-modal-body" grow={1}>
            {renderBody(body)}
            {children}
          </Stack.Item>
          {renderFooter(footer)}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ResultModal;
