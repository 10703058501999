import React from 'react';
import { motion } from 'framer-motion';
import { EntryUnreadNotificationProps } from '../../lib/entries';
import { NOTIFICATION_TRANSITION_TIME } from '../../lib/anim-timings';

const variants = {
  read: { opacity: 0, scale: 0 },
  unread: { opacity: 1, scale: 1 },
};

const EntryUnread: React.FunctionComponent<EntryUnreadNotificationProps> = ({ unread }) => {
  return (
    <motion.div
      variants={variants}
      initial={{ opacity: 0, scale: 0 }}
      animate={unread ? 'unread' : 'read'}
      style={{
        width: 12,
        height: 12,
        left: 8,
        top: 12,
        transformOrigin: 'center',
        position: 'absolute',
        backgroundColor: '#ef476f',
        borderRadius: 120,
      }}
      transition={{ duration: NOTIFICATION_TRANSITION_TIME / 1000 }}
    />
  );
};
export default EntryUnread;
