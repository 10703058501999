import React, { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { DefaultButton, Stack, Text } from '@fluentui/react';

import { useTaskMachine } from '../../../lib/hooks/useTaskMachine';
import { createIDSA03 } from './machine';
import ResultModal from '../../../components/ResultModal/ResultModal';
import ScreenModal, { ScreenModalSidebarProps } from '../../../components/ScreenModal/ScreenModal';
import RTrans from '../../../components/RTrans/RTrans';
import DummyLabFrame from '../../../components/DummyLabFrame/DummyLabFrame';
import { dummyLabModalStyles, textCenterStyles } from '../../task-common';

const baseNS = 'Game01.IDSA03';
const taskKey = 'Game01.IDSA03';

const labURI = './images/Game01/IDSA03-lab01.png';

/**
 * Game01.IDSA03 Component
 */
const IDSA03: React.FC = () => {
  const { t } = useTranslation(baseNS);
  const history = useHistory();

  // Setup task machine
  const [state, send] = useTaskMachine(createIDSA03, {
    taskKey,
  });

  // Move along to the next task if state is done.
  useEffect(() => {
    if (state.done) {
      history.push('/tasks/Game01/IR01');
    }
  }, [state, history]);

  // Memoize IR manager persona
  const IRManager: ScreenModalSidebarProps = useMemo(
    () => ({
      title: t('IRManager.name'),
      persona: {
        imageInitials: t('IRManager.initials'),
      },
    }),
    [t],
  );

  const cbSendClickB01 = useCallback(() => send('CLICK.B01'), [send]);
  const cbSendClickB02 = useCallback(() => send('CLICK.B02'), [send]);
  const cbSendClickB03 = useCallback(() => send('CLICK.B03'), [send]);
  const cbSendClickB04 = useCallback(() => send('CLICK.B04'), [send]);
  const cbSendClickB05 = useCallback(() => send('CLICK.B05'), [send]);

  return (
    <>
      {/**
       * intro01 / intro01.intro01a
       */}
      {state.matches('intro01') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('intro01a.title')}
          body={
            <Text>
              <RTrans t={t} i18nKey="intro01a.body" />
            </Text>
          }
          footer={
            <>
              <DefaultButton onClick={cbSendClickB01}>{t('intro01a.B01')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB02}>{t('intro01a.B02')}</DefaultButton>
              <DefaultButton onClick={cbSendClickB03}>{t('intro01a.B03')}</DefaultButton>
            </>
          }
        />
      )}

      {/**
       * intro01.intro01b
       */}
      <ResultModal
        isOpen={state.matches('intro01.intro01b')}
        title={t('intro01b.title')}
        body={<Text>{t('intro01b.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB04}>{t('intro01b.B04')}</DefaultButton>}
        onDismiss={cbSendClickB04}
      />

      {/**
       * intro01.intro01c
       */}
      <ResultModal
        isOpen={state.matches('intro01.intro01c')}
        title={t('intro01c.title')}
        body={<Text>{t('intro01c.body')}</Text>}
        footer={<DefaultButton onClick={cbSendClickB05}>{t('intro01c.B05')}</DefaultButton>}
        onDismiss={cbSendClickB05}
      />

      {/**
       * lab01
       */}
      {state.matches('lab01') && (
        <DummyLabFrame
          src={labURI}
          title={t('lab01.title')}
          sectionIndex={state.context.lab01.sectionIndex}
          screenIndex={state.context.lab01.screenIndex}
          // onMessage={labMessageHandler}
        />
      )}
      <ResultModal isOpen={state.matches('lab01')} hidePersona onDismiss={cbSendClickB01} styles={dummyLabModalStyles}>
        <Stack grow={1} horizontalAlign="center" verticalAlign="space-around" verticalFill>
          <Stack.Item>
            <Text block variant="xxLarge" styles={textCenterStyles}>
              <Trans t={t} i18nKey="lab01.comingSoon" />
            </Text>
          </Stack.Item>
          <Stack.Item>
            <DefaultButton onClick={cbSendClickB01}>{t('lab01.B01')}</DefaultButton>
          </Stack.Item>
        </Stack>
      </ResultModal>

      {/**
       * end01
       */}
      {state.matches('end01') && (
        <ScreenModal
          sidebar={IRManager}
          title={t('end01.title')}
          body={<Text>{t('end01.body')}</Text>}
          footer={<DefaultButton onClick={cbSendClickB01}>{t('end01.B01')}</DefaultButton>}
        />
      )}
    </>
  );
};

export default IDSA03;
