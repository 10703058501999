import GA01 from "./GA01";
import IDSA01 from "./IDSA01";
import IDSA03 from "./IDSA03";
import SECSA02 from "./SECSA02";
import SECSA03 from "./SECSA03";

/**
 * i18Next `entries:Game01.*` collection
 */
export default {
  Game01: {
    GA01,
    IDSA01,
    IDSA03,
    SECSA02,
    SECSA03,
  },
};
