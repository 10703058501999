/* eslint-disable no-restricted-globals */
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import _forOwn from 'lodash/forOwn';
import _isObject from 'lodash/isObject';

export const MAX_DATE = new Date(8640000000000000);

/**
 * Whether the application is running on the local development server (`npm run start`),
 * rather than from a build (`npm run build`).
 */
export const isLocalDev = process.env.NODE_ENV === 'development';

/**
 * Whether the application is running in a development environment.
 */
export const isDev = process.env.REACT_APP_ENV === 'development';

export function deepFreeze<T extends object>(obj: T): Readonly<T> {
  return Object.freeze(
    _forOwn(obj, (value) => {
      if (_isObject(value)) {
        deepFreeze(value);
      }
    }),
  );
}

export function isIterable(obj: any): boolean {
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

const numericRegexp = /^\s*[+-]?(?:[0-9]+(?:.[0-9]+)?(?:[eE][+-]?[0-9]+)?|Infinity)\s*$/;

/**
 * Test that the input is number that isn't NaN, or a string containing a
 * basic, parsable representation of a non-NaN number.
 */
export function isNumeric(val?: any): boolean {
  return (
    (typeof val === 'number' && !isNaN(val)) ||
    (typeof val === 'string' && numericRegexp.test(val) && !isNaN(parseFloat(val)))
  );
}

export function safeParseISO(date: string, fallback: Date = MAX_DATE): Date {
  const dateObj = parseISO(date);
  if (isValid(dateObj)) {
    return dateObj;
  }
  return fallback;
}

export const nameof = <T extends {}>(name: keyof T) => name;

export function checkEnvVar(v: string | undefined) {
  return v && v !== 'false';
}

export function getSlug() {
  if (typeof location === 'undefined') {
    return undefined;
  }
  const split = location.pathname.split('/');
  return split[split.length - 1];
}

export function getSubdomain() {
  return typeof location !== 'undefined' ? location.hostname.split('.')[0] : undefined;
}

export function getParams() {
  if (typeof location === 'undefined') {
    return {};
  }

  const url = location.href;
  const params = {};
  const parser = document.createElement('a');
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    (params as any)[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

export type EnumBase = Record<string, string | number | undefined>;
export type EnumValue<T extends EnumBase> = T extends Record<keyof T, infer X> ? X : never;

/**
 * Validate or lookup a value for an enum (with fallback).
 * @param {TEnum} enumObj - An Enum object.
 * @param {EnumValue<TEnum> | string | number | null | undefined} value - An enum value or name to lookup or validate.
 * @param {EnumValue<TEnum>} defaultValue - The value to return if the lookup fails.
 *
 * @return {EnumValue<TEnum>} A valid enum value.
 */
export function getEnumValue<TEnum extends EnumBase>(
  enumObject: TEnum,
  value: EnumValue<TEnum> | string | number | null | undefined,
  defaultValue: EnumValue<TEnum>,
): EnumValue<TEnum> {
  // Enum members can't have numeric names, so if the input is numeric we are
  // validating a value.
  if (isNumeric(value)) {
    // Enum members with numeric values have reverse mappings, so we can look up
    // the value as a key on the object. (This will also stringify if necessary.)
    const lookup = enumObject[value as string];
    if (lookup == null) {
      return defaultValue;
    }

    // If lookup was successful, we now have the name and need to reverse to
    // end up with the canonical value.
    return enumObject[lookup] as EnumValue<TEnum>;
  }

  // Enum members with string values only map name to value, so we can match
  // the input against keys & values and return the corresponding value.
  return (
    (Object.entries(enumObject).find(
      ([enumKey, enumVal]) => enumKey === value || enumVal === value,
    )?.[1] as EnumValue<TEnum>) ?? defaultValue
  );
}

/**
 * Console.log gated behind isDev
 */
export const devLog = (...data: any[]): void => {
  if (isDev) {
    // eslint-disable-next-line no-console
    console.log(...data);
  }
};
