/* eslint-disable no-param-reassign */
import { PersonaPresence } from '@fluentui/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRole } from '../lib/roles';

import type { RootState } from '.';

export interface UserStateInterface {
  role: UserRole;
  username: string;
  presence: PersonaPresence;
  sessionUuid: string;
}

// the initial user state
const initialState: UserStateInterface = {
  role: UserRole.None,
  username: '',
  presence: PersonaPresence.none,
  sessionUuid: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserValues: (state, { payload }: PayloadAction<Partial<UserStateInterface>>) => Object.assign(state, payload),
  },
});

export const selectUserIsRegistered = ({ user }: RootState) => user.sessionUuid.length !== 0;

export const { setUserValues } = userSlice.actions;

export default userSlice.reducer;
