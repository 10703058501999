import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Persona, PersonaSize, TooltipHost, DirectionalHint, PersonaInitialsColor } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { RootState } from '../../store';
import { roleColors } from '../../lib/roles';
import { selectUserIsRegistered } from '../../store/user-slice';

const NavBarPersona: React.FunctionComponent = () => {
  const { t } = useTranslation('roles');
  const username = useSelector((state: RootState) => state.user.username);
  const role = useSelector((state: RootState) => state.user.role);
  const presence = useSelector((state: RootState) => state.user.presence);
  const isRegistered = useSelector(selectUserIsRegistered);
  const initials = username
    .split(/[\s-]+/)
    .map((s) => s[0])
    .join('')
    .toLocaleUpperCase();
  const tooltipId = useId('navbarPersonaToolip');
  const tooltipContent = `${username} - ${t(role)}`;

  const renderProps = {
    tooltipContent: isRegistered ? tooltipContent : undefined,
    initialsColor: isRegistered ? (roleColors as any)[role] : PersonaInitialsColor.coolGray,
    presence: isRegistered ? presence : undefined,
    initials: isRegistered ? initials : undefined,
  };

  return (
    <TooltipHost content={renderProps.tooltipContent} id={tooltipId} directionalHint={DirectionalHint.leftCenter}>
      <Persona
        initialsColor={renderProps.initialsColor}
        imageInitials={renderProps.initials}
        styles={{
          root: {
            'display': 'block',
            'margin': '12px 0',
            '.ms-Persona-imageArea': {
              margin: 'auto',
            },
          },
        }}
        size={PersonaSize.size56}
        presence={renderProps.presence}
        imageAlt={renderProps.tooltipContent}
      />
    </TooltipHost>
  );
};

export default NavBarPersona;
