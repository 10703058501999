/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameData } from '../lib/api/types';

export interface GameStateInterface {
  lastActivePanel: string;
  activePanel: string;
  activeCallout: string[];
  activeJournalFilter: string;
  journalEntriesSeen: string[];
  policyEntriesSeen: string[];
  lastActiveJournalFilter: string;
  gameData: GameData | null;
  sessionStartTime: number;
  score: {
    group: number;
    identity: number;
    security: number;
    IR: number;
  };
  usedScoreIds: Record<string, boolean>;
  taskHistory: string[];
}

export interface ScoreUpdate {
  type: keyof GameStateInterface['score'];
  amount: number;
  id?: string;
  force?: boolean;
}

// the initial game state
const initialState: GameStateInterface = {
  lastActivePanel: '',
  activePanel: '',
  activeCallout: [],
  journalEntriesSeen: [],
  policyEntriesSeen: [],
  lastActiveJournalFilter: 'all',
  activeJournalFilter: 'all',
  gameData: null,
  sessionStartTime: 0,
  score: {
    group: 0,
    identity: 0,
    security: 0,
    IR: 0,
  },
  usedScoreIds: {},
  taskHistory: [],
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setActivePanel: (state, { payload }) => {
      const isOpen = state.activePanel === payload;
      if (!isOpen && payload === 'journal') {
        state.journalEntriesSeen = [];
      }
      state.lastActivePanel = state.activePanel;
      state.activePanel = isOpen ? '' : payload;
    },
    setActiveCallout: (state, { payload }) => {
      state.activeCallout = payload;
    },
    setCurrentTask: (state, { payload }: PayloadAction<string | null | undefined>) => {
      if (payload == null || payload === state.taskHistory[state.taskHistory.length - 1]) {
        return;
      }
      state.taskHistory.push(payload);
    },
    setJournalEntriesSeen: (state, { payload }) => {
      const payloadIsString = typeof payload === 'string';
      const entries = payloadIsString ? [payload] : payload;
      entries.forEach((e: string) => {
        if (state.journalEntriesSeen.indexOf(e) === -1) {
          state.journalEntriesSeen.push(e);
        }
      });
    },
    setPolicyEntriesSeen: (state, { payload }) => {
      const payloadIsString = typeof payload === 'string';
      const entries = payloadIsString ? [payload] : payload;
      entries.forEach((e: string) => {
        if (state.policyEntriesSeen.indexOf(e) === -1) {
          state.policyEntriesSeen.push(e);
        }
      });
    },
    setActiveJournalFilter: (state, { payload }) => {
      state.lastActiveJournalFilter = state.activeJournalFilter;
      state.activeJournalFilter = payload;
    },
    setGameData: (state, { payload }) => {
      state.gameData = payload;
    },
    setSessionStartTime: (state, { payload }) => {
      state.sessionStartTime = payload;
    },
    addScore: (state, { payload }: PayloadAction<ScoreUpdate>) => {
      const { type, amount, id, force } = payload;
      const oldScore = state.score[type];
      const newScore = Math.max(0, oldScore + amount);

      if (id != null) {
        if (state.usedScoreIds[id] && !force) {
          // eslint-disable-next-line no-console
          console.log('addScore-aborted!', { type, oldScore, amount, newScore, id, force });
          return;
        }
        state.usedScoreIds[id] = true;
      }

      // eslint-disable-next-line no-console
      console.log('addScore', { type, oldScore, amount, newScore, id, force });
      state.score[type] = newScore;
    },
  },
});

export const {
  setActivePanel,
  setActiveJournalFilter,
  setActiveCallout,
  setCurrentTask,
  setJournalEntriesSeen,
  setPolicyEntriesSeen,
  setGameData,
  setSessionStartTime,
  addScore,
} = gameSlice.actions;

export default gameSlice.reducer;
