import { EventObject } from 'xstate';
import _isMatch from 'lodash/isMatch';

import type { GuardMetaExt, TaskContext, TaskEvent, TaskOptions, TSQueryOptions } from './task-types';
import { EntryID, JournalEntryQueryOptions, StateList } from '../lib/journal';
import { addScore as rdxAddScore, ScoreUpdate } from '../store/game-slice';
import { actions as JournalRdxActions, queries as JournalRdxQueries } from '../store/journal-slice';
import { actions as PoliciesRdxActions, queries as PoliciesRdxQueries } from '../store/policies-slice';

import { store } from '../store';

import { entryData } from '../static/entry-data';

/**
 * XState Action creator for 'addEntry' action.
 */
export const addEntry = (id: EntryID, states?: StateList) => {
  const entry = entryData?.[id];
  if (entry == null) {
    // eslint-disable-next-line no-console
    console.warn('addEntry could not find entry with id: ', id);
    return { type: '' };
  }

  return {
    type: entry.category !== 'policy' ? 'addJournalEntry' : 'addPolicyEntry',
    id,
    states,
  };
};

/**
 * XState Action creator for 'addJournalEntry' action.
 */
export const addJournalEntry = (id: EntryID, states?: StateList) => {
  const entry = entryData?.[id];
  if (entry == null) {
    // eslint-disable-next-line no-console
    console.warn('addJournalEntry could not find entry id: ', id);
  } else if (entry.category === 'policy') {
    // eslint-disable-next-line no-console
    console.warn('addJournalEntry adding policy entry id: ', id);
  }

  return {
    type: 'addJournalEntry',
    id,
    states,
  };
};

/**
 * XState Action creator for 'addPolicyEntry' action.
 */
export const addPolicyEntry = (id: EntryID, states?: StateList) => {
  const entry = entryData?.[id];
  if (entry == null) {
    // eslint-disable-next-line no-console
    console.warn('addPolicyEntry could not find entry id: ', id);
  } else if (entry.category !== 'policy') {
    // eslint-disable-next-line no-console
    console.warn('addPolicyEntry adding journal entry id: ', id);
  }

  return {
    type: 'addPolicyEntry',
    id,
    states,
  };
};

/**
 * XState Action creator for 'removeEntry' action.
 */
export const removeEntry = (id: EntryID, states?: StateList) => {
  const entry = entryData?.[id];
  if (entry == null) {
    // eslint-disable-next-line no-console
    console.warn('removeEntry could not find entry with id: ', id);
    return { type: '' };
  }

  return {
    type: entry.category !== 'policy' ? 'removeJournalEntry' : 'removePolicyEntry',
    id,
    states,
  };
};

/**
 * XState Action creator for 'removeJournalEntry' action.
 */
export const removeJournalEntry = (id: EntryID, states?: StateList) => {
  const entry = entryData?.[id];
  if (entry == null) {
    // eslint-disable-next-line no-console
    console.warn('removeJournalEntry could not find entry id: ', id);
  } else if (entry.category === 'policy') {
    // eslint-disable-next-line no-console
    console.warn('removeJournalEntry adding policy entry id: ', id);
  }

  return {
    type: 'removeJournalEntry',
    id,
    states,
  };
};

/**
 * XState Action creator for 'removePolicyEntry' action.
 */
export const removePolicyEntry = (id: EntryID, states?: StateList) => {
  const entry = entryData?.[id];
  if (entry == null) {
    // eslint-disable-next-line no-console
    console.warn('removePolicyEntry could not find entry id: ', id);
  } else if (entry.category !== 'policy') {
    // eslint-disable-next-line no-console
    console.warn('removePolicyEntry adding journal entry id: ', id);
  }

  return {
    type: 'removePolicyEntry',
    id,
    states,
  };
};

/**
 * XState Action creator for 'unlockAssessment' action.
 */
export const unlockAssessment = (id: string) => ({
  type: 'unlockAssessment',
  id,
});

/**
 * XState Action creator for 'unlockTask' action.
 */
export const unlockTask = (id: string) => ({
  type: 'unlockTask',
  id,
});

/**
 * XState Action creator for 'addScore' action.
 */
export const addScore = (payload: ScoreUpdate) => ({
  type: 'addScore',
  payload,
});

/**
 * Grouped export for XState action creators
 */
export const actions = Object.freeze({
  addEntry,
  addJournalEntry,
  addPolicyEntry,
  removeEntry,
  removeJournalEntry,
  removePolicyEntry,
  unlockAssessment,
  unlockTask,
  addScore,
});

/**
 * XState Guard creator for 'hasEntry' condition
 */
export const hasEntry = (id: string, options: JournalEntryQueryOptions) => {
  const entry = entryData?.[id];
  if (entry == null) {
    // eslint-disable-next-line no-console
    console.warn('hasEntry could not find entry with id: ', id);
    return { type: '' };
  }

  return {
    type: entry.category !== 'policy' ? 'hasJournalEntry' : 'hasPolicyEntry',
    id,
    ...options,
  };
};

/**
 * XState Guard creator for 'hasJournalEntry' condition
 */
export const hasJournalEntry = (id: string, options: JournalEntryQueryOptions) => {
  const entry = entryData?.[id];
  if (entry == null) {
    // eslint-disable-next-line no-console
    console.warn('hasJournalEntry could not find entry id: ', id);
  } else if (entry.category === 'policy') {
    // eslint-disable-next-line no-console
    console.warn('hasJournalEntry checking policy entry id: ', id);
  }

  return {
    type: 'hasJournalEntry',
    id,
    ...options,
  };
};

/**
 * XState Guard creator for 'hasJournalEntry' condition
 */
export const hasPolicyEntry = (id: string, options: JournalEntryQueryOptions) => {
  const entry = entryData?.[id];
  if (entry == null) {
    // eslint-disable-next-line no-console
    console.warn('hasPolicyEntry could not find entry id: ', id);
  } else if (entry.category !== 'policy') {
    // eslint-disable-next-line no-console
    console.warn('hasPolicyEntry checking journal entry id: ', id);
  }

  return {
    type: 'hasPolicyEntry',
    id,
    ...options,
  };
};

/**
 * XState Guard create for 'matchesTS' condition
 */
export const matchesTS = (options: TSQueryOptions) => ({
  type: 'matchesTS',
  ...options,
});

/**
 * Grouped export for XState Guard creators
 */
export const guards = Object.freeze({
  hasEntry,
  hasJournalEntry,
  hasPolicyEntry,
  matchesTS,
});

/**
 * Default MachineOptions for Tasks.
 */
export const getDefaultOptions = <
  TContext extends TaskContext = TaskContext,
  TEvent extends EventObject = TaskEvent,
>(): TaskOptions<TContext, TEvent> => ({
  // Action implementations
  actions: {
    '': () => false, // no-op
    'addJournalEntry': (context, event, meta) => {
      const action = meta.action as ReturnType<typeof addJournalEntry>;
      if (!JournalRdxQueries.hasJournalEntry(store.getState().journal, { id: action.id, every: action.states })) {
        const reduxAddAction = JournalRdxActions.addJournalEntry({ id: action.id, states: action.states });
        store.dispatch(reduxAddAction);
        // eslint-disable-next-line no-console
        console.log('action-addJournalEntry', context, event, meta);
      }
    },
    'addPolicyEntry': (context, event, meta) => {
      const action = meta.action as ReturnType<typeof addPolicyEntry>;
      if (!PoliciesRdxQueries.hasPolicyEntry(store.getState().policies, { id: action.id, every: action.states })) {
        const reduxAddAction = PoliciesRdxActions.addPolicyEntry({ id: action.id, states: action.states });
        store.dispatch(reduxAddAction);
        // eslint-disable-next-line no-console
        console.log('action-addPolicyEntry', context, event, meta);
      }
    },
    'removeJournalEntry': (context, event, meta) => {
      const action = meta.action as ReturnType<typeof removeJournalEntry>;
      if (JournalRdxQueries.hasJournalEntry(store.getState().journal, { id: action.id })) {
        const reduxAddAction = JournalRdxActions.removeJournalEntry({ id: action.id, states: action.states });
        store.dispatch(reduxAddAction);
        // eslint-disable-next-line no-console
        console.log('action-removeJournalEntry', context, event, meta);
      }
    },
    'removePolicyEntry': (context, event, meta) => {
      const action = meta.action as ReturnType<typeof removePolicyEntry>;
      if (PoliciesRdxQueries.hasPolicyEntry(store.getState().policies, { id: action.id })) {
        const reduxAddAction = PoliciesRdxActions.removePolicyEntry({ id: action.id, states: action.states });
        store.dispatch(reduxAddAction);
        // eslint-disable-next-line no-console
        console.log('action-addPolicyEntry', context, event, meta);
      }
    },
    'unlockAssessment': (context, event, meta) => {
      // eslint-disable-next-line no-console
      console.log('unlockAssessment', context, event, meta);
    },
    'unlockTask': (context, event, meta) => {
      // eslint-disable-next-line no-console
      console.log('unlockTask', context, event, meta);
    },
    'addScore': (context, event, meta) => {
      const action = meta.action as ReturnType<typeof addScore>;
      const reduxAddScoreAction = rdxAddScore(action.payload);
      store.dispatch(reduxAddScoreAction);
      // eslint-disable-next-line no-console
      console.log('action-addScore', context, event, meta);
    },
  },
  // Guard implementations
  guards: {
    '': () => false, // null guard; won't pass.
    'hasJournalEntry': (context, event, meta: GuardMetaExt<typeof context, typeof event>) => {
      const cond = meta.cond as ReturnType<typeof hasJournalEntry>;
      // eslint-disable-next-line no-console
      console.log('guard-hasJournalEntry', context, event, meta);
      return JournalRdxQueries.hasJournalEntry(store.getState().journal, cond);
    },
    'hasPolicyEntry': (context, event, meta: GuardMetaExt<typeof context, typeof event>) => {
      const cond = meta.cond as ReturnType<typeof hasPolicyEntry>;
      // eslint-disable-next-line no-console
      console.log('guard-hasPolicyEntry', context, event, meta);
      return PoliciesRdxQueries.hasPolicyEntry(store.getState().journal, cond);
    },
    'matchesTS': (context, event, meta: GuardMetaExt<typeof context, typeof event>) => {
      const cond = meta.cond as ReturnType<typeof matchesTS>;
      const { type, ...query } = cond;
      // eslint-disable-next-line no-console
      console.log('guard-matchesTS', context, event, meta);
      return _isMatch(event, query);
    },
  },
});
