/**
 * i18next NS: `Game01.Ending`
 */
export default {
  title: "The End",
  incidentID: "<strong>Incident ID:</strong> 55555",
  date: "<strong>Date:</strong> {{date}}",
  idName: "<strong>Identity Administrator:</strong> {{name}}",
  secName: "<strong>Security Operations Analyst:</strong> {{name}}",
  compName: "<strong>Compliance Administrator:</strong> {{name}}",
  playerScoreHeading: "Player Score:",
  IRScoreHeading: "Incident Report Score:",
  totalPlayerScoreHeading: "Total Player Score:",
  playerRankHeading: "Player Rank:",
  teamScoreHeading: "Team Score:",
  teamScore: "Not available",
  totalTeamScoreHeading: "Total Team Score:",
  totalTeamScore: "Not available",
  teamRankHeading: "Team Rank:",
  teamRank: "Not available",
  scoreOutOf: "{{score}} / {{max}}",
  nameUnavailable: "Unavailable",
  toSurvey: 'Continue to the Survey',
};
