import React from 'react';
import { Text, FontWeights, DefaultButton, Stack } from '@fluentui/react';
import RTrans from '../../../../components/RTrans/RTrans';
import ScreenModal from '../../../../components/ScreenModal/ScreenModal';
import { GA01ComponentProps } from '../interface';

const WhatHappened: React.FunctionComponent<GA01ComponentProps> = ({ t, onClick = () => { } }) => {
  return (
    <ScreenModal
      title={
        <Text variant="xxLarge" styles={{ root: { fontWeight: FontWeights.bold } }}>
          {t('whatHappened.header')}
        </Text>
      }
      body={
        <Stack tokens={{ childrenGap: 24 }}>
          <Stack.Item>
            <Text variant="large">
              <ul>
                {[...Array(4)].map((v, i) => (
                  <li key={`wh_ul_li_${i}`} style={{ marginBottom: 16 }}>
                    {t(`whatHappened.point${i + 1}`)}
                  </li>
                ))}
              </ul>
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Text variant="large">
              <RTrans t={t} i18nKey="whatHappened.body" />
            </Text>
          </Stack.Item>
        </Stack>

      }
      footer={<DefaultButton onClick={() => onClick()}>{t('buttons.next')}</DefaultButton>}
    />
  );
};

export default WhatHappened;
