import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  JournalEntry,
  JournalEntryQuery,
  JournalEntryQueryOptions,
  JournalEntryUpdate,
  matchesEntry,
  removeStates,
  updateEntry,
} from '../lib/journal';

/**
 * Entity adapter for Journal entries.
 */
export const journalAdapter = createEntityAdapter<JournalEntry>({
  // Sort journal entries by date (ascending).
  sortComparer: (a, b) => a.date - b.date,
});

/**
 * Selectors for journalAdapter.
 */
export const journalSelectors = journalAdapter.getSelectors();

/**
 * Journal Slice definition
 */
export const journalSlice = createSlice({
  name: 'journal',
  initialState: journalAdapter.getInitialState(),
  reducers: {
    addJournalEntry: (state, { payload }: PayloadAction<JournalEntryUpdate>) => {
      const entity = journalSelectors.selectById(state, payload.id);
      const newEntity = updateEntry(entity, payload);
      return journalAdapter.upsertOne(state, newEntity);
    },
    removeJournalEntry: (state, { payload }: PayloadAction<JournalEntryUpdate>) => {
      const entity = journalSelectors.selectById(state, payload.id);
      const newEntity = removeStates(entity, payload.states);
      if (newEntity == null) {
        return journalAdapter.removeOne(state, payload.id);
      }
      return journalAdapter.upsertOne(state, newEntity);
    },
    setJournalEntryUnread: (state, { payload }) => {
      const { ids, unread } = payload;
      const updates = ids.map((id: string) => ({
        id,
        changes: {
          unread,
        },
      }));
      return journalAdapter.updateMany(state, updates);
    },
  },
});

/**
 * Grouped export for journalSlice actions.
 */
export const actions = journalSlice.actions;

/**
 * Individual exports for journalSlice actions.
 */
export const { addJournalEntry, setJournalEntryUnread } = journalSlice.actions;

/**
 * Check if an entry exists in the journal, with optional state checks.
 */
export function hasJournalEntry(
  state: EntityState<JournalEntry>,
  id: string,
  options?: JournalEntryQueryOptions,
): boolean;
export function hasJournalEntry(state: EntityState<JournalEntry>, query: JournalEntryQuery): boolean;
export function hasJournalEntry(
  state: EntityState<JournalEntry>,
  idOrQuery: string | JournalEntryQuery,
  options?: JournalEntryQueryOptions,
): boolean {
  // Process overload parameters
  let id: string;
  let queryOptions: typeof options;
  if (typeof idOrQuery === 'string') {
    id = idOrQuery;
    queryOptions = options;
  } else {
    id = idOrQuery.id;
    queryOptions = idOrQuery;
  }

  const entity = journalSelectors.selectById(state, id);
  return matchesEntry(entity, queryOptions);
}

/**
 * Grouped export for journalSlice query functions.
 */
export const queries = Object.freeze({
  hasJournalEntry,
});

export default journalSlice.reducer;
