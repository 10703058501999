/**
 * i18next NS: Game01.GA01
 */
export default {
  buttons: {
    next: "Next",
  },
  urgentMessage: {
    header: "Urgent message from CPO",
    body: "We just received a ransomware demand.",
    demandTitle: "We have your files.",
    demandBody: "Pay $30 million in crypto by Monday or we will leak all of your customers’ credit card information.",
  },
  whatHappened: {
    header: "You have one hour to find out how this happened!",
    point1: "Who hacked the system? And how?",
    point2: "What other data was exfiltrated?",
    point3: "Are the attackers still in the environment? If yes, how do we kick them out?",
    point4: "How do we protect from this happening again?",
    body: "Work with your team to hunt for evidence and find out who is to blame for this threat... we need answers quickly so we don’t have to pay the ransom.",
  },
  suspiciousFile: {
    header: "Here is the file. What are you going to do?",
    body1: "To prove they have access to our information, the hackers shared this file that they downloaded from our network.",
    filename: "BFYO Purchasing Data - Q1.xlsx",
    body2: "Discuss this with your teammates over Microsoft Teams, then select your response.",
    buttonOpen: "Open file",
    buttonForward: "Forward file to Finance",
    buttonCheck: "Check file properties",
    buttonOops: "Oops! Try again",
    wrongOpenHeader: "No, don’t open it!",
    wrongOpenBody: "Good thing we have Microsoft Antimalware for Azure on this machine. Otherwise you would have just wreaked havoc on our network.",
    wrongForwardHeader: "Wait!",
    wrongForwardBody: "We just got an alert that Antimalware Action was taken on one of the PCs in Finance. We need to contain this threat.",
  },
  criticalInformation: {
    header: "Here are the file properties.",
    body: "What information will help you investigate this alert?",
    discuss: "Discuss this with your teammates, then click the check boxes to add the most important evidence to your journal.",
    checkbox1Text: "<b>Title:</b> BFYO Purchasing Data - Q1.xlsx",
    checkbox2Text: "<b>Authors:</b> Amari Rivera",
    checkbox3Text: "<b>Program:</b> Microsoft Excel",
    checkbox4Text: "<b>Content created:</b> Weds, October 27, 2021, 1:23:15 PM",
    checkbox5Text: "<b>Date last saved:</b> Thurs, ‎October ‎28, ‎2021, ‏‎3:33:36 PM",
    hintButton: "Get Hint: {{hintCount}} of {{hintTotal}} Given",
    hintCloseButton: "Okay",
    hintModalTitle: "Get Hint",
    unlockHintBtn: "Unlock Hint",
    hintPreface: "<b>Hint {{i}} of {{hintTotal}}:</b> ",
    hint1: "There are 2 pieces of critical information here.",
    hint2: "To investigate you will need to know where the file came from.",
    hint3: "Log any critical information about the file itself.",
    hintLocked: "This hint is locked.",
    doneButton: "Done",
    incorrectHeader: "You didn’t get all the critical information",
    incorrectBody: "Make sure the following items are added to your journal",
  },
  journalCallout: {
    header: "Nice job! You documented the key information. It is now listed in your Journal.",
    body: "Open the Journal anytime to review what you’ve found. Focus on critical information, but keep your eyes open for other interesting findings along the way! The evidence you collect will help you identify who is to blame - so we won’t have to pay the ransom.",
    button: "Okay",
  },
  suspectIdentified: {
    header: "Suspect: Amari Rivera, Security Operations Analyst",
    body: "Thanks to your quick work, you identified our first suspect. Here’s some information about him.",
    point1: "Joined company in 2015",
    point2: "Responsible for upgrades needed for EU launch",
    point3: "Performed a system-wide security update 3 days ago",
    point4: "Plays in a band in his spare time and just released an EP on an indie streaming service ",
    staticRouteMsg: "End of activity, please close the tab",
  },
};
