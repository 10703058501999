import React, { useEffect } from 'react';
import { Dictionary } from '@reduxjs/toolkit';
import { useTranslation, TFunction } from 'react-i18next';
import { Stack, DefaultEffects } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import countBy from 'lodash/countBy';
import NavBarButton, { NavBarButtonProps } from '../NavBarButton/NavBarButton';
import { JournalEntry } from '../../lib/journal';
import { navBarButtonConfig, NavBarButtonConfigElement } from '../../lib/nav-bar-buttons';
import { RootState } from '../../store';
import { setJournalEntryUnread } from '../../store/journal-slice';
import { setPolicyEntryUnread } from '../../store/policies-slice';
import NavBarPersona from '../NavBarPersona/NavBarPersona';

interface NavBarButtons {
  top: NavBarButtonProps[];
  bottom: NavBarButtonProps[];
}

export const baseIconButtonStyles = {
  root: {
    height: 'auto',
    width: 92,
    minHeight: 92,
    paddingTop: 12,
    pointer: 'cursor',
    selectors: {
      span: {
        flexDirection: 'column',
      },
      label: {
        marginTop: 12,
        pointerEvents: 'none',
      },
    },
  },
};

function generateNavButtonProps(
  el: NavBarButtonConfigElement,
  journalEntities: Dictionary<JournalEntry>,
  policiesEntities: Dictionary<JournalEntry>,
  t: TFunction<'navBar'>,
): NavBarButtonProps {
  const { type, icon, activeIcon } = el;
  let unread = -1;
  let disabled = false;
  switch (type) {
    case 'journal':
      unread = countBy(journalEntities, (e) => e?.unread).true || -1;
      disabled = Object.keys(journalEntities).length < 1;
      break;
    case 'policies':
      unread = countBy(policiesEntities, (e) => e?.unread).true || -1;
      disabled = Object.keys(policiesEntities).length < 1;
      break;
    default:
      break;
  }
  return {
    title: t(`buttons.${type}.title`),
    label: t(`buttons.${type}.label`),
    panel: type,
    icon,
    unread,
    disabled,
    ...(activeIcon && { activeIcon }),
  };
}

const NavBar: React.FunctionComponent = () => {
  const { t } = useTranslation('navBar');
  const journalEntities = useSelector((state: RootState) => state.journal.entities);
  const policiesEntities = useSelector((state: RootState) => state.policies.entities);
  const journalEntriesSeen = useSelector((state: RootState) => state.game.journalEntriesSeen);
  const policyEntriesSeen = useSelector((state: RootState) => state.game.policyEntriesSeen);
  const activePanel = useSelector((state: RootState) => state.game.activePanel);
  const lastActivePanel = useSelector((state: RootState) => state.game.lastActivePanel);
  const dispatch = useDispatch();

  const top: NavBarButtonProps[] = [];
  const bottom: NavBarButtonProps[] = [];

  navBarButtonConfig.top.forEach((el) => {
    top.push(generateNavButtonProps(el, journalEntities, policiesEntities, t));
  });
  navBarButtonConfig.bottom.forEach((el) => {
    bottom.push(generateNavButtonProps(el, journalEntities, policiesEntities, t));
  });

  const navBarButtons: NavBarButtons = { top, bottom };

  useEffect(() => {
    const panelsDisagree = activePanel !== lastActivePanel;
    if (panelsDisagree) {
      if (lastActivePanel === 'journal') {
        dispatch(setJournalEntryUnread({ ids: journalEntriesSeen, unread: false }));
      } else if (lastActivePanel === 'policies') {
        dispatch(setPolicyEntryUnread({ ids: policyEntriesSeen, unread: false }));
      }
    }
  }, [activePanel, lastActivePanel, dispatch, journalEntriesSeen, policyEntriesSeen]);

  return (
    <Stack
      verticalAlign="space-between"
      style={{
        position: 'relative',
        background: 'white',
        zIndex: 2,
        boxShadow: DefaultEffects.elevation16,
        height: '100%',
      }}
    >
      <Stack>
        <Stack.Item>
          <NavBarPersona />
        </Stack.Item>
        {navBarButtons.top.map((buttonProps, i) => (
          <Stack.Item key={`top_nav_${i}`}>
            <NavBarButton {...buttonProps} />
          </Stack.Item>
        ))}
      </Stack>
      <Stack>
        {navBarButtons.bottom.map((buttonProps, i) => (
          <Stack.Item key={`bottom_nav_${i}`}>
            <NavBarButton {...buttonProps} />
          </Stack.Item>
        ))}
      </Stack>
    </Stack>
  );
};

export default NavBar;
