import { createMachine } from 'xstate';

import { getDefaultOptions } from '../../task-options';
import type { TaskConfig, TaskContext, TaskOptions, TaskMachineProps, TaskEvent, TSXEvent } from '../../task-types';

export interface IR01Context extends TaskContext {}
type TContext = IR01Context;

export type IR01Event = TaskEvent | TSXEvent;
type TEvent = IR01Event;

export type IR01MachineProps = TaskMachineProps<TContext>;

/**
 * Machine config.
 */
export const IR01Config: TaskConfig<TContext, TEvent> = {
  id: 'IR01',
  initial: 'intro',
  states: {
    intro: {
      on: {
        // needs to add all the other role's critical information according to SS
        NEXT: {
          target: 'incidentReport',
        },
      },
    },
    incidentReport: {
      initial: 'fillOutReport',
      states: {
        fillOutReport: {
          on: {
            'CLICK.SUBMIT': {
              target: 'confirmComplete',
            },
          },
        },
        confirmComplete: {
          on: {
            'CLICK.CONFIRM': {
              target: '#IR01.done',
            },
            'CLICK.DENY': {
              target: 'fillOutReport',
            },
          },
        },
      },
    },
    done: {
      type: 'final',
    },
  },
};

/**
 * Additional machine options.
 */
export const IR01Options: TaskOptions<TContext, TEvent> = {};

/**
 * Machine constructor.
 */
export function createIR01() {
  return createMachine(IR01Config, getDefaultOptions()).withConfig(IR01Options);
}
