import type { EntryLocaleContent } from "../types";

/**
 * i18Next `entries:Game01.IDSA01` entry text content
 */
const IDSA01: EntryLocaleContent = {
  // policies
  userRiskRemediationPolicy: {
    description: "User Risk Remediation Policy",
    default: "For all High Risk Users: Require password change to access.",
  },
  signInRiskPolicy: {
    description: "Sign-in Risk Policy",
    default: "For All Medium Risk and Above Users: Require multi-factor authentication to access.",
  },
  // lab01
  AADIPRiskyUserDetails01: {
    description: "Risky User Details: Amari Rivera",
    A: "Username: LeeG@M365x4897.OnMicrosoft.com",
    B: "Risk Level: High",
    C: "Risk last updated: 9/7/2021, 11:17:01 AM",
  },
  AADIPRiskyUserDetails02: {
    description: "Risky User Details: Nestor Wilke",
    A: "Username: nestorw@relecloud.com",
    B: "Risk Level: High",
    C: "Risk last updated: 9/1/2021, 5:42:23 AM",
  },
  AADIPRiskyUserDetails03: {
    description: "Risky User Details: Emily Braun",
    A: "Username: Username: EmilyB@relecloud.com",
    B: "Risk Level: High",
    C: "Risk last updated: 8/28/2021, 8:11:43 AM",
  },
  AADIPRiskySignInDetails: {
    description: "Risky Sign-in Details - Amari Rivera",
    A: "Application: OfficeHome",
    B: "IP address: 185.220.101.134",
    C: "Location: Schoenwalde-Glien, Brandenburg, DE",
    D: "Date: 9/7/2021, 11:14:01 AM",
  },
  AADIPAnonymousSignIn: {
    description: "Anonymous Sign-in - Amari Rivera",
    A: "Sign-in time: 9/7/2021, 11:14 AM",
    B: "IP address: 185.220.101.134",
    C: "Sign-in location: Schoenwalde-Glien, Brandenburg, DE",
    D: "Sign-in client: Mozilla/5.0 (Windows NT 10.0; rv:78.0)",
  },
  AADIPRiskDetections: {
    description: "Risk Detections - Amari Rivera",
    A: "Detection type: Password spray",
    B: "Detection type: Anonymous IP address (Germany)",
    C: "Detection type: Anonymous IP address (Australia)",
  },
  AADIPConfirmCompromised: {
    description: "Confirm user compromised",
    default: "User: Amari Rivera",
  },
  // modal01
  alertVMDeleted: {
    description: "ALERT: Missing VM",
    default: "The Help Desk forwarded a support ticket regarding an unreachable production VM.",
  },
  // lab03
  AADIPUserRiskPolicy: {
    description: "User risk policy",
    A: "Assignments: All users",
    B: "User Risk: High",
    C: "Control: Require password change",
    D: "Enforce policy: On",
  },
  AADIPSignInRiskPolicy: {
    description: "Sign-in risk policy.",
    A: "Assignments: All users",
    B1: "User Risk: Medium and above",
    B2: "User Risk: High",
    C: "Control: Require multi-factor authentication",
    D: "Enforce policy: On",
  },
};

export default IDSA01;
