import React from 'react';
import { IFontStyles, ITextStyles, Text } from '@fluentui/react';
import { TFunction } from 'react-i18next';
import { Control } from 'react-hook-form';
import { IR01EventOptions, IR01Option, IR01TaskProps, IRForm } from '../../interface';
import RTrans from '../../../../../components/RTrans/RTrans';
import { ControlledDropdown } from '../../../../../components/HookFormComponents/HookFormComponents';
import { generateOptions } from '../../data/options';
import { nameof } from '../../../../../lib/util';

interface IREventProps extends IR01TaskProps {
  options: IR01EventOptions[];
  i18nKey: string;
  control: Control<any, object>;
  styles?: ITextStyles;
  variant?: keyof IFontStyles | undefined;
}

export function generateDropdownComponent(
  id: string,
  options: IR01Option[],
  namespace: string,
  t: TFunction<('Game01.IR01' | 'roles')[]>,
  control: Control<any, object>,
): React.ReactElement {
  return (
    <ControlledDropdown
      required
      rules={{ required: true }}
      dropdownWidth="auto"
      options={generateOptions(options, namespace, t)}
      control={control}
      name={nameof<IRForm>(id as keyof IRForm)}
      placeholder={t('ir.placeholder')}
      styles={{
        root: {
          padding: 8,
          display: 'inline-flex',
        },
      }}
      id={id}
    />
  );
}

export const textStyles: ITextStyles = {
  root: {
    display: 'inline',
    marginLeft: 8,
    selectors: {
      p: {
        display: 'inline',
      },
    },
  },
};

const IREvent: React.FunctionComponent<IREventProps> = ({ t, options, i18nKey, control, styles, variant }) => {
  const components: React.ReactElement[] = [];
  options.forEach((o) => {
    components.push(generateDropdownComponent(o.key, o.options, o.namespace, t, control));
  });
  return (
    <Text variant={variant} styles={styles || textStyles}>
      <RTrans t={t} i18nKey={i18nKey} components={components} />
    </Text>
  );
};

export default IREvent;
