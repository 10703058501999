import * as yup from 'yup';

export const SCHEMA = {
  config: yup.object().shape({
    url: yup.string(),
    domain: yup.string(),
    key: yup.string(),
    id: yup.number(),
    // eslint-disable-next-line react/forbid-prop-types
    gameData: yup.object(),
  }),
  loginPlayer: yup.object().shape({
    username: yup.string(),
    email: yup.string().email(),
    password: yup.string(),
    token: yup.string(),
    game_id: yup.number().integer(),
  }),
  getPlayerInfo: yup.object().shape({
    game_id: yup.number().integer(),
    best: yup.string().matches(/(highest|lowest)/),
  }),
  startSession: yup.object().shape({
    segment: yup.string(),
    level_id: yup.number().integer(),
  }),
  getScore: yup.object().shape({
    game_id: yup.number().integer(),
    best: yup.string().matches(/(highest|lowest)/),
  }),
  addScore: yup.object().shape({
    player_role: yup.string(),
    group_score: yup.number().integer(),
    identity_score: yup.number().integer(),
    security_score: yup.number().integer(),
    ir_score: yup.number().integer(),
    points: yup.number().integer().required(),
    time: yup.number().integer().required(),
    complete: yup.boolean(),
    level_id: yup.number().integer(),
  }),
  addAnswer: yup.object().shape({
    game_id: yup.number().integer().required(),
    level_id: yup.number().integer(),
    question_id: yup.number().integer().required(),
    correct: yup.boolean().required(),
    answer_id: yup.string(),
    answer_text: yup.string(),
    time: yup.number().integer(),
  }),
  addPlayerMetadata: yup.object().shape({
    data: yup.object().required(),
  }),
  addSessionMetadata: yup.object().shape({
    data: yup.object().required(),
    session_id: yup.number().required(),
  }),
  checkSSOAuth: yup.object().shape({
    domain: yup.string().required(),
    slug: yup.string(),
    game_id: yup.number().integer(),
  }),
  checkAuth: yup.object().shape({
    domain: yup.string().required(),
    slug: yup.string(),
    game_id: yup.string(),
    email: yup.string().email(),
    user_list_id: yup.number().integer(),
  }),
  clearScores: yup.object().shape({
    domain: yup.string().required(),
    slug: yup.string().required(),
    username: yup.string().required(),
  }),
  getSessionMetadata: yup.object().shape({
    session_id: yup.number().required(),
  }),
  resetPlayerPassword: yup.object().shape({
    email: yup.string().email().required(),
  }),
  updatePlayer: yup.object().shape({
    username: yup.string(),
    email: yup.string().email(),
    first_name: yup.string(),
    last_name: yup.string(),
    initials: yup.string(),
    display_name: yup.string(),
    phone: yup.string(),
    employee_id: yup.string(),
    status: yup.number().integer(),
    reconcile: yup.boolean(),
  }),
  getHighscores: yup.object().shape({
    game_id: yup.number().integer().required(),
    best: yup.string().matches(/(highest|lowest)/),
    limit: yup.number().positive(),
  }),
  getHighestScore: yup.object().shape({
    game_id: yup.number().integer().required(),
    best: yup.string().matches(/(highest|lowest)/),
    start_date: yup.string(),
    end_date: yup.string(),
  }),
  getLeaderboard: yup.object().shape({
    game_id: yup.number().integer().required(),
    best: yup.string().matches(/(highest|lowest)/),
    start_date: yup.string(),
    end_date: yup.string(),
    limit: yup.number().positive(),
  }),
};

/**
 * Validate input given to functions
 * Doesn't say why, returns true or throws an error if false
 * @param {Object} params variable to be validated
 * @param {Object} schema the yup validation schema chosen
 * @param {String} comment optional comment
 */

export const validateSchema = async (params: any, schema: any, comment = '') => {
  const s = (SCHEMA as any)[schema];
  if (!s) {
    throw new Error(`No schema found with key ${schema}`);
  }
  const v = await s.isValid(params);
  if (!v) {
    throw new Error(`APIManager : ${schema} : invalid parameters : ${comment}`);
  }
  return v;
};
