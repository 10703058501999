/**
 * i18next NS: Game01.Intro
 */
export default {
  noRole: "ROLE NOT SELECTED",
  buttons: {
    first: "Got It",
    readMore: "Read More About Us",
    next: "Next",
    final: "Let’s go!",
    close: "Close",
  },
  readMoreModal: {
    title: "Our products are best for you -- and the planet.",
    content: "In 2009, brothers William and James Brown founded Best for Your Organics after a flood decimated their fall crops. Faced with the choice to stay or sell, they decided to support the growing “go green” market and modernize their third-generation farm in a sustainable way. They started with their own organically and locally grown produce. As demand grew, they developed innovative ways to expand into ethically-sourced, all natural cosmetics and baby products.<br><br>Over the last 24 months, Best for You Organics has worked hard to expand beyond U.S. borders. We passed stringent EU standards on data privacy and recently passed our reviews and audits with flying colors. We are excited to see what the future holds!",
  },
  intro1: {
    title: "Who is stealing our information?",
    content: "At Best for You Organics, our responsibly-sourced products support local farmers and inspire our customers to live their healthiest lives.<br><br>We’ve built a loyal customer base and we’re growing fast. We have over 10 million U.S. customers and we just got the go-ahead to launch our business in the EU!<br><br>But the bigger we get, the more likely we are to be targeted by malicious attacks. Lately we’ve had a rise in suspicious activity in our network.<br><br>As a member of our Incident Response Team, we need you to figure out what’s going on and make sure our systems are secure.",
  },
  intro2: {
    title: "I’m your Incident Response (IR) Manager and I’ll be guiding you along. ",
    content: "Whenever an alert comes in, I’ll pass it on to you. You’ll need to investigate, remediate, and protect us from future threats.<br><br>At the end of the day, you’ll work with your teammate to fill out an Incident Report. That report goes to our CIO, so make sure you do a good job with it.",
  },
  intro3: {
    title: "This is your Desktop",
    content: "The <b>Journal</b> is where you log your findings and actions. This is really important because you’ll need to document everything in a final <strong>Incident Report</strong>. If you don’t keep a good Journal, you won’t be able to complete this critical step of the process.",
  },
  intro4: {
    content: "<br><br>If you run into a situation where you need to configure settings, check our <strong>Policies</strong>.",
  },
  intro5: {
    content: "<br><br>From your Desktop, you can also <strong>Chat</strong> with your teammates or get <strong>Help</strong>.",
  },
  intro6: {
    content: "<br><br>That reminds me, our <strong>{{opRole}}</strong> will be your partner today. There’s already an alert coming in that you’ll need to work on together. Ready to get started?",
  },
};
