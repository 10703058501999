import tasks from "./en/tasks";
import entries from "./en/entries";

/**
 * Top-level i18next namespaces.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const en: any = {
  common: {
    addToJournal: "Add to Journal",
    btnContinue: "Continue",
    btnOK: "OK",
    prompt: "Pick one:",
    IRManager: {
      name: "IR Manager",
      initials: "IR",
    },
    NYIDialog: {
      title: "NYI",
      body: "This content is not implemented, and will be skipped.",
    },
  },
  unavailable: {
    header: "Feature Unavailable",
    body: "This feature is not yet available in the current build.",
  },
  journal: {
    header: "Journal",
  },
  policies: {
    header: "Policies",
  },
  help: {
    header: "Help",
  },
  chat: {
    header: "Chat",
  },
  settings: {
    header: "Settings",
    confirmationDialog: {
      title: "Are you sure?",
      body: "If you log out, all your progress will be cleared.",
      confirmButton: "Log out",
      cancelButton: "Cancel",
    },
  },
  journalEntry: {
    evidence: "Evidence",
    alert: "Alert",
    policy: "Policy",
    seeMore: "See more",
  },
  journalFilter: {
    header: {
      all: "All",
      team: "Team",
      identity: "Identity",
      security: "Security Operations",
      compliance: "Compliance",
      alert: "Alerts",
    },
    buttons: {
      ariaLabel: "Journal Filter Button",
      ariaDescription: {
        all: "Show All Entries",
        identity: "Show Identity Entries",
        compliance: "Show Compliance Entries",
        alert: "Show Alerts",
      },
    },
  },
  roles: {
    identity: "Identity Administrator",
    security: "Security Operations Analyst",
  },
  navBar: {
    unread: {
      newEntry: 'New Entry',
      newEntries: 'New Entries',
      clickToView: 'Click To View',
    },
    buttons: {
      journal: {
        title: "Journal Nav Button",
        label: "Journal",
      },
      policies: {
        title: "Policies Nav Button",
        label: "Policies",
      },
      chat: {
        title: "Chat Button",
        label: "Chat",
      },
      help: {
        title: "Help Nav Button",
        label: "Help",
      },
      settings: {
        title: "Settings Nav Button",
        label: "Settings",
      },
    },
  },
  ...tasks,
  ...entries,
};
export default en;
