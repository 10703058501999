import type { EntryDefinition } from '../types';

/**
 * Game01.IDSA01 entry definitions
 */
const IDSA01: EntryDefinition = {
  // policies
  userRiskRemediationPolicy: {
    type: 'identity',
    category: 'policy',
  },
  signInRiskPolicy: {
    type: 'identity',
    category: 'policy',
  },
  // lab01
  AADIPRiskyUserDetails01: {
    type: 'identity',
    category: 'evidence',
  },
  AADIPRiskyUserDetails02: {
    type: 'identity',
    category: 'evidence',
  },
  AADIPRiskyUserDetails03: {
    type: 'identity',
    category: 'evidence',
  },
  AADIPRiskySignInDetails: {
    type: 'identity',
    category: 'evidence',
  },
  AADIPAnonymousSignIn: {
    type: 'identity',
    category: 'evidence',
  },
  AADIPRiskDetections: {
    type: 'identity',
    category: 'evidence',
  },
  AADIPConfirmCompromised: {
    type: 'identity',
    category: 'evidence',
  },
  // modal01
  alertVMDeleted: {
    type: 'alert',
    category: 'alert',
  },
  // lab03
  AADIPUserRiskPolicy: {
    type: 'identity',
    category: 'evidence',
  },
  AADIPSignInRiskPolicy: {
    type: 'identity',
    category: 'evidence',
  },
};

export default IDSA01;
