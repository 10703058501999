export default {
  ALERT: {
    description: "ALERT: Leaked File",
    default: "Test File released on public website, no sensitive information lost.",
  },
  LEAKED_FILE_ANALYSIS: {
    description: "Leaked File: Analysis",
    default: "We chose to check the properties of the leaked file and see what we could determine.",
  },
  LEAKED_FILE_PROPERTIES: {
    description: "Leaked File: File Properties",
    default: "The following properties where saved to help the investigation:",
    title: "<b>Title:</b> BFYO Purchasing Data - Q1.xlsx",
    author: "<b>Authors:</b> Amari Rivera",
    program: "<b>Program:</b> Microsoft Excel",
    created: "<b>Content created:</b> Weds, October 27, 2021, 1:23:15 PM",
    saved: "<b>Date last saved:</b> Thurs, ‎October ‎28, ‎2021, ‏‎3:33:36 PM",
  },
};
