import React from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import merge from 'lodash/merge';
import { IIconProps, IconButton, IButtonStyles } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { setActiveJournalFilter } from '../../store/game-slice';
import { RootState } from '../../store';
import { filterColors } from '../../lib/journal-filter';

interface JournalFilterInterface {
  type: string;
  icon: string;
}

const filterButtonStyles: IButtonStyles = {
  root: {
    height: 'auto',
    width: 40,
    minHeight: 40,
    paddingTop: 0,
    pointer: 'cursor',
    color: 'white',
    borderRadius: 100,
    backgroundColor: 'rgb(237, 235, 233)',
  },
  rootHovered: {
    color: 'white',
    backgroundColor: 'rgb(237, 235, 233)',
  },
  rootPressed: {
    color: 'white',
    backgroundColor: 'rgb(237, 235, 233)',
  },
};

function createFilterButtonStyles(type: string): IButtonStyles {
  const filter = filterColors.find((e) => e.type === type);
  if (filter) {
    const toMerge = {
      root: {
        '&.selected': {
          backgroundColor: `${filter.color}!important`,
        },
      },
    };
    return merge(toMerge, filterButtonStyles);
  }
  throw new Error('createFilterButtonStyles called with invalid type');
}

function createIconProps(iconName: string): IIconProps {
  return {
    styles: {
      root: {
        fontSize: 22,
      },
    },
    iconName,
  };
}

const JournalFilterButton: React.FunctionComponent<JournalFilterInterface> = ({ type, icon }) => {
  const dispatch = useDispatch();
  const activeJournalFilter = useSelector((state: RootState) => state.game.activeJournalFilter);
  const { t } = useTranslation('journalFilter');
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout>
      <IconButton
        className={cx(`filter-button-${type}`, {
          selected: activeJournalFilter === type,
        })}
        ariaLabel={t('buttons.ariaLabel')}
        ariaDescription={t(`buttons.ariaDescription.${type}`)}
        iconProps={createIconProps(icon)}
        styles={createFilterButtonStyles(type)}
        onClick={() => {
          dispatch(setActiveJournalFilter(type));
        }}
      />
    </motion.div>
  );
};
export default JournalFilterButton;
