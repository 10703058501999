import React from 'react';
import { Text, DefaultButton, Stack, Icon } from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import ScreenModal, { ScreenModalSidebarProps } from '../../../../components/ScreenModal/ScreenModal';
import { GA01ComponentProps } from '../interface';
import ResultModal from '../../../../components/ResultModal/ResultModal';

interface SuspiciousFileProps extends GA01ComponentProps {
  sidebar: ScreenModalSidebarProps;
  state: any;
}
let suspiciousFileState = 'wrongOpen';

const SuspiciousFile: React.FunctionComponent<SuspiciousFileProps> = ({ t, send = (e) => e, sidebar, state }) => {
  const { suspiciousFile } = state;
  const modalOpen = suspiciousFile === 'wrongOpen' || state.suspiciousFile === 'wrongForward';
  if (suspiciousFile !== 'makeChoice') {
    suspiciousFileState = suspiciousFile;
  }
  return (
    <ScreenModal sidebar={sidebar} title={t('suspiciousFile.header')}>
      <Stack tokens={{ childrenGap: 24 }} verticalAlign="space-around" verticalFill>
        <Stack.Item>
          <Text variant="large">{t('suspiciousFile.body1')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal tokens={{ childrenGap: 24 }}>
            <Stack.Item>
              <Icon {...getFileTypeIconProps({ extension: 'xlsx', size: 96 })} />
            </Stack.Item>
            <Stack.Item>
              <Stack verticalAlign="center" verticalFill>
                <Stack.Item>
                  <Text variant="large">{t('suspiciousFile.filename')}</Text>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Text variant="large">{t('suspiciousFile.body2')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 24 }}>
            <Stack.Item>
              <DefaultButton onClick={() => send('CLICK.OPEN')}>{t('suspiciousFile.buttonOpen')}</DefaultButton>
            </Stack.Item>
            <Stack.Item>
              <DefaultButton onClick={() => send('CLICK.FORWARD')}>{t('suspiciousFile.buttonForward')}</DefaultButton>
            </Stack.Item>
            <Stack.Item>
              <DefaultButton onClick={() => send('CLICK.CHECK')}>{t('suspiciousFile.buttonCheck')}</DefaultButton>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
      <ResultModal
        isOpen={modalOpen}
        title={t(`suspiciousFile.${suspiciousFileState}Header`)}
        body={<Text>{t(`suspiciousFile.${suspiciousFileState}Body`)}</Text>}
        footer={<DefaultButton onClick={() => send('CLICK.CLOSE')}>{t('suspiciousFile.buttonOops')}</DefaultButton>}
        onDismiss={() => send('CLICK.CLOSE')}
      />
    </ScreenModal>
  );
};

export default SuspiciousFile;
