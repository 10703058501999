import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Stack, FontIcon } from '@fluentui/react';
import { generateEntryStyles, getEntryIcons } from '../../lib/entries';

interface EntryIconColProps {
  category: string;
  type: string;
}

const EntryIconCol: React.FunctionComponent<EntryIconColProps> = ({ category, type }) => {
  const { t } = useTranslation('journalEntry');
  return (
    <Stack tokens={{ childrenGap: 4 }}>
      <FontIcon aria-label={t(category)} iconName={getEntryIcons(category)} className={generateEntryStyles(type)} />
      <Text variant="medium" styles={{ root: { textAlign: 'center', fontWeight: 900 } }} block>
        {t(category)}
      </Text>
    </Stack>
  );
};
export default EntryIconCol;
