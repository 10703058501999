import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  JournalEntry,
  JournalEntryQuery,
  JournalEntryQueryOptions,
  JournalEntryUpdate,
  matchesEntry,
  removeStates,
  updateEntry,
} from '../lib/journal';

/**
 * Entity adapter for Policy entries.
 */
export const policiesAdapter = createEntityAdapter<JournalEntry>({
  // Sort policies entries by date (ascending).
  sortComparer: (a, b) => a.date - b.date,
});

/**
 * Selectors for policiesAdapter.
 */
export const policiesSelectors = policiesAdapter.getSelectors();

/**
 * Journal Slice definition
 */
export const policiesSlice = createSlice({
  name: 'policies',
  initialState: policiesAdapter.getInitialState(),
  reducers: {
    addPolicyEntry: (state, { payload }: PayloadAction<JournalEntryUpdate>) => {
      const entity = policiesSelectors.selectById(state, payload.id);
      const newEntity = updateEntry(entity, payload);
      return policiesAdapter.upsertOne(state, newEntity);
    },
    removePolicyEntry: (state, { payload }: PayloadAction<JournalEntryUpdate>) => {
      const entity = policiesSelectors.selectById(state, payload.id);
      const newEntity = removeStates(entity, payload.states);
      if (newEntity == null) {
        return policiesAdapter.removeOne(state, payload.id);
      }
      return policiesAdapter.upsertOne(state, newEntity);
    },
    setPolicyEntryUnread: (state, { payload }) => {
      const { ids, unread } = payload;
      const updates = ids.map((id: string) => ({
        id,
        changes: {
          unread,
        },
      }));
      return policiesAdapter.updateMany(state, updates);
    },
  },
});

/**
 * Grouped export for policiesSlice actions.
 */
export const actions = policiesSlice.actions;

/**
 * Individual exports for policiesSlice actions.
 */
export const { addPolicyEntry, setPolicyEntryUnread } = policiesSlice.actions;

/**
 * Check if an entry exists in the policies, with optional state checks.
 */
export function hasPolicyEntry(
  state: EntityState<JournalEntry>,
  id: string,
  options?: JournalEntryQueryOptions,
): boolean;
export function hasPolicyEntry(state: EntityState<JournalEntry>, query: JournalEntryQuery): boolean;
export function hasPolicyEntry(
  state: EntityState<JournalEntry>,
  idOrQuery: string | JournalEntryQuery,
  options?: JournalEntryQueryOptions,
): boolean {
  // Process overload parameters
  let id: string;
  let queryOptions: typeof options;
  if (typeof idOrQuery === 'string') {
    id = idOrQuery;
    queryOptions = options;
  } else {
    id = idOrQuery.id;
    queryOptions = idOrQuery;
  }

  const entity = policiesSelectors.selectById(state, id);
  return matchesEntry(entity, queryOptions);
}

/**
 * Grouped export for policiesSlice query functions.
 */
export const queries = Object.freeze({
  hasPolicyEntry,
});

export default policiesSlice.reducer;
