import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { INavLink, INavLinkGroup, IStackTokens, Nav, Stack, Text } from '@fluentui/react';

const homeSectionStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const taskNavLinks: INavLinkGroup[] = [
  {
    name: 'Tasks',
    collapseByDefault: false,
    links: [
      {
        name: 'Game 1, Registration',
        url: '/tasks/Game01/Registration',
      },
      {
        name: 'Game 1, Intro',
        url: '/tasks/Game01/Intro',
      },
      {
        name: 'Game 1, GA 01',
        url: '/tasks/Game01/GA01',
      },
      {
        name: 'Game 1, ID SA 01',
        url: '/tasks/Game01/IDSA01',
      },
      {
        name: 'Game 1, ID SA 03',
        url: '/tasks/Game01/IDSA03',
      },
      {
        name: 'Game 1, SEC SA 02',
        url: '/tasks/Game01/SECSA02',
      },
      {
        name: 'Game 1, SEC SA 03',
        url: '/tasks/Game01/SECSA03',
      },
      {
        name: 'Game 1, IR 01',
        url: '/tasks/Game01/IR01',
      },
      {
        name: 'Game 1, Ending',
        url: '/tasks/Game01/Ending',
      },
    ],
  },
];

const HomeScreen: React.FunctionComponent = () => {
  const history = useHistory();
  const handleNavLinkClick = useCallback(
    (event?: React.MouseEvent, element?: INavLink) => {
      event?.preventDefault();
      if (element != null) {
        history?.push(element.url);
      }
      return true;
    },
    [history],
  );

  return (
    <Stack verticalFill tokens={homeSectionStackTokens}>
      <Stack.Item>
        <Text variant="xxLarge">Home Screen</Text>
      </Stack.Item>
      <Stack.Item>
        <Nav groups={taskNavLinks} onLinkClick={handleNavLinkClick} />
      </Stack.Item>
    </Stack>
  );
};

export default HomeScreen;
