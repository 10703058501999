/**
 * i18next NS: `Game01.SECSA02`
 */
export default {
  common: {
    btnDismiss: "Dismiss",
    btnDone: "Done",
    btnLetsGo: "Let's Go!",
    btnOkay: "Okay",
    btnTryAgain: "Oops! Try again",
    btnHintAvailable: "Hint Available",
    btnHintUnavailable: "Hint Unavailable",
  },
  intro01: {
    title: "Moving forward in time…",
    body: "<p>During the full experience, you would now start investigating the ransomware alert. But for this demo we are giving you a different challenge.</p> <p><strong>Another Alert is coming in! This one is from the Help Desk.</strong></p><p>Ready to see what happens next?</p>",
    B01: "$t(common.btnLetsGo)",
  },
  modal01: {
    title: "Critical Alert in SCI Teams Channel",
    body: "@Security from Help Desk: User unable to connect to FinanceVM23",
    B01: "Learn More",
  },
  delete01a: {
    title: "Here's the support ticket.",
    body: "What critical information will help you investigate this issue? Select the key items to add them to your journal.",
    subheading: "Support Ticket",
    CH01: "Reporting User: Debra Berger",
    CH02: "Help Desk Agent: Grady Archie",
    CH03: "VM Name: FinanceVM23",
    CH04: "Issue: Connection to FinanceVM23 fails",
    CH05: "Last Used: 4 days ago",
    B01: "$t(common.btnDone)",
    HT01: "Get Hint: {{delete01HintsUnlocked}} of {{delete01HintTotal}} Given",
  },
  delete01HT: {
    title: "Get Hint",
    hintPreface: "<b>Hint {{index}} of {{hintTotal}}:</b> ",
    hintLocked: "This hint is locked.",
    hint1: "There are 3 pieces of critical information here.",
    hint2: "It's important to note the resource you need to investigate.",
    hint3: "Make sure you know when and how the incident occured.",
    HT02: "Unlock Hint",
    D01: "$t(common.btnOkay)",
  },
  delete01b: {
    title: "You didn't get all the critical information.",
    body: "Make sure the following items are added to your journal.",
    B02: "$t(common.btnDone)",
  },
  delete01c: {
    title: "Looks good to me.",
    body: "You documented the key information.",
    B03: "Continue",
  },
  delete02a: {
    title: "What should you do next?",
    body: "Now that you collected some key information, how do you want to investigate this incident?",
    B01: "View the logs in Azure Sentinel",
    B02: "Look for the VM in the Azure portal",
    B03: "Call Help Desk for more info",
  },
  delete02b: {
    title: "No, we're past that point.",
    body: "The Help Desk already looked for the VM in the Azure portal. You need to take the next step.",
    B04: "$t(common.btnTryAgain)",
  },
  delete02c: {
    title: "Response from the Help Desk",
    body: "The Support Ticket includes all of the information we have. We tried to fix it but there's nothing else we can do. Can you help us figure out what's going on?",
    B05: "$t(common.btnTryAgain)",
  },
  delete03a: {
    title: "Good idea. You’ll search Azure Sentinel logs to investigate why the VM is missing.",
    body: "<p>You'll start with search \"FinanceVM23\".</p> <p>Then you'll use KQL (Kusto Query Language) to narrow down your search results.</p>",
    B01: "What's KQL?",
    B02: "$t(common.btnLetsGo)",
  },
  delete03b: {
    title: "KQL (Kusto Query Language)",
    body: '<p>A KQL (Kusto Query Language) query is a plain text, read-only request to process data and return results. You use KQL queries to search large data sets in Azure.</p> <ul><li>First, you reference the source of the data (the table that contains the information).</li> <li>Then, you add query statements to filter the data and narrow down the results.</li></ul> <p><strong>Example</strong></p> <code>StormEvents<br />| where StartTime >= datetime(2007-11-01) and StartTime &lt; datetime(2007-12-01)<br />| where State == "FLORIDA"<br />| count',
    B03: "$t(common.btnOkay)",
  },
  reflect01a: {
    title: "Record the KQL Query you used so that you can refer back to it later.",
    body: "Where did you find the information and how did you narrow down your search results?",
    DD01a: "AzureActivity",
    DD01b: "SecurityAlert",
    DD01c: "AuditLogs",
    DD01d: "Usage",
    where01: "| <em>where</em> Properties <em>contains</em>",
    DD02a: '"rgFinance"',
    DD02b: '"FinanaceVM23"',
    DD02c: '"76c86e96-91d3-4777-a917-d7b06aea69b1"',
    DD02d: '"M365x489897"',
    where02: "| <em>where</em> OperationNameValue ==",
    DD03a: "MICROSOFT.COMPUTE/VIRTUALMACHINES/ERASE",
    DD03b: "MICROSOFT.COMPUTE/VIRTUALMACHINES/REMOVE",
    DD03c: "MICROSOFT.COMPUTE/VIRTUALMACHINES/DELETE",
    DD03d: "MICROSOFT.COMPUTE/VIRTUALMACHINES/UNALLOCATE",
    where03: "| <em>where</em> ActivityStatusValue ==",
    DD04a: "Accept",
    DD04b: "Success",
    DD04c: "Start",
    DD04d: "Finished",
    B01: "$t(common.btnDone)",
  },
  reflect01b: {
    title: "That's not quite right.",
    body: "Set the answers correctly before proceeding.",
    DD01error: 'The answer you\'re looking for is "$t(reflect01a.DD01a)"',
    DD02error: "The answer you're looking for is $t(reflect01a.DD02b)",
    DD03error: 'The answer you\'re looking for is "$t(reflect01a.DD03c)"',
    DD04error: 'The answer you\'re looking for is "$t(reflect01a.DD04b)"',
    B02: "$t(common.btnDone)",
  },
  reflect03a: {
    title: "Investigation complete!",
    body: "Did you find out who deleted the VM?",
    B01: "Yes, it was NestorW",
    B02: "Yes, it was LeeG",
    B03: "Yes, it was EmilyB",
  },
  reflect03b: {
    title: "Are you sure?",
    body: "I don't think it's NestorW. He's been on leave for the last 6 months.",
    B04: "$t(common.btnTryAgain)",
  },
  reflect03c: {
    title: "Are you sure?",
    body: "Take another look. I don't think EmilyB would be given VM access.",
    B05: "$t(common.btnTryAgain)",
  },
  reflect04: {
    title: "Now contact your teammates.",
    body: "<p>Let them know what you found and that the VM will be restored.</p> <p><em>Tip: Ask Identity to make sure the VM can't be deleted again.</em></p>",
    B01: "$t(common.btnDone)",
  },
  labHints: {
    "common": {
      incorrectSearchTerm: "That doesn't seem to be the correct search term. Try again.",
      tryRunningQueryAgain: "Ok, now try running the query again.",
      tryExploring: "Try exploring more events.",
      tryExploring2: "Try exploring more events for information for your journal.",
      tryExploringAuth: "$t(labHints.common.tryExploringAuth)",
      tryScrolling: "Try scrolling through the events to find confirmation of the VM delete.",
      authProps: "Authorization contains properties related to authentication and RBAC for the event. Usually includes the “action”, “role” and “scope” properties and is stored as string. Using Authorization_d is better as that uses dynamic columns, not strings.",
      worthRecording: "This looks worth recording in your journal",
      checkDetailsEvent: "Check out more details  of this event.",
    },
    "2ef47dc7-347f-472d-af86-1f397e282f51": "The support ticket suggested that the VM had been in use within the past week.",
    "491b3ca1-079d-42a8-bded-fa8ede62cca1": "You know this incident happened before today. Try changing the time range for the search.",
    "61284318-6749-4532-b803-67e818ee991a": "$t(labHints.common.tryRunningQueryAgain)",
    "3205ac12-84b6-4db5-82ff-8c14405ab425": "$t(labHints.common.tryRunningQueryAgain)",
    "e2f6324a-0f8b-4eb8-84f0-a8c0bf35accd": "$t(labHints.common.incorrectSearchTerm)",
    "bbf8ce24-b121-43a5-b243-a4f76b90a7bd": "$t(labHints.common.tryRunningQueryAgain)",
    "536af78b-0744-425a-9942-a3cecf2e4dde": "$t(labHints.common.incorrectSearchTerm)",
    "feaaff60-b577-47cb-b313-e0c79fe2d389": "$t(labHints.common.tryRunningQueryAgain)",
    "fa1cd0b9-adaa-405e-a250-27aa9e1023fb": "$t(labHints.common.incorrectSearchTerm)",
    "8231e670-60e8-4fcc-8cfe-247eec0649d8": "$t(labHints.common.tryRunningQueryAgain)",
    "463c87a4-0ea4-4759-959e-22752a35d352": "$t(labHints.common.tryRunningQueryAgain)",
    "74169f40-d7e5-4650-aca9-b295bfbc3227": "$t(labHints.common.tryScrolling)",
    "f3e119ef-7e6b-455a-a862-050e223cad73": "Try expanding the results to see more information.",
    "3b5a5080-10da-4bd4-b8e7-bff7ebdf4685": "$t(labHints.common.tryScrolling)",
    "e21e0340-c9d4-41f0-999a-d25b16671141": "$t(labHints.common.tryScrolling)",
    "b881713a-0222-4dbf-9809-e77675053728": "$t(labHints.common.tryExploring)",
    "afb650f0-330c-4d73-9c04-44a03b212f46": "$t(labHints.common.tryScrolling)",
    "1a6318e7-51b3-492e-9aa5-0332ca8bd6e3": "$t(labHints.common.tryScrolling)",
    "a5d06e72-9213-40ef-b7dc-9ff7e3581841": "$t(labHints.common.tryScrolling)",
    "fcd00854-4e37-48cb-8443-a454611d7e3f": "$t(labHints.common.tryExploring)",
    "3156f38e-8fd2-4858-9f6b-eaa9c2c57328": "$t(labHints.common.tryScrolling)",
    "4865e5d6-d832-4911-9508-0668d4248121": "$t(labHints.common.tryScrolling)",
    "b8b32e69-a47e-44f9-a206-03e03e1d392d": "$t(labHints.common.tryExploring)",
    "d338cd29-7d88-4d55-aa61-ac4dca088594": "$t(labHints.common.tryExploring)",
    "1f0529bc-6769-41b5-8091-6edd6d84e533": "$t(labHints.common.tryScrolling)",
    "2e705392-c8f2-4cc3-a5d8-2c22dee40de0": "$t(labHints.common.tryExploring)",
    "1e9d255f-37bc-46ec-a7d7-2e42d172a01a": "$t(labHints.common.tryScrolling)",
    "9f9e8e77-031a-45ac-94fd-b4f8e77ef132": "$t(labHints.common.tryExploring)",
    "b87aa3d1-b47c-4f31-9142-2a6366a1cb9a": "$t(labHints.common.tryScrolling)",
    "673db8b8-1db8-4d17-a8ba-7464156c0190": "$t(labHints.common.tryScrolling)",
    "ce281fcb-8393-45a2-a6d7-b1b5d4cca770": "You need to identify the VM in question, who performed the deletion, from where, and other useful information.",
    "5352bfc6-c05d-4d3d-ac73-41c021862bb3": "Try scrolling through the events to find information to record in your journal.",
    "4181aa7e-c68a-4a4c-b217-24c3b38bac70": "$t(labHints.common.tryExploring2)",
    "0978ebb7-9b30-4c25-83ea-18294791ee7f": "$t(labHints.common.authProps)",
    "1f5780aa-2f32-4ac7-b545-7cb3a1ab52c7": "$t(labHints.common.tryExploring2)",
    "22586939-4feb-47ad-9add-6aeabc0dc4c7": "$t(labHints.common.tryExploring2)",
    "0214f977-e7a2-4fee-bc12-92ef1da19901": "$t(labHints.common.tryExploringAuth)",
    "0144e738-aef9-4ae4-a2bc-be2fb4756c63": "$t(labHints.common.worthRecording)",
    "3f4ef736-ef04-4f7b-bd93-44aae99dc173": "$t(labHints.common.checkDetailsEvent)",
    "6b735bc2-a127-4d9b-9c94-74c547e0b60f": "Properties are a set of pairs (i.e. a Dictionary) describing the details of the event, stored as strings. Using Properties_d is recommended as that uses dynamic columns, not strings.",
    "529bfa3f-e669-4101-8347-f324e357b248": "$t(labHints.common.tryExploring2)",
    "922c9575-b206-4c51-9113-87f05293c6c9": "$t(labHints.common.worthRecording)",
    "10d5fe90-6746-4d50-acf7-1adcd94780a9": "$t(labHints.common.tryExploring2)",
    "22625b2d-2465-430a-8ce2-352f0af5472f": "$t(labHints.common.tryExploring2)",
    "ed65e127-d907-49e2-8596-1d07d0e43efd": "$t(labHints.common.tryExploring2)",
    "7e9697d8-65c6-4de2-80ec-a513ff65e08a": "$t(labHints.common.checkDetailsEvent)",
    "36f50d99-8753-4954-a82a-e82f0c6b975c": "$t(labHints.common.authProps)",
    "3ff3fa33-862c-48ac-9ea0-9d3e4596ff60": "$t(labHints.common.tryExploring2)",
    "01a14814-81bd-4f02-ae38-c40a07505e00": "$t(labHints.common.tryExploring2)",
    "6f3da65e-b690-403f-b7cc-de101f58e33c": "$t(labHints.common.tryExploringAuth)",
    "0fe4efb2-9559-496b-99b9-cf1187cee097": "$t(labHints.common.worthRecording)",
    "55791dff-d1b0-42e1-976c-ed97baae9adb": "$t(labHints.common.checkDetailsEvent)",
    "8bdd7836-5be1-4e0b-9104-130d2f5ea38d": "$t(labHints.common.tryExploring2)",
    "90a22807-9740-4fd2-bf3c-15179688e095": "$t(labHints.common.tryExploring2)",
    "46f9bd4e-dbad-42e6-af7a-9a1918e0bac3": "$t(labHints.common.worthRecording)",
    "4c21562b-532d-4308-8dd3-e23cfbf459e7": "$t(labHints.common.tryExploring2)",
    "69f0bf6f-d1fb-4600-8826-29c9db5ba667": "$t(labHints.common.tryExploring2)",
    "766c1fe4-835e-45dc-ba44-9a0e851f7a3b": "$t(labHints.common.checkDetailsEvent)",
    "b43b4927-83e9-4760-ac14-a16ccae1760d": "$t(labHints.common.authProps)",
    "beb7062b-11d3-4011-b221-4e3873a470fb": "$t(labHints.common.checkDetailsEvent)",
    "aa4da5ae-704e-42c0-86f1-e11db6c6b313": "$t(labHints.common.tryExploring2)",
    "acf3ea39-5309-4cde-9cb0-05d5a20adaee": "$t(labHints.common.checkDetailsEvent)",
    "12ba427e-c99a-499b-bd86-3c112925c425": "$t(labHints.common.worthRecording)",
    "bbd67ca8-4ab2-40f3-ad15-46e357314b70": "$t(labHints.common.checkDetailsEvent)",
    "7a0f1f06-c721-4475-ade0-4a80db31d401": "$t(labHints.common.tryExploring2)",
    "e9ab21a4-f54b-4aed-b69a-7532f29b3c8f": "$t(labHints.common.worthRecording)",
  },
};
