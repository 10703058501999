export interface NavBarButtonConfigElement {
  type: string;
  icon: string;
  activeIcon?: string;
}
export interface NavBarButtonConfig {
  top: NavBarButtonConfigElement[];
  bottom: NavBarButtonConfigElement[];
}

export const navBarButtonConfig: NavBarButtonConfig = {
  top: [
    {
      type: 'journal',
      icon: 'ReadingMode',
      activeIcon: 'ReadingModeSolid',
    },
    {
      type: 'policies',
      icon: 'PageList',
      activeIcon: 'PageListSolid',
    },
    {
      type: 'chat',
      icon: 'OfficeChat',
      activeIcon: 'OfficeChatSolid',
    },
    {
      type: 'help',
      icon: 'Unknown',
      activeIcon: 'UnknownSolid',
    },
  ],
  bottom: [
    {
      type: 'settings',
      icon: 'Settings',
    },
  ],
};
