/**
 * i18next NS: `Game01.IR01`
 */
export default {
  intro: {
    header: "It's time to write your Incident Report",
    body: "Now that you’ve resolved all of today’s incidents to the best of your ability, it’s time for you and your teammates to write your Incident Reports, so that we have a record of what happened.<br><br>In the complete experience, you would already have access to your teammates’ journal entries. But today, you’ll need to communicate with your teammate to figure out how they factor into the Incident Report!",
    button: "Let's Go",
  },
  ir: {
    header: "Incident Report",
    pageHead: {
      incidentID: "Incident ID: 55555",
      date: "Date: {{date}}",
      identity: "Identity Adminstrator: {{playerName}}",
      security: "Security Operations Analyst: {{playerName}}",
      compliance: "Compliance Adminstrator: {{playerName}}",
      unavailable: "Unavailable",
      incidentImpact: {
        header: "<b>Impact:</b>",
        options: {
          wiped: "Attacker wiped a production VM.",
          copied: "Attacker copied a production VM.",
          deleted: "Attacker deleted a production VM.",
          corrupted: "Attacker corrupted a production VM.",
        },
      },
      dataLoss: {
        header: "<b>Data Loss:</b>",
        options: {
          internal: "Internal file released, employee information lost.",
          customer: "Customer file released, customer information lost.",
          security: "test file released, security information lost.",
          test: "Test file released, no sensitive information lost.",
        },
      },
      remediation: "Remediation: Completed",
      status: "Status: Closed",
    },
    summary: {
      header: "Executive Summary",
      body: "On {{date}}, our CISO notified our Incident Response team that a test file was found on a public website.  Our Privacy Incident team was also notified as they need to start their required notification procedures based on legal and compliance requirements (such as GDPR) based on our investigation findings. Our team was tasked with finding out how the file was leaked, what other data might have been leaked, fix any issues, and finally protect our reputation by preventing this from happening again.",
    },
    reported: {
      header: "Reported Events",
      alert1: "Alert: Our CISO notified us that a test file was found on a public website.",
      leakedFile: {
        text: "<p>The file, </p><0/><p>, was authored by</p><1/><p>of our Finance Applications team.</p>",
        fileNameOptions: {
          purchasingData: "BFYO Purchasing Data - Q1.xlsx",
          financialRecords: "[CompanyFinancialRecords.xlsx]",
          employeeInfo: "[CompanyEmployeeInfo.xlsx]",
          productInfo: "[CompanyProductInfo.xlsx]",
        },
        fileAuthorOptions: {
          nestor: "Nestor Wilke",
          lee: "Amari Rivera",
          emily: "Emily Braun",
          debra: "Debra Berger",
        },
      },
      identityInvestigate1: {
        text: "<p>Using</p><0/><p>, <strong>Identity's</strong> investigation revealed the file's author to be</p><1/><p>, noted risky sign-ins from an IP in </p><2/><p>and confirmed</p><3/>",
        identityInvestigateUsingOptions: {
          pid: "Azure AD Privileged Identity Management",
          ad: "Azure Active Directory",
          sentinel: "Azure Sentinel",
          ip: "Azure AD Identity Protection",
        },
        identityTypeOptions: {
          highRisk: "a high risk user",
          employee: "a disgruntled employee",
          lowRisk: "a low risk user",
          mediumRisk: "a medium risk user",
        },
        riskyLocationOptions: {
          slovenia: "Slovenia",
          germany: "Germany",
          moldova: "Moldova",
          estonia: "Estonia",
        },
        incidentReasonOptions: {
          sprayAttack: "they were the victim of a spray attack.",
          leakedFile: "they leaked the file maliciously.",
          soldFile: "they sold the file for personal profit.",
          dictionaryAttack: "they were the victim of a dictionary attack.",
        },
      },
      identityBonus1: {
        text: "<p><strong>Identity</strong> also</p><0/>",
        identityActionOptions: {
          deleted: "deleted the user's account.",
          warning: "sent the user a warning.",
          confirmed: "confirmed the user was compromised.",
          blocked: "blocked the user.",
        },
      },
      identityRemediate1: {
        text: "<p>In order to remediate this user, <strong>Identity</strong></p><0/>",
        identityRemediationOptions: {
          reset: "reset the user's password.",
          blocked: "blocked the user.",
          createdNew: "created a new account for the user.",
          rolledBack: "rolled back the user's account.",
        },
      },
      identityRemediate2: {
        text: "<p>To protect against future incidents <strong>Identity</strong> configured the user risk remediation policy to require</p><0/><p>with a user risk of</p><1/><p>to</p><2/>",
        riskPolicyGroupOptions: {
          administrators: "administators",
          selectIndividuals: "select individuals and groups",
          nonAdminstrators: "non-adminstrators",
          allUsers: "all users",
        },
        riskPolicyLevelOptions: {
          low: "Low and above",
          medium: "Medium and above",
          high: "High",
        },
        riskPolicyActionOptions: {
          resetPassword: "reset their password.",
          changeUsename: "change their user name.",
          deleteAccount: "delete their account.",
          submitReport: "submit a report.",
        },
      },
      alert2: {
        text: "<p>Alert: Help Desk notified Security that a user was unable to connect to </p><0/>",
        alert2Options: {
          rgFinance: "rgFinance.",
          FinanceVM23: "FinanceVM23.",
          M365x489897: "M365x489897.",
          internet: "the internet.",
        },
      },
      securityInvestigate1: {
        text: "<p>As part of Security's investigation, they noted that the last activity was</p><0/>",
        lastActivityOptions: {
          oneDay: "1 day ago.",
          fourDays: "4 days ago.",
          threeDays: "3 days ago.",
          eightDays: "8 days ago.",
        },
      },
      securityBonus1a: {
        text: "<p>They also logged the Reporting User</p><0/>",
        reportingUserOptions: {
          lidia: "Lidia Holloway.",
          brian: "Brian Johnson.",
          debra: "Debra Berger.",
          gradie: "Gradie Archie.",
        },
      },
      securityBonus1b: {
        text: "<p>They also logged the Help Desk Agent</p><0/>",
        helpDeskAgentOptions: {
          brian: "Brian Johnson.",
          gradie: "Gradie Archie.",
          lidia: "Lidia Holloway.",
          debra: "Debra Berger.",
        },
      },
      securityBonus1c: {
        text: "<p>They also logged the Reporting User</p><0/><p>and the Help Desk Agent</p><1/>",
        reportingUserOptions: {
          lidia: "Lidia Holloway",
          brian: "Brian Johnson",
          debra: "Debra Berger",
          gradie: "Gradie Archie",
        },
        helpDeskAgentOptions: {
          brian: "Brian Johnson.",
          gradie: "Gradie Archie.",
          lidia: "Lidia Holloway.",
          debra: "Debra Berger.",
        },
      },
      securityInvestigate2: {
        text: "<p>Using a</p><0/><p>query in</p><1/><p>, they were able to determine the VM was unreachable because it had been</p><2/><p>by</p><3/><p>To proactively manage future events of this nature, <strong>Security</strong></p><4/>",
        databaseTypeOptions: {
          kql: "KQL",
          aql: "AQL",
          oql: "OQL",
          sql: "SQL",
        },
        securityUsingOptions: {
          pid: "Azure AD Privileged Identity Management",
          ad: "Azure Active Directory",
          ip: "Azure AD Identity Protection",
          sentinel: "Azure Sentinel",
        },
        vmStateOptions: {
          infected: "infected",
          hidden: "hidden",
          deleted: "deleted",
          blocked: "blocked",
        },
        securityByOptions: {
          nestor: "Nestor Wilke.",
          emily: "Emily Braun.",
          lee: "Amari Rivera.",
          debra: "Debra Berger.",
        },
        securityActionOptions: {
          mfa: "configured an MFA registration policy in Sentinel.",
          scheduledQuery: "set up a Scheduled Query in Sentinel.",
          blocked: "blocked the user responsible.",
          rolledBack: "rolled back the VM.",
        },
      },
      identityProtect1: {
        text: "<p>Based on <strong>Security's</strong> findings, <strong>Identity</strong> was able to protect against future incidents of this nature by configuring</p><0/>",
        identityProtectUsingOptions: {
          pid: "Azure AD Privileged Identity Management",
          ad: "Azure Active Directory",
          ip: "Azure AD Identity Protection",
          sentinel: "Azure Sentinel",
        },
      },
    },
    confirmationDialog: {
      title: "Are you sure?",
      body: "Once you confirm submission, your Incident Report will no longer be editable and your final score will be calculated.",
      confimButton: "Confirm",
      cancelButton: "I'm not done yet",
    },
    ending: "There’s more to the story! More of the Incident Report will be completed during the full experience.",
    whenDone: "When you're done filling out your incident report, click the Submit button.",
    incomplete: "Some entries are not selected yet.",
    submitButton: "Submit",
    placeholder: "Select an option",
  },
};
