import React from 'react';
import {
  FontSizes,
  FontWeights,
  getTheme,
  IPersonaSharedProps,
  IStackStyles,
  IStackTokens,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
  Stack,
  Text,
} from '@fluentui/react';

const theme = getTheme();

/**
 * Static config. Maybe make configurable at instance level in future.
 */
const config = {
  contentChildrenGap: '24 20', // 'vertical horizontal'
  contentPadding: 40,
  frameBackgroundColor: theme.palette.neutralLight,
  frameBorderRadius: theme.effects.roundedCorner2,
  frameBoxShadow: theme.effects.elevation16,
  frameHeight: 560,
  frameWidth: 1040,
  outerPadding: 32,
  sidebarBackgroundColor: theme.palette.neutralQuaternary,
  sidebarWidth: 240,
  titleTextStyles: { root: { fontSize: FontSizes.xxLarge, fontWeight: FontWeights.semibold } },
  personaDefaultProps: {
    initialsColor: PersonaInitialsColor.darkRed,
  },
  personaForcedProps: {
    hidePersonaDetails: true,
    size: PersonaSize.size120,
  },
};

export interface ScreenModalProps {
  title?: string | React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  footerAlign?: 'auto' | 'stretch' | 'baseline' | 'center' | 'start' | 'end' | undefined;
  sidebar?: ScreenModalSidebarProps | boolean;
}

export interface ScreenModalSidebarProps {
  title?: string | React.ReactNode;
  persona?: IPersonaSharedProps;
  contents?: React.ReactNode;
}

const vContainerStyles: IStackStyles = {
  root: {
    alignSelf: 'stretch',
    minHeight: config.frameHeight + config.outerPadding,
  },
};

const vContainerTokens: IStackTokens = {
  padding: config.outerPadding,
};

const modalFrameStyles: IStackStyles = {
  root: {
    backgroundColor: config.frameBackgroundColor,
    borderRadius: config.frameBorderRadius,
    boxShadow: config.frameBoxShadow,
    minWidth: config.frameWidth,
    width: config.frameWidth,
    minHeight: config.frameHeight,
    height: config.frameHeight,
    overflow: 'hidden',
  },
};

const modalSidebarStyles: IStackStyles = {
  root: {
    backgroundColor: config.sidebarBackgroundColor,
    width: config.sidebarWidth,
    minWidth: config.sidebarWidth,
  },
};

const modalContentTokens: IStackTokens = {
  childrenGap: config.contentChildrenGap,
  padding: config.contentPadding,
};

const modalSidebarTokens: IStackTokens = {
  childrenGap: config.contentChildrenGap,
  padding: config.contentPadding,
};

const modalBodyTokens: IStackTokens = {
  childrenGap: config.contentChildrenGap,
};

const modalFooterTokens: IStackTokens = {
  childrenGap: config.contentChildrenGap,
};

/**
 * Render string title as <Text> component.
 */
const renderTitle = (title?: string | React.ReactNode): React.ReactNode => {
  if (typeof title === 'string') {
    return <Text styles={config.titleTextStyles}>{title}</Text>;
  }
  return title;
};

/**
 * Render persona for sidebar.
 */
const renderPersona = (persona?: IPersonaSharedProps): React.ReactNode => {
  if (persona == null) {
    return null;
  }
  return <Persona {...config.personaDefaultProps} {...persona} {...config.personaForcedProps} />;
};

/**
 * Render sidebar.
 */
const renderSidebar = (sidebar: ScreenModalProps['sidebar']): React.ReactNode => {
  if (sidebar == null || !sidebar) {
    return null;
  }
  const props = typeof sidebar === 'object' ? sidebar : {};

  return (
    <Stack
      className="screen-modal-sidebar"
      horizontalAlign="stretch"
      styles={modalSidebarStyles}
      tokens={modalSidebarTokens}
      verticalAlign="end"
      verticalFill
    >
      <Stack.Item className="screen-modal-sidebar-contents" grow={1}>
        {props.contents}
      </Stack.Item>
      <Stack.Item className="screen-modal-sidebar-persona" align="center">
        {renderPersona(props.persona)}
      </Stack.Item>
      <Stack.Item className="screen-modal-sidebar-title" align="center">
        {renderTitle(props.title)}
      </Stack.Item>
    </Stack>
  );
};

/**
 * Render body prop as stack.
 */
const renderBody = (body: ScreenModalProps['body']): React.ReactNode => {
  if (body == null) {
    return null;
  }

  return <Stack tokens={modalBodyTokens}>{body}</Stack>;
};

/**
 * Render footer prop as stack.
 */
const renderFooter = (footer: ScreenModalProps['footer']): React.ReactNode => {
  if (footer == null) {
    return null;
  }

  return (
    <Stack horizontal horizontalAlign="end" tokens={modalFooterTokens} verticalAlign="end">
      {footer}
    </Stack>
  );
};

/**
 * ScreenModal component.
 */
const ScreenModal: React.FunctionComponent<ScreenModalProps> = (
  { sidebar, title, body, footer, footerAlign, children },
) => {
  return (
    <Stack
      className="screen-modal-v-container"
      grow={1}
      horizontalAlign="stretch"
      styles={vContainerStyles}
      tokens={vContainerTokens}
      verticalAlign="stretch"
      verticalFill
    >
      <Stack
        className="screen-modal-h-container"
        grow={1}
        horizontal
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
      >
        <Stack
          className="screen-modal-frame"
          grow={0}
          horizontal
          horizontalAlign="start"
          styles={modalFrameStyles}
          verticalAlign="stretch"
        >
          {renderSidebar(sidebar)}
          <Stack
            className="screen-modal-content"
            grow={1}
            horizontalAlign="start"
            tokens={modalContentTokens}
            verticalAlign="space-between"
            verticalFill
          >
            <Stack.Item className="screen-modal-title" align="start">
              {renderTitle(title)}
            </Stack.Item>
            <Stack.Item className="screen-modal-body" grow={1} align="stretch">
              {renderBody(body)}
              {children}
            </Stack.Item>
            <Stack.Item className="screen-modal-footer" align={footerAlign || 'end'}>
              {renderFooter(footer)}
            </Stack.Item>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ScreenModal;
