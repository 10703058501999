import React from 'react';
import { Text, FontWeights, DefaultButton } from '@fluentui/react';
import RTrans from '../../../../components/RTrans/RTrans';
import ScreenModal, { ScreenModalSidebarProps } from '../../../../components/ScreenModal/ScreenModal';
import { IR01TaskProps } from '../interface';

interface IR01IntroProps extends IR01TaskProps {
  sidebar: ScreenModalSidebarProps;
  onClick: Function;
}

const Intro: React.FunctionComponent<IR01IntroProps> = ({ t, onClick, sidebar }) => {
  return (
    <ScreenModal
      sidebar={sidebar}
      title={
        <Text variant="xxLarge" styles={{ root: { fontWeight: FontWeights.bold } }}>
          {t('intro.header')}
        </Text>
      }
      body={
        <Text variant="large">
          <RTrans t={t} i18nKey="intro.body" />
        </Text>
      }
      footer={<DefaultButton onClick={() => onClick()}>{t('intro.button')}</DefaultButton>}
    />
  );
};

export default Intro;
