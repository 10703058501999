/**
 * i18next NS: `Game01.IDSA03`
 */
export default {
  common: {
    btnTryAgain: "Oops! Try again",
  },
  intro01a: {
    title: "Moving forward in time...",
    body: "<p>During the full experience, you would now work on the “Missing VM” alert currently being investigated by Security. You would investigate Amari Rivera’s role-based access and groups, and discover that he was given temporary access to a “VM Roles” group but was never removed. To remediate, you would remove him from that group.</p> <p>But for this demo, we are fast-forwarding to the protection step.</p> <p><strong>How can you assign role-based access to protect against future incidents like this?</strong></p>",
    B01: "Investigate in Azure AD Identity Protection",
    B02: "Configure Azure AD Privileged Identity Management",
    B03: "Set up Conditional Access",
  },
  intro01b: {
    title: "This isn't the right time for that.",
    body: "Azure AD Identity Protection is focused on risks regarding the compromise of user accounts, including suspicious sign-on attempts.",
    B04: "$t(common.btnTryAgain)",
  },
  intro01c: {
    title: "Wait!",
    body: "Conditional Access applies to everyone in your organization. You need to use a much more targeted tool.",
    B05: "$t(common.btnTryAgain)",
  },
  lab01: {
    title: "",
    comingSoon: "Configure Azure AD Privileged ID Management<br />Simulation Coming Soon!",
    B01: "Continue",
  },
  end01: {
    title: "Part 1 of Identity tasks complete!",
    body: "You'll continue the story during the full experience. But now it's time for your final challenge.",
    B01: "Continue to Incident Report",
  },
};
