import { EventObject, State, Typestate } from 'xstate';
import { storage } from '../store/persist-storage';

export const TASK_STORAGE_PREFIX = 'task:MPS:';

export function clearTaskState(taskKey: string) {
  storage.removeItemSync(taskKey);
}

export function restoreTaskState<TContext, TEvent extends EventObject>(taskKey: string | undefined) {
  if (taskKey == null || taskKey.length === 0) {
    return undefined;
  }
  const serializedState = storage.getItemSync(`${TASK_STORAGE_PREFIX}${taskKey}`);
  if (serializedState != null) {
    try {
      const newState = JSON.parse(serializedState);
      return State.create<TContext, TEvent>(newState);
    } catch {
      // noop
    }
  }
  return undefined;
}

export function saveTaskState<
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext },
>(taskKey: string | undefined, state: State<TContext, TEvent, any, TTypestate>) {
  if (taskKey == null || taskKey.length === 0) {
    return undefined;
  }
  try {
    const serializedState = JSON.stringify(state);
    storage.setItemSync(`${TASK_STORAGE_PREFIX}${taskKey}`, serializedState);
    return serializedState;
  } catch {
    return undefined;
  }
}
