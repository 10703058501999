import { IButtonStyles, IFontIconProps, mergeStyles } from '@fluentui/react';
import i18n from '../i18n';
import { entryData } from '../static/entry-data';
import { filterColors } from './journal-filter';

export interface EntryComponentProps {
  entry_id: string;
  category: string;
  type: string;
  date: number;
  description: string;
  content: string[];
  more?: string[];
  unread: boolean;
  active: boolean;
}

export interface EntryUnreadNotificationProps {
  unread: boolean;
}

export interface EntryContentProps {
  date: number;
  description: string;
  content: string[];
  entry_id: string;
}

export interface EntryShowMoreProps {
  more: string[];
  type: string;
  entry_id: string;
}

export function getEntryColor(type: string) {
  const entryColorElem = filterColors.find((f) => f.type === type);
  if (entryColorElem) {
    return entryColorElem;
  }
  throw new Error('getJournalEntryBackgroundColor - no color element found for type');
}

export function getEntryIcons(category: string) {
  switch (category) {
    case 'alert':
      return 'IncidentTriangle';
    case 'policy':
      return 'EntitlementPolicy';
    default:
      return 'ComplianceAudit';
  }
}

export function generateEntryStyles(category: string): IFontIconProps['className'] {
  const entryColorElem = filterColors.find((f) => f.type === category);
  if (entryColorElem) {
    const size = 64;
    const { color } = entryColorElem;
    return mergeStyles({
      fontSize: size,
      width: size,
      height: size,
      color,
    });
  }
  throw new Error('generateEntryStyles - no color element found for type');
}

export function generateShowMoreButtonStyles(type: string): IButtonStyles {
  return {
    root: {
      padding: 0,
      marginLeft: -4,
      span: {
        fontWeight: 900,
      },
    },
    rootHovered: {
      color: 'initial',
    },
    icon: {
      color: getEntryColor(type).color,
    },
    iconHovered: {
      color: getEntryColor(type).color,
    },
    iconChecked: {
      color: getEntryColor(type).color,
    },
  };
}

const disallowedEntryStates = new Set(['description', 'default', 'more']);

export function generateEntries(journal: any, activeFilter: string = 'all'): EntryComponentProps[] {
  const { ids, entities } = journal;
  const data: EntryComponentProps[] = [];
  for (let i = 0; i < ids.length; i += 1) {
    const id = ids[i];
    const entry = entities[id];
    if (entry) {
      const eData = entryData[entry.id];
      const content: string[] = [];
      const more: string[] = [];
      // Push default key if it exists
      const dKey = `${entry.id}.default`;
      if (i18n.exists(dKey, { ns: 'entries' })) {
        content.push(dKey);
      }
      // Push default 'more' key if it exists
      const dmKey = `${entry.id}.more.default`;
      if (i18n.exists(dmKey, { ns: 'entries' })) {
        more.push(dmKey);
      }
      entry.states.forEach((s: string) => {
        // Skip disallowed state names
        if (disallowedEntryStates.has(s)) {
          return;
        }
        // Push state key to content area if it exists.
        const cKey = `${entry.id}.${s}`;
        if (i18n.exists(cKey, { ns: 'entries' })) {
          content.push(cKey);
        }
        // Push state key to 'more' area if it exists.
        const mKey = `${entry.id}.more.${s}`;
        if (i18n.exists(mKey, { ns: 'entries' })) {
          more.push(mKey);
        }
      });
      data.push({
        entry_id: entry.id,
        date: entry.date,
        category: eData.category,
        content,
        more,
        unread: entry.unread,
        type: eData.type,
        description: `${entry.id}.description`,
        active: activeFilter === eData.type || activeFilter === 'all',
      });
    }
  }
  return data;
}
