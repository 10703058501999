import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultButton, Text } from '@fluentui/react';
import RTrans from '../../../../components/RTrans/RTrans';
import ScreenModal, { ScreenModalSidebarProps } from '../../../../components/ScreenModal/ScreenModal';
import { RootState } from '../../../../store';
import { setActiveCallout } from '../../../../store/game-slice';
import { IntroTaskProps } from '../interface';
import './IntroTutorial.scss';

interface IntroTutoralProps extends IntroTaskProps {
  onClick: Function;
  index: number;
  sidebar: ScreenModalSidebarProps;
}

const IntroTutorial: React.FunctionComponent<IntroTutoralProps> = ({ onClick, t, sidebar, index }) => {
  const dispatch = useDispatch();
  const role = useSelector((s: RootState) => s.user.role);
  const opRole = role === 'identity' ? 'security' : 'identity';

  const components = {
    role: role ? t(`roles:${role}`) : t('noRole'),
    opRole: role ? t(`roles:${opRole}`) : t('noRole'),
  };

  useEffect(() => {
    switch (index) {
      case 3:
        dispatch(setActiveCallout(['journal']));
        break;
      case 4:
        dispatch(setActiveCallout(['policies']));
        break;
      case 5:
        dispatch(setActiveCallout(['help', 'chat']));
        break;
      default:
        dispatch(setActiveCallout([]));
        break;
    }
  }, [index, dispatch]);

  const nextBtnText = `buttons.${index > 4 ? 'final' : 'next'}`;
  return (
    <ScreenModal
      sidebar={sidebar}
      title={t('intro3.title')}
      body={
        <Text variant="large">
          {index > 2 && <RTrans t={t} components={components} i18nKey={['intro3.content']} />}
          {index > 3 && <RTrans t={t} components={components} i18nKey={['intro4.content']} />}
          {index > 4 && <RTrans t={t} components={components} i18nKey={['intro5.content']} />}
          {index > 5 && <RTrans t={t} components={components} i18nKey={['intro6.content']} />}
        </Text>
      }
      footer={
        <DefaultButton onClick={() => onClick()}>{t(nextBtnText)}</DefaultButton>
      }
    />
  );
};

export default IntroTutorial;
