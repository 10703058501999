/**
 * i18next NS: `Game01.IDSA01`
 */
export default {
  common: {
    btnDismiss: "Dismiss",
    btnOkay: "Okay",
    btnTryAgain: "Oops! Try again",
    btnHintAvailable: "Hint Available",
    btnHintUnavailable: "Hint Unavailable",
  },
  intro01: {
    title: "It's your job to protect us against identity-based risks.",
    body: "Based on what you know, we need you to investigate and remediate this incident, and then protect us from it happening again.",
    B01: "Let's go!",
  },
  intro02a: {
    title: "Ready to investigate this incident?",
    body: "You know that Amari Rivera is the author of the file. What should you do first?",
    B01: "Call Lee",
    B02: "Review Azure AD Identity Protection reports",
    B03: "Block Lee's account",
  },
  intro02b: {
    title: "Be careful!",
    body: "Don't call Lee right now. What if he leaked the file maliciously? You don't want to give him a chance to cover his tracks.",
    B04: "$t(common.btnTryAgain)",
  },
  intro02c: {
    title: "Wait!",
    body: "Don't block Lee's account yet. Take a step back and try to figure out what happened first.",
    B05: "$t(common.btnTryAgain)",
  },
  lab01: {
    title: "Lab 1",
  },
  modal01: {
    body: "@Security from Help Desk: User unable to connect to FinanceVM23.",
    D01: "$t(common.btnDismiss)",
  },
  mid01a: {
    title: "What did you find?",
    body: "Did you detect any anomalies with the user Amari Rivera?",
    B01: "Yes, he is a high risk user",
    B02: "Yes, he is a moderate risk user",
    B03: "No, he is a low risk user",
  },
  mid01b: {
    title: "That doesn't look right.",
    body: "Remember, any unauthorized access contributes to a higher level of risk.",
    B04: "$t(common.btnTryAgain)",
  },
  mid01c: {
    title: "Wait!",
    body: "Take another look at the evidence. Is Amari Rivera's activity different from other users? Suspicious activity contributes to a higher user risk score.",
    B05: "$t(common.btnTryAgain)",
  },
  mid02a: {
    title: "Why is Amari Rivera a high risk user?",
    body: "Check your journal for activity that indicates high risk.",
    B01: "Spray attack",
    B02: "Suspicious browser",
    B03: "Anonymous IP address sign-in",
  },
  mid02b: {
    title: "No, it's not suspicious browser risk.",
    body: "A suspicious browser risk is based on suspicious sign-in activity across multiple tenants from different countries in the same browser. That’s not what’s going on here - take another look at the evidence and try again.",
    B04: "$t(common.btnTryAgain)",
  },
  mid02c: {
    title: "No, it's not anonymous IP address risk.",
    body: "Anonymous IP address risk is based on sign-ins from an anonymous IP address (for example, Tor browser or anonymous VPN). That’s not what’s going on here - take another look at the evidence and try again.",
    B05: "$t(common.btnTryAgain)",
  },
  mid03: {
    title: "Investigation complete!",
    B01: "Remediate Amari Rivera",
    body: "Now you know that Amari Rivera was compromised by a Spray Attack from an unrecognized company.",
    list: ["Reviewed risky user data [Amari Rivera]", "Checked risky sign-ins [112.34.556 Slovenia]", "Checked risk detections [Spray Attack]"],
  },
  lab02: {
    title: "Lab 2",
  },
  modal02: {
    title: "Remediation Complete!",
    body: "Don't forget to tell your team members what you did to remediate Amari Rivera.",
    D01: "$t(common.btnOkay)",
  },
  mid04a: {
    title: "Take action to protect against future risks",
    body: "How can we prevent high risk users from accessing Azure resources in the future?",
    B01: "Go to Azure AD Privileged Identity Management",
    B02: "Go to Azure AD Identity Protection",
    B03: "Go to Azure AD Identity Governance",
  },
  mid04b: {
    title: "This isn't the right time for that.",
    body: "Azure AD Privileged Identity Management lets you automatically assign users to groups based on their properties. But for this task, you will need to configure and enable risk policies.",
    B04: "$t(common.btnTryAgain)",
  },
  mid04c: {
    title: "This isn't the right time for that.",
    body: "Azure AD Identity Governance helps you manage user identities and their access to resources. But for this task, you will need to configure and enable risk policies.",
    B05: "$t(common.btnTryAgain)",
  },
  modal03: {
    body: "When you enable risk policies, use our standard corporate settings. They are listed under the <strong>Policy</strong> tab in your journal.",
    D01: "$t(common.btnOkay)",
  },
  lab03: {
    title: "Lab 3",
  },
  modal04: {
    title: "Protection Complete!",
    body: "Don't forget to tell your team members what actions you took.",
    D01: "$t(common.btnOkay)",
  },
  labHints: {
    "common": {
      worthRecording: "This looks worth recording in your journal",
      turnPolicyOn: "Don't forget to turn the policy on",
      saveChanges: "Make sure you save your changes",
      policySetUp: "Make sure the policy is set up correctly for your organizations needs",
      mediumRiskUsers: "It is probably best to take automated action on at least the medium risk users",
      bestProtectOrg: "How can you best protect the organization but verify it is the correct user logging in?",
    },
    "start1": "$t(labHints.898e9b3f-3099-495b-82b9-3a6e77f03bac)",
    "start2": "$t(labHints.80e94ec7-10d0-4465-864d-8f792c1950d9)",
    "start3": "$t(labHints.c0271ff3-1051-4b39-b574-405483afdc12)",
    "898e9b3f-3099-495b-82b9-3a6e77f03bac": "User identities are managed through Azure Active Directory.",
    "c81de640-ad49-432b-bd93-2bef02c67196": "Remember you must manage your users' security.",
    "1a89e5f5-9351-4da6-9e1d-45cc9187975c": "Where is the best place to start reviewing the protection of identities?",
    "3323d122-a413-4532-bfa9-506b37ccd144": 'First stop is probably to review the users that have been identified as "Risky".',
    "9f996242-3175-4126-98a7-f59285cf687e": "Let's start with the users that are flagged with the highest risk level.",
    "53b6a250-fa2a-42a6-9180-1d6566f9fbb0": "$t(labHints.common.worthRecording)",
    "eec87897-2031-415e-abe5-72ed6de1940b": "$t(labHints.common.worthRecording)",
    "6275b483-5658-41a2-a512-19c5f5ca081b": "$t(labHints.common.worthRecording)",
    "08f3c6df-33b9-4663-b810-404fb2b6adfa": "It might be worth looking deeper into Lee's risky sign-ins.",
    "872d1ccc-2dc9-498e-ae3e-33a053f027c4": "$t(labHints.common.worthRecording)",
    "1f6fb891-aadb-4fc4-8bcc-44cbc190dd1f": "$t(labHints.common.worthRecording)",
    "eb037363-2cef-4e1a-a504-44100df468fd": "There's some interesting events here that should be looked at deeper.",
    "80e94ec7-10d0-4465-864d-8f792c1950d9": "We need to mitigate the risk with Lee and make sure it is him signing in",
    "ac27d3c3-ae03-42e4-9541-83a1a7b3d079": "There are several steps you could take to verify it is actually Lee signing in.",
    "80485eeb-5c7b-4a58-bdc6-604591d1c7e9": "You completely blocked Lee from accessing everything. Now he's non-productive. We've undone that so you can try again.",
    "c0271ff3-1051-4b39-b574-405483afdc12": "$t(labHints.common.policySetUp)",
    "d4576bdc-0edd-4957-8d20-5173b67cdfd9": "It is probably best to take automated action on the highest risk users",
    "59a88cd2-1622-4049-a4fd-72a83425854c": "$t(labHints.common.bestProtectOrg)",
    "e6e4b8b3-9336-42c8-b568-dec12d066027": "$t(labHints.common.turnPolicyOn)",
    "afe1a64f-8ed1-4115-96d6-b2244a9a01f2": "$t(labHints.common.saveChanges)",
    "7eb239e0-b973-4d74-8e24-57c717906aa9": "$t(labHints.common.policySetUp)",
    "2db08473-11b4-407e-a792-dedd72ea910f": "$t(labHints.common.meduimRiskUsers)",
    "cbe6bd4e-b684-4ebe-b71e-229527b90c9f": "$t(labHints.common.bestProtectOrg)",
    "ef2cd672-a0df-464b-b435-ac1d99b500d4": "$t(labHints.common.turnPolicyOn)",
    "8baecb4d-2438-4524-8e96-6172a3997ab0": "$t(labHints.common.saveChanges)",
    "5c8c3307-bb6b-42e4-8af5-ff662c793d4c": "$t(labHints.common.bestProtectOrg)",
    "50b671a4-f7de-4703-9460-d7e7c4e0cddf": "$t(labHints.common.turnPolicyOn)",
    "80179dc1-2d47-47ad-9d1b-9fdb31cd6d75": "$t(labHints.common.saveChanges)",
    "5fbdf6d3-b7c7-4e9e-888a-40476e1c0420": "$t(labHints.common.turnPolicyOn)",
    "0db1a01b-3bd4-407f-8ce2-0b719e765501": "$t(labHints.common.saveChanges)",
    "caec4145-1678-4b3b-90e3-63e4dbbda28c": "$t(labHints.common.turnPolicyOn)",
    "17327af8-818a-44c4-a93f-43ac699419a0": "$t(labHints.common.saveChanges)",
    "8bb81ed9-3f3a-41b2-9539-9bb2d84fe6d8": "$t(labHints.common.meduimRiskUsers)",
    "a4c6d58b-9a6b-4c46-a5de-255c4142321a": "$t(labHints.common.meduimRiskUsers)",
    "311f963a-c824-45c1-82e8-3ca070645642": "$t(labHints.common.turnPolicyOn)",
    "d8a86176-068e-4fc5-9b09-095e6b43e681": "$t(labHints.common.turnPolicyOn)",
    "741ef99c-fdb6-4657-844d-a166605a15bb": "$t(labHints.common.saveChanges)",
    "eabb6216-6da1-4a3a-a5ca-775af31c1f3e": "$t(labHints.common.meduimRiskUsers)",
  },
};
