import { IDropdownOption } from '@fluentui/react';
import { TFunction } from 'react-i18next';
import { PointValue } from '../../../task-common';
import { IR01Option } from '../interface';

export function generateOption(
  key: string,
  namespace: string,
  t: TFunction<('Game01.IR01' | 'roles')[]>,
): IDropdownOption {
  return {
    key,
    text: t(`${namespace}.${key}`),
  };
}

export function generateOptions(
  keys: IR01Option[],
  namespace: string,
  t: TFunction<('Game01.IR01' | 'roles')[]>,
): IDropdownOption[] {
  return keys.map((k) => generateOption(k.key, namespace, t));
}

export const IR01ImpactOptions: IR01Option[] = [
  {
    key: 'wiped',
    value: PointValue.NoPoints,
  },
  {
    key: 'copied',
    value: PointValue.NoPoints,
  },
  {
    key: 'deleted',
    value: PointValue.Critical,
  },
  {
    key: 'corrupted',
    value: PointValue.NoPoints,
  },
];

export const IR01DataLossOptions: IR01Option[] = [
  {
    key: 'internal',
    value: PointValue.NoPoints,
  },
  {
    key: 'customer',
    value: PointValue.NoPoints,
  },
  {
    key: 'security',
    value: PointValue.NoPoints,
  },
  {
    key: 'test',
    value: PointValue.Critical,
  },
];

export const IR01FileNameOptions: IR01Option[] = [
  {
    key: 'purchasingData',
    value: PointValue.Critical,
  },
  {
    key: 'financialRecords',
    value: PointValue.NoPoints,
  },
  {
    key: 'employeeInfo',
    value: PointValue.NoPoints,
  },
  {
    key: 'productInfo',
    value: PointValue.NoPoints,
  },
];

export const IR01AuthorOptions: IR01Option[] = [
  {
    key: 'nestor',
    value: PointValue.NoPoints,
  },
  {
    key: 'lee',
    value: PointValue.Critical,
  },
  {
    key: 'emily',
    value: PointValue.NoPoints,
  },
  {
    key: 'debra',
    value: PointValue.NoPoints,
  },
];

export const IR01IdentityInvestigateUsingOptions: IR01Option[] = [
  {
    key: 'pid',
    value: PointValue.NoPoints,
  },
  {
    key: 'ad',
    value: PointValue.NoPoints,
  },
  {
    key: 'sentinel',
    value: PointValue.NoPoints,
  },
  {
    key: 'ip',
    value: PointValue.Critical,
  },
];

export const IR01IdentityTypeOptions: IR01Option[] = [
  {
    key: 'highRisk',
    value: PointValue.Critical,
  },
  {
    key: 'employee',
    value: PointValue.NoPoints,
  },
  {
    key: 'lowRisk',
    value: PointValue.NoPoints,
  },
  {
    key: 'mediumRisk',
    value: PointValue.NoPoints,
  },
];

export const IR01RiskyLocationOptions: IR01Option[] = [
  {
    key: 'slovenia',
    value: PointValue.NoPoints,
  },
  {
    key: 'germany',
    value: PointValue.Critical,
  },
  {
    key: 'moldova',
    value: PointValue.NoPoints,
  },
  {
    key: 'estonia',
    value: PointValue.NoPoints,
  },
];

export const IR01IncidentReasonOptions: IR01Option[] = [
  {
    key: 'sprayAttack',
    value: PointValue.Critical,
  },
  {
    key: 'leakedFile',
    value: PointValue.NoPoints,
  },
  {
    key: 'soldFile',
    value: PointValue.NoPoints,
  },
  {
    key: 'dictionaryAttack',
    value: PointValue.NoPoints,
  },
];

export const IR01IdentityActionOptions: IR01Option[] = [
  {
    key: 'deleted',
    value: PointValue.NoPoints,
  },
  {
    key: 'warning',
    value: PointValue.NoPoints,
  },
  {
    key: 'confirmed',
    value: PointValue.Bonus,
  },
  {
    key: 'blocked',
    value: PointValue.NoPoints,
  },
];

export const IR01IdentityRemediationOptions: IR01Option[] = [
  {
    key: 'reset',
    value: PointValue.Critical,
  },
  {
    key: 'blocked',
    value: PointValue.NoPoints,
  },
  {
    key: 'createdNew',
    value: PointValue.NoPoints,
  },
  {
    key: 'rolledBack',
    value: PointValue.NoPoints,
  },
];

export const IR01IdentityRiskPolicyGroupOptions: IR01Option[] = [
  {
    key: 'administrators',
    value: PointValue.NoPoints,
  },
  {
    key: 'selectIndividuals',
    value: PointValue.NoPoints,
  },
  {
    key: 'nonAdminstrators',
    value: PointValue.NoPoints,
  },
  {
    key: 'allUsers',
    value: PointValue.Critical,
  },
];

export const IR01IdentityRiskPolicyLevelOptions: IR01Option[] = [
  {
    key: 'low',
    value: PointValue.NoPoints,
  },
  {
    key: 'medium',
    value: PointValue.NoPoints,
  },
  {
    key: 'high',
    value: PointValue.Critical,
  },
];

export const IR01IdentityRiskPolicyActionOptions: IR01Option[] = [
  {
    key: 'resetPassword',
    value: PointValue.Critical,
  },
  {
    key: 'changeUsename',
    value: PointValue.NoPoints,
  },
  {
    key: 'deleteAccount',
    value: PointValue.NoPoints,
  },
  {
    key: 'submitReport',
    value: PointValue.NoPoints,
  },
];

export const IR01Alert2Options: IR01Option[] = [
  {
    key: 'rgFinance',
    value: PointValue.NoPoints,
  },
  {
    key: 'FinanceVM23',
    value: PointValue.Critical,
  },
  {
    key: 'M365x489897',
    value: PointValue.NoPoints,
  },
  {
    key: 'internet',
    value: PointValue.NoPoints,
  },
];

export const IR01LastActivityOptions: IR01Option[] = [
  {
    key: 'oneDay',
    value: PointValue.NoPoints,
  },
  {
    key: 'fourDays',
    value: PointValue.Critical,
  },
  {
    key: 'threeDays',
    value: PointValue.NoPoints,
  },
  {
    key: 'eightDays',
    value: PointValue.NoPoints,
  },
];

export const IR01ReportingUserOptions: IR01Option[] = [
  {
    key: 'brian',
    value: PointValue.NoPoints,
  },
  {
    key: 'gradie',
    value: PointValue.NoPoints,
  },
  {
    key: 'lidia',
    value: PointValue.NoPoints,
  },
  {
    key: 'debra',
    value: PointValue.Bonus,
  },
];

export const IR01HelpDeskAgentOptions: IR01Option[] = [
  {
    key: 'gradie',
    value: PointValue.Bonus,
  },
  {
    key: 'lidia',
    value: PointValue.NoPoints,
  },
  {
    key: 'brian',
    value: PointValue.NoPoints,
  },
  {
    key: 'debra',
    value: PointValue.NoPoints,
  },
];

export const IR01DatabaseTypeOptions: IR01Option[] = [
  {
    key: 'kql',
    value: PointValue.Critical,
  },
  {
    key: 'aql',
    value: PointValue.NoPoints,
  },
  {
    key: 'oql',
    value: PointValue.NoPoints,
  },
  {
    key: 'sql',
    value: PointValue.NoPoints,
  },
];

export const IR01SecurityUsingOptions: IR01Option[] = [
  {
    key: 'pid',
    value: PointValue.NoPoints,
  },
  {
    key: 'ad',
    value: PointValue.NoPoints,
  },
  {
    key: 'ip',
    value: PointValue.NoPoints,
  },
  {
    key: 'sentinel',
    value: PointValue.Critical,
  },
];

export const IR01VMStateOptions: IR01Option[] = [
  {
    key: 'infected',
    value: PointValue.NoPoints,
  },
  {
    key: 'hidden',
    value: PointValue.NoPoints,
  },
  {
    key: 'deleted',
    value: PointValue.Critical,
  },
  {
    key: 'blocked',
    value: PointValue.NoPoints,
  },
];

export const IR01SecurityByOptions: IR01Option[] = [
  {
    key: 'nestor',
    value: PointValue.NoPoints,
  },
  {
    key: 'lee',
    value: PointValue.Critical,
  },
  {
    key: 'emily',
    value: PointValue.NoPoints,
  },
  {
    key: 'debra',
    value: PointValue.NoPoints,
  },
];

export const IR01SecurityActionOptions: IR01Option[] = [
  {
    key: 'mfa',
    value: PointValue.NoPoints,
  },
  {
    key: 'scheduledQuery',
    value: PointValue.Critical,
  },
  {
    key: 'blocked',
    value: PointValue.NoPoints,
  },
  {
    key: 'rolledBack',
    value: PointValue.NoPoints,
  },
];

export const IR01IdentityProtectUsingOptions: IR01Option[] = [
  {
    key: 'pid',
    value: PointValue.Critical,
  },
  {
    key: 'ad',
    value: PointValue.NoPoints,
  },
  {
    key: 'ip',
    value: PointValue.NoPoints,
  },
  {
    key: 'sentinel',
    value: PointValue.NoPoints,
  },
];
